<template>
    <div class="info">
        <div class="info-line"></div>
        <div class="info-box">
            <div class="info-box">
                <div class="top">
                    <span style="cursor: pointer" @click="$router.push(`/home`)">首页</span>
                    >
                    <span style="cursor: pointer" @click="$router.push(`/report`)">权威报道</span>
                    >
                    <span style="cursor: pointer" @click="$router.push(`/report`)">企业资讯</span>
                </div>
                <div class="title">{{ title }}</div>
                <div class="date">{{ date }}</div>
                <div class="line"></div>
                <div>
                    <div ref="content"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        title: "",
        date: "",
    };
  },
  methods: {
    getInfo(id){
            axios.get(`${this.$request}v1/info/queryPcMedicaldata?id=${id}`).then((res) => {
                let content = Base64.decode(res.data.data[0].content).replaceAll(/<img/gi, "<img style='width: 740px;margin: 20px auto;display:block;'")
                this.$refs.content.innerHTML =content;
                this.title =res.data.data[0].title;
                this.date =res.data.data[0].createTime;
            }).catch((err) => {
                console.log(err);
            });
        },
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    if (this.$route.query.id) {
        this.getInfo(this.$route.query.id);
    }
  },
};
</script>

<style lang="scss"></style>
