<template>
  <div class="innovation">
    <img :src="`${$img}//innovation/banner.jpg`" class="banner" />
    <div class="item" style="background: #f7f8fc">
      <div class="box">
        <div class="allTitle wow fadeInUp">技术专利证书</div>
        <div class="allTitle-En wow fadeInUp">LETTER PATENT</div>
        <div class="technological">
          <img
            :src="`${$img}/innovation/technological/${selectTechnological}.png`"
            class="technological-left InLeft bounceInLeft wow"
            alt=""
            :key="+new Date()"
            data-wow-delay="0.3s"
          />
          <div class="technological-right">
            <div
              class="technological-item wow flipInY"
              data-wow-duration=".5s"
              data-wow-delay="0.3s"
              :class="{ 'select-technological': selectTechnological == 1 }"
              @mouseenter="selectTechnological = 1"
            >
              <div class="technological-title">发明</div>
              <div class="technological-title">专利证书</div>
              <div class="technological-line"></div>
              <div class="technological-text">一种人造毛囊及根据人造毛囊</div>
              <div class="technological-text">确定发型的方法</div>
            </div>
            <div
              class="technological-item wow flipInY"
              data-wow-duration=".5s"
              data-wow-delay="0.4s"
              :class="{ 'select-technological': selectTechnological == 2 }"
              @mouseenter="selectTechnological = 2"
            >
              <div class="technological-title">外观设计</div>
              <div class="technological-title">专利证书</div>
              <div class="technological-line"></div>
              <div class="technological-text">植发用微针</div>
            </div>
            <div
              class="technological-item wow flipInY"
              data-wow-duration=".5s"
              data-wow-delay="0.5s"
              :class="{ 'select-technological': selectTechnological == 3 }"
              @mouseenter="selectTechnological = 3"
            >
              <div class="technological-title">实用新型</div>
              <div class="technological-title">专利证书</div>
              <div class="technological-line"></div>
              <div class="technological-text">一种具有抑菌功能的</div>
              <div class="technological-text">植发装置</div>
            </div>
            <div
              class="technological-item wow flipInY"
              data-wow-duration=".5s"
              data-wow-delay="0.6s"
              :class="{ 'select-technological': selectTechnological == 4 }"
              @mouseenter="selectTechnological = 4"
            >
              <div class="technological-title">实用新型</div>
              <div class="technological-title">专利证书</div>
              <div class="technological-line"></div>
              <div class="technological-text">用于毛发种植的</div>
              <div class="technological-text">微针以及微针套件</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="box">
        <div class="allTitle wow fadeInUp">独家商标证书</div>
        <div class="allTitle-En wow fadeInUp">EXCLUSIVE TRADEMARK</div>
        <div class="Exclusive">
          <img
            :src="`${$img}/innovation/Exclusive/${selectExclusive}.png`"
            data-wow-delay="0.3s"
            :key="+new Date() + 1"
            class="Exclusive-left InLeft bounceInLeft wow"
            alt=""
          />
          <div class="Exclusive-right">
            <div
              class="Exclusive-item wow fadeInUp"
              data-wow-delay="0.3s"
              :class="{ 'select-Exclusive': selectExclusive == 1 }"
              @mouseenter="selectExclusive = 1"
            >
              <img :src="`${$img}/innovation/Exclusive/5.jpg`" alt="" />
              <div>
                <div class="Exclusive-title">碧莲盛不剃发植发</div>
                <div class="Exclusive-text">商标注册证号：58809755</div>
              </div>
              <div class="Exclusive-R">R</div>
            </div>
            <div
              class="Exclusive-item wow fadeInUp"
              data-wow-delay="0.5s"
              :class="{ 'select-Exclusive': selectExclusive == 2 }"
              @mouseenter="selectExclusive = 2"
            >
              <img :src="`${$img}/innovation/Exclusive/6.jpg`" alt="" />
              <div>
                <div class="Exclusive-title">碧莲盛植发不剃发</div>
                <div class="Exclusive-text">商标注册证号：49690455</div>
              </div>
              <div class="Exclusive-R">R</div>
            </div>
            <div
              class="Exclusive-item wow fadeInUp"
              data-wow-delay="0.7s"
              :class="{ 'select-Exclusive': selectExclusive == 3 }"
              @mouseenter="selectExclusive = 3"
            >
              <img :src="`${$img}/innovation/Exclusive/7.jpg`" alt="" />
              <div>
                <div class="Exclusive-title">碧莲盛</div>
                <div class="Exclusive-text">商标注册证号：7654124</div>
              </div>
              <div class="Exclusive-R">R</div>
            </div>
            <div
              class="Exclusive-item wow fadeInUp"
              data-wow-delay="0.9s"
              :class="{ 'select-Exclusive': selectExclusive == 4 }"
              @mouseenter="selectExclusive = 4"
            >
              <img :src="`${$img}/innovation/Exclusive/8.jpg`" alt="" />
              <div>
                <div class="Exclusive-title">碧莲盛植发</div>
                <div class="Exclusive-text">商标注册证号：21244865</div>
              </div>
              <div class="Exclusive-R">R</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item" style="background: #f7f8fc; padding-bottom: 0">
      <div class="box">
        <div class="allTitle wow fadeInUp">技术研发团队</div>
        <div class="allTitle-En wow fadeInUp">R & D TEAM</div>
      </div>
      <img
        :src="`${$img}/innovation/time.png`"
        data-wow-delay="0.4s"
        class="time-img wow fadeInUp"
        alt=""
      />
    </div>
    <div class="item">
      <div class="box">
        <div class="allTitle wow fadeInUp">产学研机构合作</div>
        <div class="allTitle-En wow fadeInUp">IUR PARTNERSHIP</div>
        <div class="institutions wow fadeInUp" data-wow-delay="0.4s">
          <div class="institutions-item">
            <div class="institutions-img">
              <img :src="`${$img}/innovation/institutions1.jpg`" alt="" />
            </div>
            <div>
              <div class="institutions-title">雅光医疗战略合作</div>
              <div class="institutions-text">
                2022年，碧莲盛与雅光医疗在北京举行战略合作发布会，共同携手打造医学养发新概念和新模式，是轻医美和医学养发领域的联合突破，将引领和开拓医美与医学养发行业的新方向。
              </div>
              <div class="institutions-ellipsis">...</div>
            </div>
          </div>
          <div class="institutions-item">
            <div class="institutions-img">
              <img :src="`${$img}/innovation/institutions2.jpg`" alt="" />
            </div>
            <div>
              <div class="institutions-title">南京同仁堂战略合作</div>
              <div class="institutions-text">
                2018年，碧莲盛与南京同仁堂达成战略合作，将中医中药与脱发治疗相结合，针对脱发患者推出了一系列养发产品，如植物头皮养护组合、盛丽中药康养系列洗护产品等。
              </div>
              <div class="institutions-ellipsis">...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item" style="background: #f7f8fc">
      <div class="box">
        <div class="allTitle wow fadeInUp">行业学术交流</div>
        <div class="allTitle-En wow fadeInUp">ACADEMIC EXCHANGE</div>
        <div class="Industry wow fadeInUp">
          <div
            class="Industry-item"
            :class="{ 'select-Industry': selectIndustry == 1 }"
            @mouseenter="selectIndustry = 1"
            :style="{
              borderRight:
                selectIndustry == 3 || selectIndustry == 2
                  ? 'none'
                  : ' solid 1px #c5c6c7',
            }"
          >
            世界植发大会
          </div>
          <div
            class="Industry-item"
            :class="{ 'select-Industry': selectIndustry == 2 }"
            @mouseenter="selectIndustry = 2"
            :style="{
              borderRight:
                selectIndustry == 1 || selectIndustry == 2
                  ? 'none'
                  : ' solid 1px #c5c6c7',
              borderLeft:
                selectIndustry == 1 || selectIndustry == 2
                  ? 'none'
                  : ' solid 1px #c5c6c7',
            }"
          >
            中国植发大会
          </div>
          <div
            class="Industry-item"
            :class="{ 'select-Industry': selectIndustry == 3 }"
            @mouseenter="selectIndustry = 3"
            :style="{
              borderLeft:
                selectIndustry == 3 || selectIndustry == 2
                  ? 'none'
                  : ' solid 1px #c5c6c7',
            }"
          >
            学术年会
          </div>
        </div>
        <div style="display: flex" v-if="selectIndustry == 1">
          <div class="Industry-left InLeft bounceInLeft wow">
            <div class="Industry-title">世界植发大会</div>
            <div class="Industry-text">
              自2008年开始，碧莲盛连续13年受邀参加世界植发大会，向世界展示中国植发技术的最新突破与发展。2018年和2019年，碧莲盛植发案例小蔡、白龙先后在世界植发大会展示，董事长尤丽娜成为第一位入驻世界植发大会案例中心的中国医生。
            </div>
            <div class="Industry-ellipsis">...</div>
          </div>
          <div class="Industry-right InRight bounceInRight wow">
            <img
              :src="`${$img}//innovation/Industry/1.jpg`"
              class="IndustryTop-img"
              alt=""
            />
            <div style="display: flex; justify-content: space-between">
              <img
                :src="`${$img}//innovation/Industry/2.jpg`"
                alt=""
                class="Industry-img"
              />
              <img
                :src="`${$img}//innovation/Industry/3.jpg`"
                alt=""
                class="Industry-img"
              />
            </div>
          </div>
        </div>
        <div style="display: flex" v-if="selectIndustry == 2">
          <div class="Industry-left InLeft">
            <div class="Industry-title">中国植发大会</div>
            <div class="Industry-text">
              自2019年开始，碧莲盛连续4年受邀参加中国植发大会，从行业的高度、专业的深度、经济的角度等多个层面，对世界最前沿的毛发医学研究进展及临床应用成果展开深度分析与讨论，并作为起草单位之一，见证了中国《毛发移植规范》团体标准的诞生。
            </div>
            <div class="Industry-ellipsis">...</div>
          </div>
          <div class="Industry-right InRight">
            <img
              :src="`${$img}/innovation/Industry/7.jpg`"
              class="IndustryTop-img"
              alt=""
            />
            <div style="display: flex; justify-content: space-between">
              <img
                :src="`${$img}/innovation/Industry/8.jpg`"
                alt=""
                class="Industry-img"
              />
              <img
                :src="`${$img}/innovation/Industry/9.jpg`"
                alt=""
                class="Industry-img"
              />
            </div>
          </div>
        </div>
        <div style="display: flex" v-if="selectIndustry == 3">
          <div class="Industry-left InLeft">
            <div class="Industry-title">中国非公立医疗机构协会学术年会</div>
            <div class="Industry-text">
              2021年，碧莲盛受邀出席中国非公立医疗机构整形协会整形与美容专业委员会2021学术年会，共同探讨当今医美界热门话题，展望医美行业未来的发展趋势，不断增进机构间的团结合作、资源共享，构建民营医疗机构和谐发展的市场环境。
            </div>
            <div class="Industry-ellipsis">...</div>
          </div>
          <div class="Industry-right InRight">
            <img
              :src="`${$img}/innovation/Industry/4.jpg`"
              class="IndustryTop-img"
              alt=""
            />
            <div style="display: flex; justify-content: space-between">
              <img
                :src="`${$img}/innovation/Industry/5.jpg`"
                alt=""
                class="Industry-img"
              />
              <img
                :src="`${$img}/innovation/Industry/6.jpg`"
                alt=""
                class="Industry-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
 <script>
export default {
  data() {
    return {
      selectTechnological: 1,
      selectExclusive: 1,
      selectIndustry: 1,
      wow: null,
    };
  },
  mounted() {
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
  },
  beforeDestroy() {
    this.wow.stop();
  },
};
</script>
  
 <style lang="scss">
</style>