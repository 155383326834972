<template>
  <div class="report">
    <img :src="`${$img}/report/Banner.jpg`" class="banner" alt="" />
    <div class="item box" style="padding-bottom: 40px">
      <div class="allTitle wow fadeInUp">媒体报道</div>
      <div class="allTitle-En wow fadeInUp">MEDIA COVERAGE</div>
      <div class="media">
        <div class="media-item wow fadeInUp" v-for="(item, index) in list" :key="index" @click="show1(index)">
          <img :src="item.img" alt="" />
          <div class="media-bottom">{{ item.text }}</div>
          <img src="https://static.drlianzhuren.com/brandWeb/Journalism/bf.png" class="media-bf" alt="" />
        </div>
      </div>
      <div class="lookMore" @click="voidIndex++" v-if="voidIndex * 6 <= voidList.length - 1">
        查看更多
        <div class="out-top"></div>
      </div>
    </div>
    <div class="item" style="background: #f7f8fc; padding-bottom: 40px">
      <div class="box">
        <div class="allTitle wow fadeInUp">企业资讯</div>
        <div class="allTitle-En wow fadeInUp">CORPORATE NEWS</div>
        <div class="Corporate">
          <div class="Corporate-item wow fadeInUp" v-for="(item, index) in newsPageList" :key="index + 'a'">
            <div v-if="item.NewsType == 'news'">
              <router-link :to="{ path: '/info', query: { id: item.id } }">
                <img :src="item.pcImgUrl" alt="" class="Corporate-img" />
                <div class="Corporate-bottom">
                  <div class="Corporate-title">
                    {{ item.title }}
                  </div>
                  <div class="Corporate-date">{{ item.publishTime }}</div>
                </div>
              </router-link>
            </div>
            <div v-else-if="item.NewsType == 'video'">
              <div class="media-item wow fadeInUp" @click="show(item.id)">
                <img :src="item.pcImgUrl" alt="" />

                <img src="https://static.drlianzhuren.com/brandWeb/Journalism/bf.png" class="media-bf" alt="" />
                <div class="Corporate-bottom">
                  <div class="Corporate-title">
                    {{ item.title }}
                  </div>
                  <div class="Corporate-date">{{ item.publishTime }}</div>
                </div>
              </div>
            </div>
            <div v-else>
              <router-link :to="{ path: '/info1', query: { id: item.path } }">
                <img :src="item.img" alt="" class="Corporate-img" />
                <div class="Corporate-bottom">
                  <div class="Corporate-title">
                    {{ item.text }}
                  </div>
                  <div class="Corporate-date">{{ item.date }}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="lookMore" @click="lookMoreT" v-if="isNoAddT">
          查看更多
          <div class="out-top"></div>
        </div>
      </div>
    </div>
    <prop v-show="bol" @cancel="close" :url="url" :img="img" :title="title" :propList="propList"></prop>

    <template v-if="bol1">
      <prop1 @cancel="close1" :isSelect="isSelect"></prop1>
    </template>

  </div>
</template>

<script>
import "swiper/css/swiper.css";
import prop from "../components/prop.vue";
import prop1 from "../components/prop1.vue";
export default {
  name: "report",
  mounted() {
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
    this.getVideoList() // 视频
    this.getNewsList()  // 新闻
  },
  beforeDestroy() {
    this.wow.stop();
  },
  components: {
    prop,
    prop1
  },
  computed: {
    list() {
      const length = this.voidIndex * 6;
      if (this.voidList.length <= length) {
        return this.voidList;
      } else {
        return this.voidList.filter((item,index) => index < length);
      }
    },
    newsPageList: function () {
      let end = Math.min((this.pageNum + 1) * 6,this.newsList.length)
      return this.newsList.slice(0,end)
    },
  },

  data() {
    return {
      wow: null,
      bol: false,
      bol1: false,
      mySwiper: null,
      isNoAddT: true,
      pageNum: 0,
      pageNum1: 1,
      newsList: [],
      videoList: [],
      propList: [],
      url: "",
      img: "",
      title: "",
      voidIndex: 1,
      CorIndex: 1,
      newsList: [
        {
          img: `${this.$url}Journalism/style1.jpg`,
          text: "估值再创新高，“头部”企业碧莲盛如何实现逆势增长？",
          date: '2022-06-15',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/style2.jpg`,
          text: "播种希望 筑梦未来 碧莲盛开展六一爱心助学公益行",
          date: '2022-06-01',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/style3.jpg`,
          text: "碧莲盛荣获胡润百富“最青睐的高端植发品牌”",
          date: '2022-01-20',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/style4.jpg`,
          text: "不忘初心，勇于担当，碧莲盛荣获企业社会责任行业典范奖",
          date: '2022-01-14',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/style5.jpg`,
          text: "不剃发植发重塑行业天花板，碧莲盛当选植发行业领军企业",
          date: '2022-01-09',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/style6.jpg`,
          text: "碧莲盛董事长尤丽娜荣膺中国经济十大杰出女性",
          date: '2021-12-28',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism7.jpg`,
          text: "碧莲盛携手鸿基金启动“爱的翅膀·助力成才计划”",
          date: '2021-03-20',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism8.jpg`,
          text: "专家热议“新消费”：多措并举促进新业态新模式健康发展",
          date: '2021-03-10',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism9.jpg`,
          text: "潮流大秀视听盛宴隆重开幕 碧莲盛时尚盛典名流汇聚尽显奢华",
          date: '2020-11-26',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism10.jpg`,
          text: "斩获两项大奖，碧莲盛成为第七届中国行业影响力品牌峰会焦点",
          date: '2020-08-30',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism11.jpg`,
          text: "创新 匠心 仁心，植发行业赋能美好生活",
          date: '2020-08-18',
          NewsType: "old"
        },
        {
          img: `${this.$url}Journalism/Journalism12.jpg`,
          text: "2020国际质造节圆满落幕，碧莲盛植发荣获两大奖项",
          date: '2020-07-29',
          NewsType: "old"
        },

      ],

      voidList: [
        {
          img: `${this.$url}Journalism/Journalism1.jpg`,
          text: "CCTV 《创新之路》",
        },
        {
          img: `${this.$url}Journalism/Journalism2.jpg`,
          text: "CCTV13 《新闻直播间》 ",
        },
        {
          img: `${this.$url}Journalism/Journalism3.jpg`,
          text: "人民网《人民会客厅·两会时刻》 ",
        },
        {
          img: `${this.$url}Journalism/Journalism4.jpg`,
          text: "CCTV1 《生活圈》 ",
        },
        {
          img: `${this.$url}Journalism/Journalism5.jpg`,
          text: "BTV 《品质生活》",
        },
        {
          img: `${this.$url}Journalism/Journalism6.jpg`,
          text: "CCTV 《连线中国》 ",
        },
        {
          img: `${this.$url}Journalism/style7.jpg`,
          text: "CCTV《焦点关注》",
        },
        {
          img: `${this.$url}Journalism/style8.jpg`,
          text: "钛媒体《华楠直播间》",
        },
        {
          img: `${this.$url}Journalism/style9.jpg`,
          text: "山西电视台《黄河新闻》",
        },
        {
          img: `${this.$url}Journalism/style10.jpg`,
          text: "广东电视台《创新广东》",
        },
        {
          img: `${this.$url}Journalism/style11.jpg`,
          text: "甘肃电视台《今日文化君》",
        },
        {
          img: `${this.$url}Journalism/style12.jpg`,
          text: "南昌电视台《新闻说报》",
        },
      ],

    };
  },

  methods: {
    getUrl(vid) {
      let that = this;
      that.url = vid;
      console.log(vid);
      return false
      $.ajax({
        url: "https://vv.video.qq.com/getinfo?vids=" + vid + "&platform=101001&charge=0&otype=json",
        dataType: 'jsonp',
        jsonp: 'callback',
        success: function (data) {
          let ul = data.vl.vi;
          if (ul.length > 0) {
            let fn = ul[0].fn;
            let vkey = ul[0].fvkey;
            let uis = ul[0].ul.ui;
            that.url = uis[0].url + fn + "?vkey=" + vkey;
          }
        }
      });
    },
    lookMore() {
      this.pageNum1++
    },
    lookMoreT() {
      if (this.newsList.length * this.pageNum >= this.newsPageList.length) {
        this.isNoAddT = false
      }
      this.pageNum++
    },
    getNewsList() {
      let data = {
        field: "",
        orderByClause: "",
        pageNum: 1,
        pageSize: 9999,
        secondType: 3,
        stairType: "hair_care"
      }
      axios.post(`${this.$request}v1/index/pcNews`,data).then((res) => {
        // let list = res.data.data.data
        let list = res.data.data.data.filter(item => item.pcImgUrl !== null)
        list.forEach(item => item.NewsType = "news")
        this.newsList = [...this.newsList,...list]
      }).catch((err) => {
        console.log(err);
      });
    },
    getVideoList() {
      let data = {
        field: "",
        orderByClause: "",
        pageNum: 1,
        pageSize: 9999,
        secondType: 12,
        stairType: "renowned_clinic"
      }
      axios.post(`${this.$request}v1/index/pcNews`,data).then((res) => {
        // let list = res.data.data.data
        let list = res.data.data.data.filter(item => item.pcImgUrl !== null)
        list.forEach(item => item.NewsType = "video")
        this.newsList = [...this.newsList,...list]
      }).catch((err) => {
        console.log(err);
      });
    },
    show1(index) {
      document.body.style.height = "auto";
      document.body.style.overflowY = "hidden";
      this.bol1 = true;
      this.isSelect = index;
    },
    show(id) {
      this.title = this.newsList.find(item => item.id == id).title;

      let data = {
        id: id,
        secondType: "12",
        tagId: ""
      }
      axios.post(`${this.$request}v1/info/queryPcVideo`,data).then((res) => {
        let list = res.data.data.data.filter(item => item.pcImgUrl !== null)
        if (list.length < 6) {
          this.propList = list;
        } else {
          this.propList = list.slice(0,6);
        }
        this.getUrl(list[0].content);
        this.img = list[0].imgUrl
      }).catch((err) => {
        console.log(err);
      });
      document.body.style.height = "auto"
      document.body.style.overflow = "hidden";
      this.bol = true;
    },
    close() {
      document.body.style.height = " 100%";
      document.body.style.overflowY = "auto";
      this.bol = false;
    },
    close1() {
      document.body.style.height = " 100%";
      document.body.style.overflowY = "auto";
      this.bol1 = false;
    },
  },
};
</script>

<style  lang="scss"></style>
