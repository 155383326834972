<template>
  <div class="home" style="overflow-x: hidden">
    <!-- <div class="swiper-slide my-swiper-slide">
            <img :src="`${$img}/home/Banner5.jpg`" alt="" style="cursor: pointer;" class="home-banner" @click="testApp('https://mp.weixin.qq.com/s/FPsIkxnfrt9KfdpNr1CCHw')" />
          </div> -->
    <!-- <li :class="{ 'select-banner': homeBanner == 3 }" @click="setBanner(3)"></li> -->
    <!-- <div>
            <div class="swiper-box" style="position: relative" @mouseenter="mouseenter" @mouseleave="mouseleave">
                <div class="swiper-wrapper">
                
                    <div class="swiper-slide my-swiper-slide">
                        <img :src="`${$img}/home/Banner1.jpg`" alt="" class="home-banner" />
                    </div>
                    <div class="swiper-slide my-swiper-slide">
                        <img :src="`${$img}/home/Banner2.jpg`" alt="" class="home-banner" />
                    </div>
                    <div class="swiper-slide my-swiper-slide">
                        <img :src="`${$img}/home/Banner3.jpg`" alt="" class="home-banner" />
                    </div>
                </div>
                <ul class="banner-tab">
                    <li :class="{ 'select-banner': homeBanner == 0 }" @click="setBanner(0)"></li>
                    <li :class="{ 'select-banner': homeBanner == 1 }" @click="setBanner(1)"></li>
                    <li :class="{ 'select-banner': homeBanner == 2 }" @click="setBanner(2)"></li>
                  
                </ul>
            </div>
        </div>
        <div class="major">
            <div class="box" style="display: flex; justify-content: space-between">
                <div class="major-item">
                    <span>
                        <countTo ref="countTo5" :startVal="0" style="margin-right: -10px" :endVal="18" :duration="1000"
                            :autoplay="false" :decimals="0" :useEasing="true"></countTo>
                    </span><span>年</span>
                    <div>专研植发</div>
                </div>
                <div class="major-item">
                    <div style="
              display: flex;
              justify-content: center;
              align-items: flex-end;
              margin: 0;
            ">
                        <span>
                            <countTo ref="countTo1" :startVal="0" :endVal="40" :duration="1000" :autoplay="false"
                                :decimals="0" :useEasing="true"></countTo>
                        </span>
                        <span style="font-size: 25px">+
                            <div style="margin-top: 10px; font-size: 16px; margin-bottom: 4px">
                                家
                            </div>
                        </span>
                    </div>
                    <div>直营机构</div>
                </div>
                <div class="major-item">
                    <div style="
              display: flex;
              justify-content: center;
              align-items: flex-end;
              margin: 0;
            ">
                        <span>
                            <countTo ref="countTo2" :startVal="0" :endVal="100" :duration="1000" :autoplay="false"
                                :decimals="0" :useEasing="true"></countTo>
                        </span>
                        <span style="font-size: 25px">+
                            <div style="margin-top: 10px; font-size: 16px; margin-bottom: 4px">
                                位
                            </div>
                        </span>
                    </div>
                    <div>执业医师</div>
                </div>
                <div class="major-item">
                    <div style="
              display: flex;
              justify-content: center;
              align-items: flex-end;
              margin: 0;
            ">
                        <span>
                            <countTo ref="countTo3" :startVal="0" :endVal="900" :duration="1000" :autoplay="false"
                                :decimals="0" :useEasing="true"></countTo>
                        </span>
                        <span style="font-size: 25px">+
                            <div style="margin-top: 10px; font-size: 16px; margin-bottom: 4px">
                                位
                            </div>
                        </span>
                    </div>
                    <div>执业护士</div>
                </div>
                <div class="major-item">
                    <div style="
              display: flex;
              justify-content: center;
              align-items: flex-end;
              margin: 0;
            ">
                        <span>
                            <countTo ref="countTo4" :startVal="0" :endVal="40" :duration="1000" :autoplay="false"
                                :decimals="0" :useEasing="true"></countTo>
                            <div style="float: right; font-size: 52px">万</div>
                        </span>
                        <span style="font-size: 25px">+
                            <div style="margin-top: 10px; font-size: 16px; margin-bottom: 4px">
                                台
                            </div>
                        </span>
                    </div>
                    <div>植发手术</div>
                </div>
            </div>
        </div> -->
    <div class="item">
      <div class="allTitle wow fadeInUp">种植项目</div>
      <div class="allTitle-En wow fadeInUp">HAIR TRANSPLANT ITEMS</div>
      <div class="PlantingProject box wow fadeInUp" data-wow-delay="0.3s">
        <div
          class="PlantingProject-item"
          v-for="(item, index) in PlantingList"
          :key="index"
          @click="
            $router.push({
              name: 'project',
              params: {
                id: index + 1,
              },
            })
          "
        >
          <div class="greyimg">
            <img
              :src="`${$img}/home/grey/${index + 1}.png`"
              class="PlantingProject-img"
              alt=""
            />
          </div>
          <div class="whiteimg">
            <img
              :src="`${$img}/home/white/${index + 1}.png`"
              class="PlantingProject-img"
              alt=""
            />
          </div>
          <div class="PlantingProject-text">{{ item.text }}</div>
          <div class="PlantingProject-En">{{ item.en }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="item" style="background: #f7f8fc">
            <div class="allTitle wow fadeInUp">为什么选择碧莲盛</div>
            <div class="allTitle-En wow fadeInUp">REASONS FOR CHOICE</div>
            <div class="WhyChoose box wow fadeInUp">
                <div class="WhyChoose-item" :class="{ 'select-WhyChoose': index == 1 }" @mouseenter="setWhyChoose(1)"
                    @mouseleave="mouseWhyChoose">
                    <div class="WhyChoose-circular">
                        <div>医疗</div>
                        <div>初心</div>
                    </div>
                    <div>创始人具有</div>
                    <div>医生执业证书</div>
                </div>
                <div class="WhyChoose-item" :class="{ 'select-WhyChoose': index == 2 }" @mouseenter="setWhyChoose(2)"
                    @mouseleave="mouseWhyChoose">
                    <div class="WhyChoose-circular">
                        <div>主任</div>
                        <div>专家</div>
                    </div>
                    <div>十余位</div>
                    <div>主任级专家</div>
                </div>
                <div class="WhyChoose-item" :class="{ 'select-WhyChoose': index == 3 }" @mouseenter="setWhyChoose(3)"
                    @mouseleave="mouseWhyChoose">
                    <div class="WhyChoose-circular">
                        <div>技术</div>
                        <div>领先</div>
                    </div>
                    <div>连续13年参加</div>
                    <div>世界植发大会</div>
                </div>
                <div class="WhyChoose-item" :class="{ 'select-WhyChoose': index == 4 }" @mouseenter="setWhyChoose(4)"
                    @mouseleave="mouseWhyChoose">
                    <div class="WhyChoose-circular">
                        <div>单人</div>
                        <div>种植</div>
                    </div>

                    <div>技术体系采用</div>
                    <div>单人种植技术</div>
                </div>
                <div class="WhyChoose-item" :class="{ 'select-WhyChoose': index == 5 }" @mouseenter="setWhyChoose(5)"
                    @mouseleave="mouseWhyChoose">
                    <div class="WhyChoose-circular">
                        <div>历史</div>
                        <div>悠久</div>
                    </div>
                    <div>国内较早</div>
                    <div>工商注册</div>
                </div>
                <div class="WhyChoose-item" :class="{ 'select-WhyChoose': index == 6 }" @mouseenter="setWhyChoose(6)"
                    @mouseleave="mouseWhyChoose">
                    <div class="WhyChoose-circular">
                        <div>权威</div>
                        <div>认可</div>
                    </div>

                    <div>代表行业参加</div>
                    <div>两会健康论坛</div>
                </div>
                <div class="WhyChoose-item" :class="{ 'select-WhyChoose': index == 7 }" @mouseenter="setWhyChoose(7)"
                    @mouseleave="mouseWhyChoose">
                    <div class="WhyChoose-circular">
                        <div>医疗</div>
                        <div>保障</div>
                    </div>
                    <div>提供</div>
                    <div>医护责任险</div>
                </div>
            </div>
            <div class="swiper-boxS wow fadeInUp" data-wow-delay="0.3s" style="position: relative">
                <div class="swiper-wrapper">
                    <div class="swiper-slide my-swiper-slide" v-for="(item, index) in WhyChooseList" :key="index">
                        <div class="box" style="display: flex">
                            <div class="WhyChoose-left">
                                <div class="WhyChoose-index">0{{ index + 1 }}</div>
                                <div class="WhyChoose-title">
                                    {{ item.title }}
                                </div>
                                <div class="WhyChoose-Subtitle">
                                    {{ item.Subtitle }}
                                </div>
                                <div class="WhyChoose-text">
                                    {{ item.text }}
                                </div>
                                <div class="WhyChoose-line"></div>
                            </div>
                            <img :src="`${$img}/home/WhyChoose/${index + 1}.jpg`" alt="" class="WhyChoose-right" />
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <div class="item BrandStrength">
      <div class="allTitle wow fadeInUp">品牌实力</div>
      <div class="allTitle-En wow fadeInUp">BRAND STRENGTH</div>
      <div class="BrandStrength-swiper">
        <!--  :style="{ 'margin-left': BrandStrength == 1 ? '0' : `-1vw` }" -->
        <div class="swiper-container wow fadeInUp">
          <!---->
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div>
                <img
                  :src="`${$img}/home/BrandStrength/3.jpg`"
                  alt=""
                  class="BrandStrength-img"
                />
                <div class="BrandStrength-bottom">全国直营分院</div>
                <div class="BrandStrength-show">
                  <div class="BrandStrength-title">全国直营分院</div>
                  <div class="BrandStrength-Subtitle">直营连锁 辐射全国</div>
                  <div class="BrandStrength-text">
                    作为国内兼具规模和实力的专业植发连锁机构，碧莲盛在全国已有40余家旗舰医院，形成了以省会为中心辐射全国的植发医疗体系。碧莲盛坚持直营连锁模式，保证全国每一家分院技术与服务“0”差别，为广大发友提供品质如一的医疗服务。
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div>
                <img
                  :src="`${$img}/home/BrandStrength/4.jpg`"
                  alt=""
                  class="BrandStrength-img"
                />
                <div class="BrandStrength-bottom">植发技术创新</div>
                <div class="BrandStrength-show">
                  <div class="BrandStrength-title">植发技术创新</div>
                  <div class="BrandStrength-Subtitle">无需剃发 即刻变美</div>
                  <div class="BrandStrength-text">
                    碧莲盛坚持技术创新，秉承匠心精神，洞察新消费群体提出的新需求，于2020年正式推出不剃发植发技术，首次将“不剃发”这一概念真正落地，彻底改变了“先剃发再植发”的传统，在引领植发行业全新变革的的同时，将国内植发技术推向了一个全新高度，不断引领植发行业向更高标准探索。
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div>
                <img
                  :src="`${$img}/home/BrandStrength/5.jpg`"
                  alt=""
                  class="BrandStrength-img"
                />
                <div class="BrandStrength-bottom">专业医护团队</div>
                <div class="BrandStrength-show">
                  <div class="BrandStrength-title">专业医护团队</div>
                  <div class="BrandStrength-Subtitle">资质齐全 经验丰富</div>
                  <div class="BrandStrength-text">
                    碧莲盛通过严格的选聘制度、严苛的专业考核和审美培训，组建了一支经验丰富的医护团队，通过高超的技术、默契的配合、细致的分工，帮助近35万+名发友重拾自信，开启精彩人生。目前，碧莲盛拥有100余位实战派植发医生，800多名专业医护人员。
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div>
                <img
                  :src="`${$img}/home/BrandStrength/1.jpg`"
                  alt=""
                  class="BrandStrength-img"
                />
                <div class="BrandStrength-bottom">标准服务体系</div>
                <div class="BrandStrength-show">
                  <div class="BrandStrength-title">标准服务体系</div>
                  <div class="BrandStrength-Subtitle">统一规范 权益保障</div>
                  <div class="BrandStrength-text">
                    碧莲盛始终坚持为发友提供安全可靠、诚信透明的医疗服务，全国40家分院严格遵守统一、规范的就诊流程和服务标准，术前签订八大服务承诺协议、手术过程全程监控、术后跟踪服务，建立了一整套的权益保障体系，并与中国平安保险达成战略合作，全面保障发友的合法权益。
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div>
                <img
                  :src="`${$img}/home/BrandStrength/2.jpg`"
                  alt=""
                  class="BrandStrength-img"
                />
                <div class="BrandStrength-bottom">全球口碑</div>
                <div class="BrandStrength-show">
                  <div class="BrandStrength-title">全球口碑</div>
                  <div class="BrandStrength-Subtitle">兴与本土 全球认可</div>
                  <div class="BrandStrength-text">
                    碧莲盛坚持“发友至上，诚信为本”的发展理念，一切从发友的需求出发，为发友提供全面专业的毛发解决方案，积累了强大的用户口碑。碧莲盛自成立以来，坚持技术创新，与国际接轨，连续13年参加世界植发大会向世界展示中国的植发技术，并获得国际范围的认可和称赞。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-next Brandnext"></div>
      <div class="swiper-button-prev Brandprev"></div>
    </div>
    <div class="allTitle wow fadeInUp">名医专家团队</div>
    <div class="allTitle-En wow fadeInUp">EXPERTS</div>
    <div class="experts wow fadeInUp" data-wow-delay="0.3s">
      <div
        class="experts-item"
        :class="{ 'select-experts': selectExperts == 1 }"
        style="border-right: none"
        @click="selectExperts = 1"
      >
        名医堂
      </div>
      <div
        class="experts-item"
        :class="{ 'select-experts': selectExperts == 2 }"
        @click="selectExperts = 2"
        style="border-left: none"
      >
        技术院长
      </div>
    </div>
    <img
      data-wow-delay="0.3s"
      :src="`${$img}/home/1.jpg`"
      alt=""
      class="experts-img wow fadeInUp"
      v-if="selectExperts == 1"
      :key="123"
      @click="$router.push({ name: 'team', params: { type: 1 } })"
    />
    <img
      :src="`${$img}/home/2.jpg`"
      alt=""
      class="experts-img"
      :key="456"
      @click="$router.push({ name: 'team', params: { type: 2 } })"
      v-else
    />
  </div>
</template>

<script>
import countTo from "vue-count-to";
import Milepost from "swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    countTo,
  },
  mounted() {
    // this.$refs.countTo1.start();
    // this.$refs.countTo2.start();
    // this.$refs.countTo3.start();
    // this.$refs.countTo4.start();
    // this.$refs.countTo5.start();
    this.initSwiper();
    this.initSwiperT();
    this.initSwiperS();
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
  },

  data() {
    return {
      num: 8,
      wow: null,
      BrandStrength: 0,
      selectExperts: 1,
      homeBanner: 0,
      WhyChooseList: [
        {
          title: "坚守医疗初心",
          Subtitle: "创始人具有医生执业资格证书",
          text: "碧莲盛创始人尤丽娜不仅是一名具有执业资格证书的植发医生，还是中国民营连锁植发行少有的世界植发大会高级资深会员。创始人的医生出身，为碧莲盛植发带来了不一样的医疗理念，碧莲盛发展的每一步，都始终坚守医疗本质。",
        },
        {
          title: "高级医师团队",
          Subtitle: "十余位主任级专家",
          text: "医生数量与质量是衡量医疗服务机构优劣的重要指标，碧莲盛不仅组建了100余位资质齐全、经验丰富的专业医生团队，还拥有10余位主任/副主任医师，其中，有行业唯一一位享受国务院特殊津贴的教授，这在整个医美行业都是不多见的。",
        },
        {
          title: "植发技术领先",
          Subtitle: "连续13年参加世界植发大会",
          text: "2008年，碧莲盛植发第一次受邀出席世界植发大会，之后连续13年作为中国植发行业的的代表受邀出席世界植发大会，是中国民营连锁植发机构罕见的世界植发大会高级资深会员，向世界植发行业展示中国领先的植发技术。",
        },

        {
          title: "独有单人种植",
          Subtitle: "全部技术体系采用单人种植技术",
          text: "碧莲盛植发自主研发了单人种植-即插即种技术，种植过程由一名专业种植师独立完成，贯穿手术全程，精准掌握头皮弹性以及种植深浅程度，提升种植的疏密度、深浅度、协调度、美感度。",
        },
        {
          title: "注册历史悠久",
          Subtitle: "国内较早工商注册",
          text: "碧莲盛植发工商注册时间为2005年，是中国植发行业注册时间较早、历史悠久的连锁植发机构。",
        },
        {
          title: "权威机构认可",
          Subtitle: "代表植发行业参加两会健康论坛",
          text: "2021年3月，作为植发行业的代表，碧莲盛创始人尤丽娜受邀录制人民网《人民会客厅》两会特别版——《两会时刻》系列访谈栏目，与两会代表、专家学者共同解读新型消费的特点、机遇和趋势。",
        },
        {
          title: "强大医疗保障",
          Subtitle: "与平安保险合作提供医疗责任险",
          text: "2020年6月，碧莲盛植发与中国平安保险达成战略合作，为植发医护团队提供百万责任险，为发友的植发安全与效果提供有力的保障，让发友“放心植发，安心变美”。",
        },
      ],
      index: 1,

      swiperIndex: 2,
      PlantingList: [
        {
          text: "顶部加密",
          en: "OVERHEAD HAIR TRANSPLANT",
        },
        {
          text: "眉毛种植",
          en: "EYEBROW HAIR TRANSPLANT",
        },
        {
          text: "发际线种植",
          en: "HAIRLINE HAIR TRANSPLANT",
        },
        {
          text: "胡须种植",
          en: "BEARD HAIR TRANSPLANT",
        },
        {
          text: "鬓角种植",
          en: "SIDEBURNS HAIR TRANSPLANT",
        },
        {
          text: "疤痕种植",
          en: "HAIR SCAR REPAIR",
        },
        {
          text: "体毛种植",
          en: "BODY HAIR TRANSPLANT",
        },
        {
          text: "美人尖种植",
          en: "WIDOW'S PEAK HAIR TRANSPLANT",
        },
      ],
      mySwiperT: null,
      mySwiper: null,
      mySwiperS: null,
    };
  },
  beforeDestroy() {
    this.mySwiper.autoplay.stop();
    this.mySwiperS.autoplay.stop();
    this.mySwiperT.autoplay.stop();
    this.wow.stop();
  },
  methods: {
    testApp(url) {
      location.href = url;
      //   console.log(url);
      //   var timeout,t = 1000,hasApp = true;
      //   setTimeout(function () {
      //     if (!hasApp) {
      //       //没有安装微信
      //       var r = confirm("您没有安装微信，请先安装微信!");
      //       if (r == true) {
      //         location.href = "http://weixin.qq.com/"
      //       }
      //     } else {
      //       //安装微信
      //     }
      //     document.body.removeChild(ifr);
      //   },2000)

      //   var t1 = Date.now();
      //   var ifr = document.createElement("iframe");
      //   ifr.setAttribute('src',url);
      //   ifr.setAttribute('style','display:none');
      //   document.body.appendChild(ifr);
      //   timeout = setTimeout(function () {
      //     var t2 = Date.now();
      //     if (!t1 || t2 - t1 < t + 100) {
      //       hasApp = false;
      //     }
      //   },t);
    },
    mySwiperSlideTo(a) {
      console.log(a);
      this.mySwiperT.slideTo(a);
    },
    mouseWhyChoose() {
      this.mySwiperS.autoplay.start();
    },
    setWhyChoose(a) {
      this.index = a;
      this.mySwiperS.slideTo(a);
      this.mySwiperS.autoplay.stop();
    },
    setBanner(a) {
      this.homeBanner = a;
      this.mySwiper.slideTo(a + 1);
    },
    mouseenter() {
      this.mySwiper.autoplay.stop();
    },
    mouseleave() {
      this.mySwiper.autoplay.start();
    },
    initSwiper() {
      let that = this;
      this.mySwiper = new Milepost(".swiper-box", {
        direction: "horizontal",
        loop: true,
        on: {
          slideChangeTransitionStart: function () {
            that.homeBanner = this.realIndex;
          },
        },
        slidesPerView: 1,
        autoplay: {
          disableOnInteraction: false,
          delay: 2000, //1秒切换一次
        },

        autoplayDisableOnInteraction: false,
      });
    },
    initSwiperT() {
      let that = this;
      this.mySwiperT = new Milepost(".swiper-container", {
        loop: true,
        //根据slider的宽度⾃动分布
        slidesPerView: "auto",
        // active下居中显⽰
        on: {
          slideChangeTransitionStart: function () {
            that.BrandStrength = this.realIndex + 1;
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        centeredSlides: true,
      });
    },
    initSwiperS() {
      let that = this;
      this.mySwiperS = new Milepost(".swiper-boxS", {
        direction: "horizontal",
        loop: true,
        on: {
          slideChangeTransitionStart: function () {
            that.index = this.realIndex + 1;
          },
        },
        slidesPerView: 1,
        autoplay: {
          disableOnInteraction: false,
          delay: 2000, //1秒切换一次
        },

        autoplayDisableOnInteraction: false,
      });
    },
  },
};
</script>

<style lang="scss"></style>
