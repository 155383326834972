<template>
  <div class="team">
    <img :src="`${$img}/team/banner.jpg`" class="banner" />
    <div class="box">
      <div class="team-search" id="location">
        <input
          type="text"
          v-model="name"
          style="font-size: 16px; color: #666666; padding-left: 10px"
          @keyup.enter="$router.push({ path: 'team', query: { search: name } })"
          placeholder="请输入医生姓名"
        />
        <div
          class="search"
          @click="$router.push({ path: 'team', query: { search: name } })"
        >
          <img :src="`${$img}/team/search.png`" alt="" />
        </div>
      </div>
      <div class="Dean" v-if="Dean !== 4">
        <div
          class="Dean-item"
          :class="{ selectDean: Dean == 1 }"
          @click="
            Dean = 1;
            index = 1;
            isAdd = false;
          "
        >
          名医堂
        </div>
        <div
          class="Dean-item"
          :class="{ selectDean: Dean == 2 }"
          @click="
            Dean = 2;
            index = 1;
            isAdd = false;
          "
        >
          技术院长
        </div>
        <div
          class="Dean-item"
          :class="{ selectDean: Dean == 3 }"
          @click="
            Dean = 3;
            index = 1;
            isAdd = false;
          "
        >
          植发医师
        </div>
      </div>

      <div class="temaList" v-if="Dean == 1">
        <div
          class="temaList-item"
          v-for="item in list"
          :key="item.title"
          @click="details(item.numberIndex)"
        >
          <div class="temaList-bg">DOCTO<span>R</span></div>
          <div class="temaList-bg">{{ item.en }}</div>
          <img
            :src="`${$img}/team/Famous/list/${
              Number(item.numberIndex) + 1
            }.png`"
            alt=""
          />

          <div class="temaList-line"></div>
          <div class="temaList-title">{{ item.title }}</div>
          <div class="temaList-position">{{ item.position }}</div>
          <div class="temaList-details">详细介绍</div>
        </div>
      </div>
      <div class="temaList" v-if="Dean == 2">
        <div
          class="temaList-item"
          v-for="(item, index) in list"
          :key="item.title"
          @click="details(item.numberIndex)"
        >
          <div class="temaList-bg">DOCTO<span>R</span></div>
          <div class="temaList-bg">{{ item.en }}</div>
          <img :src="`${$img}${item.src}?v=1`" alt="" v-if="item.src" />
          <img
            :src="`${$img}/team/Technical/list/${
              Number(item.numberIndex) + 2
            }.png`"
            alt=""
            v-else
          />

          <div class="temaList-line"></div>
          <div class="temaList-title">{{ item.title }}</div>
          <div class="temaList-position">{{ item.position }}</div>
          <div class="temaList-details">详细介绍</div>
        </div>
      </div>
      <div class="temaList" v-if="Dean == 3">
        <div
          class="temaList-item"
          v-for="(item, index) in list"
          :key="item.title"
          @click="details(item.numberIndex)"
        >
          <div class="temaList-bg">DOCTO<span>R</span></div>
          <div class="temaList-bg">{{ item.en }}</div>

          <img
            :src="`${$img}/team/Transplanter/list/${
              Number(item.numberIndex) + 1
            }.png`"
            alt=""
            v-if="index <= 7"
          />
          <img
            v-else
            :src="`${$img}/team/Transplanter/list/${
              Number(item.numberIndex) + 2
            }.png?v=1`"
            alt=""
          />

          <div class="temaList-line"></div>
          <div class="temaList-title">{{ item.title }}</div>
          <div class="temaList-position">{{ item.position }}</div>
          <div class="temaList-details">详细介绍</div>
        </div>
      </div>
      <div class="temaList" v-if="Dean == 4">
        <div
          class="temaList-item"
          v-for="(item, index) in selectList"
          :key="index"
          @click="details(item.numberIndex, item.type)"
        >
          <div class="temaList-bg">DOCTO<span>R</span></div>
          <div class="temaList-bg">{{ item.en }}</div>
          <img
            :src="`${$img}/team/${item.path}/list/${
              Number(item.numberIndex) + 1
            }.png`"
            alt=""
            v-if="item.type == 1"
          />
          <img
            :src="`${$img}${item.src}`"
            alt=""
            v-else-if="item.type == 2 && item.src"
          />

          <img
            :src="`${$img}/team/${item.path}/list/${
              Number(item.numberIndex) + 2
            }.png`"
            alt=""
            v-else-if="item.type == 2 && !item.src"
          />
          <img
            :src="`${$img}/team/${item.path}/list/${
              Number(item.numberIndex) + 1
            }.png`"
            alt=""
            v-else-if="item.type == 3 && item.numberIndex <= 7"
          />

          <img
            v-else
            :src="`${$img}/team/Transplanter/list/${
              Number(item.numberIndex) + 2
            }.png?v=1`"
            alt=""
          />
          <div class="temaList-line"></div>
          <div class="temaList-title">{{ item.title }}</div>
          <div class="temaList-position">{{ item.position }}</div>
          <div class="temaList-details">详细介绍</div>
        </div>
      </div>
      <div class="tema-Add" @click="addIndex" v-if="!isAdd && Dean !== 4">
        查看更多
      </div>
      <div class="tema-margin"></div>
    </div>
  </div>
</template>

<script>
import FamousList from "@/utils/FamousDoctorHall";
import TechnicalList from "../utils/TechnicalDean";
import HaircalList from "../utils/HairTransplanter";
export default {
  name: "team",
  data() {
    return {
      Dean: 1,
      isAdd: false,
      index: 1,
      name: "",
      selectList: [],
    };
  },
  computed: {
    list() {
      let list = [];
      if (this.Dean == 1) {
        list = FamousList;
      } else if (this.Dean == 3) {
        list = HaircalList.filter((item) => item.show !== false);
      } else {
        list = TechnicalList;
      }
      const length = this.index * 9;
      if (list.length <= length) {
        this.isAdd = true;
        return list;
      } else {
        return list.filter((item, index) => index < length);
      }
    },
  },
  methods: {
    getName() {
      this.Dean = 4;
      const list = [...FamousList, ...TechnicalList, ...HaircalList].filter(
        (item) => {
          return item.title.indexOf(this.name) !== -1;
        }
      );
      this.selectList = [];
      list.forEach((el) => {
        if (!this.selectList.find((e) => e.title == el.title)) {
          this.selectList.push(el);
        }
      });
    },
    getNameT() {
      const list = [...FamousList, ...TechnicalList, ...HaircalList].filter(
        (item) => {
          return item.title.indexOf(this.name) !== -1;
        }
      );
      this.selectList = [];
      list.forEach((el) => {
        if (!this.selectList.find((e) => e.title == el.title)) {
          this.selectList.push(el);
        }
      });
    },
    addIndex() {
      this.index++;
    },
    details(a, b) {
      const type = b || this.Dean;
      const index = `${String(a)}-${String(type)}`;

      this.$router.push({
        path: "/Personal",
        query: {
          index: index,
        },
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.search) {
      this.name = to.query.search;
      this.getName();
    } else {
      this.name = "";
      this.Dean = 1;
    }
  },
  activated() {
    if (this.$route.params.type) {
      this.Dean = this.$route.params.type;
      document.querySelector("#location").scrollIntoView(true);
    }
    if (this.$route.query.search) {
      this.name = this.$route.query.search;
      this.getName();
    }
    if (this.$route.params.searchA) {
      this.name = this.$route.params.searchA;
      this.getName();
      document.querySelector("#location").scrollIntoView(true);
    }
  },

  beforeRouteEnter: (to, from, next) => {
    if (from.name !== "Personal") {
      next((vm) => {
        vm.name = "";
        vm.Dean = 1;
        vm.getNameT();
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss">
input::-webkit-input-placeholder {
  color: #dcdcdc;
}
</style>
