export default [
  {
    en: "LI",
    path: "Transplanter",
    type: "3",
    title: "李根亮",
    show: false,
    numberIndex: "0",
    text: [
      "碧莲盛医疗技术带头人",
      "碧莲盛植发技术培训中心特聘带教官",
      "参与不剃发植发技术的核心研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "副主任医师，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，参与不剃发植发技术的核心研发。近二十年外科临床经验，植发手术经验丰富，多次受邀参加国内毛发移植技术交流论坛，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["110140100006075", "200514110142326800918651"],
    position: "副主任医师",
  },
  {
    en: "ZHANG",
    path: "Transplanter",
    type: "3",
    title: "张章",
    numberIndex: "1",
    text: [
      "主治医师",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与新式毛囊提取设备的研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、大面积种植及不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与新式毛囊提取设备的研发。毕业于齐齐哈尔医学院，多次受邀参加国内毛发移植技术交流论坛，现场演示精密即插集种植发技术。擅长艺术种植、大面积种植及不剃发植发。",
    number: ["110230200005032", "201423110230204198812192112"],
    position: "主治医师",
  },
  {
    en: "GUO",
    path: "Transplanter",
    type: "3",
    title: "郭杨",
    numberIndex: "2",
    text: [
      "整形美容外科主诊医师",
      "碧莲盛植发技术培训中心特聘带教官",
      "碧莲盛植发技术研究中心核心成员",
      "多次参加国内植发医生学术交流会议",
      "擅长大面积提取、艺术设计、不剃发植发及治脱指导",
    ],
    detail:
      "主治医师，整形美容外科主诊医师，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，碧莲盛北京总部技术专家。多年外科手术经验，在后枕部均匀提取方面有独到经验。多次在国内植发医生交流会议上进行疑难案例植发方式分享，擅长大面积提取、艺术设计、不剃发植发及治脱指导，在脱发预防养护和植发技术改进领域有独到见解。",
    number: ["110131000005569", "201413110131022198610035433"],
    position: "主治医师",
  },
  {
    title: "李中先",
    numberIndex: "3",
    en: "LI",
    path: "Transplanter",
    type: "3",
    text: [
      "长江大学临床医学专业毕业",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与新式毛囊提取设备的研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "主治医师，长江大学临床医学专业毕业，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与新式毛囊提取设备的研发。近二十年外科临床经验，积累了丰富的医学理论基础和临床实践经验，多次受邀参加国内毛发移植技术交流论坛，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["110420500003533", "200742110420525198004012834"],
    position: "主治医师",
  },
  {
    numberIndex: "4",
    en: "CHANG",
    path: "Transplanter",
    type: "3",
    title: "畅昭",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与新式毛囊提取设备的研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与新式毛囊提取设备的研发。拥有十多年毛发移植临床经验，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["210320582000615", "201632110410724198901284533"],
    position: "主治医师",
  },
  {
    en: "LIU",
    path: "Transplanter",
    type: "3",
    numberIndex: "5",
    title: "刘敬",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与不剃发植发技术的器械升级研发",
      "多次受邀参加参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密和不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械升级研发。拥有十余年毛发移植临床经验，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密和不剃发植发。",
    number: ["110430700004540", "201343110430725198701190014"],
    position: "主治医师",
  },
  {
    numberIndex: "6",
    en: "YU",
    path: "Transplanter",
    type: "3",
    title: "于艳",
    text: [
      "碧莲盛植发技术培训中心特聘带教官",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的核心研发",
      "参与精密即插即种技术的升级研发",
      "擅长大面积种植、不剃发植发、加密种植、艺术及疤痕种植",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，碧莲盛植发技术培训中心特聘带教官，参与不剃发植发技术的核心研发，参与精密即插即种技术的升级研发，擅长大面积种植、不剃发植发、加密种植、艺术及疤痕种植，在治脱方案选择及术后指导方面有独到心得。",
    number: ["110120104000508", "2001131101329027305200624"],
    position: "主治医师",
  },
  {
    en: "WEN",
    path: "Transplanter",
    type: "3",
    numberIndex: "7",
    title: "文根源",
    text: [
      "原南华大学附属第一医院医疗美容科医生",
      "碧莲盛植发技术研究中心核心成员",
      "倡导大面积种植精细化操作",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "整形外科、皮肤科、普外科三科主治医师，原南华大学附属第一医院医疗美容科医生，碧莲盛植发技术研究中心核心成员，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发，深受发友信赖。",
    number: ["110430200004491", "201143110430426198011174848"],
    position: "主治医师",
  },
  {
    title: "黄晨",
    numberIndex: "8",
    en: "HUANG",
    path: "Transplanter",
    type: "3",
    text: [
      "第四军医大学硕士",
      "碧莲盛植发技术研究中心核心成员",
      "参与研发精密即插即种植发技术",
      "参与碧莲盛毛发康养体系的建设",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "整形外科主治医师，第四军医大学硕士，碧莲盛植发技术研究中心核心成员，参与研发精密即插即种植发技术，参与碧莲盛毛发康养体系的建设。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，多次受邀参加国内毛发移植技术交流论坛，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["110140181000225", "200614110149001820218477"],
    position: "主治医师",
  },
  {
    title: "汪贤文",
    numberIndex: "9",
    en: "WANG",
    path: "Transplanter",
    type: "3",
    text: [
      "三峡大学临床医学专业毕业",
      "碧莲盛植发技术研究中心核心成员",
      "参与毛囊提取器械改进研发",
      "参与新式毛囊提取设备的研发",
      "倡导大面积种植精细化操作",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "整形外科主治医师，三峡大学临床医学专业毕业，碧莲盛植发技术研究中心核心成员，参与毛囊提取器械改进研发，参与新式毛囊提取设备的研发。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，倡导大面积种植精细化操作，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["110420684000819", "201342110421126198710220150"],
    position: "主治医师",
  },
  {
    title: "冯文丽",
    numberIndex: "10",
    en: "FENG",
    path: "Transplanter",
    type: "3",
    show: false,
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与毛发提取器械改进研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与毛发提取器械改进研发。植发手术经验丰富，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["110450203000036", "201037110370523198505172417"],
    position: "主治医师",
  },
  {
    title: "宋卫昆",
    numberIndex: "11",
    en: "SONG",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与不剃发植发技术的器械升级研发",
      "多次受邀参加参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密和不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，不剃发植发技术的器械升级研发，多次受邀参加参加国内毛发移植技术交流论坛，擅长艺术种植、多维加密和不剃发植发，深受发友信赖。",
    number: ["110130600009354", "201013110130626198410277638"],
    position: "主治医师",
  },
  {
    title: "吴剑山",
    en: "WU",
    path: "Transplanter",
    type: "3",
    numberIndex: "12",
    show: false,
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与精密即插即种植发技术的升级研发",
      "倡导大面积种植精细化操作",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与精密即插即种植发技术的升级研发。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，倡导大面积种植精细化操作，倡导将植发技术与艺术美学结合实施，擅长艺术种植、多维加密及不剃发植发。",
    number: ["110441900018061", "201533110350301198702110730"],
    position: "主治医师",
  },
  {
    title: "田松",
    en: "TIAN",
    path: "Transplanter",
    type: "3",
    show: false,
    numberIndex: "13",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的升级研发",
      "参与精密即插即种植发技术的升级研发",
      "倡导植养固体系与植发手术紧密结合",
      "擅长大面积种植、艺术种植及不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的升级研发，参与精密即插即种植发技术的升级研发。多年毛发移植临床经验，倡导大面积种植精细化操作，倡导植养固体系与植发手术紧密结合，擅长大面积种植、艺术种植及不剃发植发，在治脱方案选择及术后指导方面有独到心得。",
    number: ["210130700004066", "201613110130732198602071557"],
    position: "主治医师",
  },
  {
    title: "米哲亮",
    numberIndex: "14",
    en: "MI",
    path: "Transplanter",
    type: "3",
    text: [
      "湖南省中医药和中西医结合学会医学美容专委会委员",
      "碧莲盛植发技术研究中心核心成员",
      "参与新式毛囊提取设备的研发",
      "参与精密即插即种植发技术的器械改进",
      "擅长大面积种植、艺术种植及不剃发植发",
    ],
    detail:
      "整形外科主治医师，湖南省中医药和中西医结合学会医学美容专委会委员，碧莲盛植发技术研究中心核心成员，参与新式毛囊提取设备的研发，参与精密即插即种植发技术的器械改进，在治疗脱发方案选择及术后指导方面有丰富经验，擅长大面积种植、艺术种植及不剃发植发。",
    number: ["110431300001603", "201543110432524198312057716"],
    position: "主治医师",
  },
  {
    numberIndex: "15",
    en: "ZHAO",
    path: "Transplanter",
    type: "3",
    title: "赵辉",
    show: false,
    text: [
      "第二军医大学临床医学专业毕业",
      "原海军青岛第二疗养院医学美容科医生",
      "碧莲盛植发技术研究中心核心成员",
      "参与新式毛囊提取设备的研发",
      "参与精密即插即种植发技术的升级研发",
      "倡导植养固体系与植发手术紧密结合",
      "擅长大面积种植、艺术种植及不剃发植发",
    ],
    detail:
      "主治医师，第二军医大学临床医学专业毕业，原海军青岛第二疗养院医学美容科医生，碧莲盛植发技术研究中心核心成员，参与新式毛囊提取设备的研发，参与精密即插即种植发技术的升级研发。多年毛发移植临床经验，倡导植养固体系与植发手术紧密结合，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积种植、艺术种植及不剃发植发。",
    number: ["110370202001994", "201188110370212197905088521"],
    position: "主治医师",
  },
  {
    numberIndex: "16",
    title: "张雪敏",
    en: "ZHANG",
    path: "Transplanter",
    type: "3",
    text: [
      "医疗美容主诊医师",
      "温州医科大学硕士",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与毛发提取器械改进研发",
      "倡导植养固体系与植发手术紧密结合",
      "擅长大面积种植、艺术种植及不剃发植发",
    ],
    detail:
      "主治医师，医疗美容主诊医师，温州医科大学硕士，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与毛发提取器械改进研发。多年毛发移植临床经验，倡导植养固体系与植发手术紧密结合，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积种植、艺术种植及不剃发植发。",
    number: ["110330109001914", "201333110410621198807151182"],
    position: "主治医师",
  },
  {
    title: "张志坚",
    numberIndex: "17",
    en: "ZHANG",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与新式毛囊提取设备的研发",
      "参与精密即插即种植发技术的升级研发",
      "倡导大面积种植精细化操作",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与新式毛囊提取设备的研发，参与精密即插即种植发技术的升级研发。拥有近十年毛发移植临床经验，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。",
    number: ["110450100005952", "201445110452123198707127016"],
    position: "主治医师",
  },
  {
    numberIndex: "18",
    en: "LIU",
    path: "Transplanter",
    type: "3",
    title: "刘旭",
    show: false,
    text: [
      "曾在攀钢集团总医院美容外科进修学习",
      "碧莲盛植发技术研究中心核心成员",
      "参与毛发提取器械改进研发",
      "参与不剃发植发技术的研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "主治医师，曾在攀钢集团总医院美容外科进修学习，碧莲盛植发技术研究中心核心成员，参与毛发提取器械改进研发，参与不剃发植发技术的研发，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发，深受发友信赖。",
    number: ["2015099111000284", "201450110500382198901294655"],
    position: "主治医师",
  },
  {
    title: "李嘉恒",
    numberIndex: "19",
    en: "LI",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与毛囊提取器械改进研发",
      "参与不剃发植发技术的研发",
      "倡导大面积种植精细化操作",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与毛囊提取器械改进研发，参与不剃发植发技术的研发。多年外科临床经验，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["210411721000129", "201741110412824199312097715"],
    position: "主治医师",
  },
  {
    title: "梅孟杰",
    numberIndex: "20",
    en: "MEI",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "倡导大面积种植精细化操作",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，倡导大面积种植精细化操作，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。",
    number: ["110530102003427", "201241110412828198404295138"],
    position: "主治医师",
  },
  {
    en: "LV",
    path: "Transplanter",
    type: "3",
    title: "吕晓明",
    numberIndex: "21",
    text: [
      "美容主诊医师",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与碧莲盛毛发康养体系的建设",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "美容主诊医师，整形外科主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与碧莲盛毛发康养体系的建设，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发，倡导将植发技术与艺术美学结合实施。",
    number: ["110210681000801", "201421110210281198811266844"],
    position: "主治医师",
  },
  {
    title: "贺建奇",
    numberIndex: "22",
    en: "HE",
    path: "Transplanter",
    show: false,
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与新式毛囊提取设备的研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与新式毛囊提取设备的研发。植发手术经验丰富，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发，倡导将植发技术与艺术美学结合实施。",
    number: ["110430481000861", "201443110430481198601176711"],
    position: "主治医师",
  },
  {
    numberIndex: "23",
    title: "龙琴",
    en: "LONG",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与不剃发植发技术的器械升级研发",
      "多次受邀参加参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密和不剃发植发",
    ],
    detail:
      "主治医师，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械升级研发。拥有十余年毛发移植临床经验，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密和不剃发植发。",
    number: ["110360926000188", "201136110362227198107280913"],
    position: "主治医师",
  },
  {
    numberIndex: "24",
    title: "王凯",
    en: "WANG",
    path: "Transplanter",
    type: "3",
    text: [
      "美容外科主诊医师",
      "碧莲盛植发技术研究中心核心成员",
      "碧莲盛植发养护体系制定者之一",
      "参与不剃发植发技术的器械改进",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "主治医师，美容外科主诊医师，碧莲盛植发技术研究中心核心成员，碧莲盛植发养护体系制定者之一，参与不剃发植发技术的器械改进。多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["1104101000015160", "201365110410423198310202038"],
    position: "主治医师",
  },
  {
    numberIndex: "25",
    title: "孙锐",
    en: "SUN",
    path: "Transplanter",
    type: "3",
    text: [
      "华中科技大学同济医学院毕业",
      "碧莲盛植发技术研究中心核心成员",
      "参与毛囊提取器械改进研发",
      "曾荣获湖北省医疗器械科技进步三等奖",
      "擅长艺术种植、大面积种植和不剃发植发",
    ],
    detail:
      "华中科技大学同济医学院毕业，碧莲盛植发技术研究中心核心成员，参与毛囊提取器械改进研发，曾荣获湖北省医疗器械科技进步三等奖。拥有十余年毛发移植临床经验，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、大面积种植和不剃发植发。",
    number: ["110420804000001", "200842110420802198107290919"],
    position: "执业医师",
  },
  {
    numberIndex: "26",
    en: "QIANG",
    path: "Transplanter",
    type: "3",
    title: "强志顺",
    text: [
      "碧莲盛植发技术培训中心特聘带教官",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与精密即插即种植发技术的研发",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与精密即插即种植发技术的研发。拥有多年毛发移植临床经验，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["110610500001415", "201261110642222198410180830"],
    position: "执业医师",
  },
  {
    numberIndex: "27",
    title: "史后彬",
    en: "SHI",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "曾在济南军区总医院进修",
      "参与研发精密即插即种植发技术",
      "参与新式毛囊提取设备的研发",
      "擅长大面积种植、发际线修复和不剃发植发",
    ],
    detail:
      "曾在济南军区总医院进修，碧莲盛植发技术研究中心核心成员，参与研发精密即插即种植发技术，参与新式毛囊提取设备的研发，提出多项术后恢复的针对性养护建议，提出阶段式治脱的新式理念，擅长大面积种植、发际线修复和不剃发植发。",
    number: ["210370923000628", "201837110370923199006180611"],
    position: "执业医师",
  },
  {
    en: "LI",
    path: "Transplanter",
    type: "3",
    numberIndex: "28",
    title: "李世涛",
    text: [
      "河南科技大学临床医学专业毕业",
      "碧莲盛植发技术研究中心核心成员",
      "参与研发精密即插即种植发技术",
      "参与碧莲盛毛发康养体系的建设",
      "擅长个性化定制方案、艺术种植和不剃发植发",
    ],
    detail:
      "河南科技大学临床医学专业毕业，碧莲盛植发技术研究中心核心成员，参与研发精密即插即种植发技术，参与碧莲盛毛发康养体系的建设，拥有多年毛发移植临床经验，倡导大面积种植精细化操作，擅长个性化定制方案、艺术种植和不剃发植发。",
    number: ["110411729000104", "201341110410724198206016019"],
    position: "执业医师",
  },
  {
    numberIndex: "29",
    en: "HE",
    path: "Transplanter",
    type: "3",
    title: "贺俊",
    text: [
      "毕业于南华大学临床医学专业",
      "碧莲盛植发技术培训中心特聘带教官",
      "参与精密即插即种植发技术养固体系",
      "参与不剃发植发技术的器械升级研发",
      "倡导大面积种植精细化操作",
      "擅长量身定制个性化植发方案、发际线修复和艺术种植",
    ],
    detail:
      "毕业于南华大学临床医学专业，碧莲盛植发技术培训中心特聘带教官，参与精密即插即种植发技术养固体系，参与不剃发植发技术的器械升级研发，倡导大面积种植精细化操作。拥有多年毛发移植临床经验，有良好的艺术种植审美，擅长量身定制个性化植发方案、发际线修复和艺术种植。",
    number: ["110430481000910", "201543110430419198009050572"],
    position: "执业医师",
  },
  {
    en: "LI",
    path: "Transplanter",
    type: "3",
    numberIndex: "30",
    title: "李进",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与不剃发植发技术的器械升级研发",
      "多次受邀参加参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械升级研发。多次受邀参加参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["110610524000281", "20096111061052519841030001X"],
    position: "执业医师",
  },
  {
    en: "WANG",
    path: "Transplanter",
    type: "3",
    numberIndex: "31",
    title: "王卜宣",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与精密即插即种植发技术的升级研发",
      "多次参加国内植发医生学术交流会议",
      "倡导植养固体系与植发手术紧密结合",
      "擅长多维加密、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与精密即插即种植发技术的升级研发。多年毛发移植临床经验，多次参加国内植发医生学术交流会议，倡导植养固体系与植发手术紧密结合，擅长根据脱发者的具体情况量身打造植发方案，擅长多维加密、艺术种植和不剃发植发。",
    number: ["110650103002117", "201765110500242198809152359"],
    position: "执业医师",
  },
  {
    en: "LIN",
    path: "Transplanter",
    type: "3",
    numberIndex: "32",
    title: "林迪生",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与新式毛囊提取设备的研发",
      "参与精密即插即种植发技术的器械改进",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与新式毛囊提取设备的研发，参与精密即插即种植发技术的器械改进。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，多次受邀参加国内毛发移植技术交流论坛，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210450422000650", "201845110450422198803023012"],
    position: "执业医师",
  },
  {
    numberIndex: "33",
    en: "XIA",
    path: "Transplanter",
    type: "3",
    title: "夏振强",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与碧莲盛毛发康养体系的建设",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与碧莲盛毛发康养体系的建设，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发，倡导将植发技术与艺术美学结合实施。",
    number: ["110210100020475", "201721110370982198706282470"],
    position: "执业医师",
  },
  {
    en: "WU",
    path: "Transplanter",
    type: "3",
    numberIndex: "34",
    title: "伍湘涛",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与碧莲盛毛发康养体系的建设",
      "参与精密即插即种植发技术的升级研发",
      "倡导植养固体系与植发手术紧密结合",
      "擅长多维加密、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与碧莲盛毛发康养体系的建设，参与精密即插即种植发技术的升级研发。多年毛发移植临床经验，倡导植养固体系与植发手术紧密结合，在后枕部均匀提取方面有独到心得，擅长多维加密、艺术种植和不剃发植发。",
    number: ["110460100006582", "201046110432322197406147933"],
    position: "执业医师",
  },
  {
    en: "WANG",
    path: "Transplanter",
    type: "3",
    numberIndex: "35",
    title: "王荣",
    text: [
      "原灌云仁济医院皮肤科医生",
      "碧莲盛植发技术研究中心核心成员",
      "拥有十余年毛发移植临床经验",
      "参与碧莲盛头发康养体系的建设",
      "参与不剃发植发技术的研发",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "原灌云仁济医院皮肤科医生，碧莲盛植发技术研究中心核心成员，参与碧莲盛头发康养体系的建设，参与不剃发植发技术的研发。拥有十余年毛发移植临床经验，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["210320700000920", "201932110320723198912105010"],
    position: "执业医师",
  },
  {
    numberIndex: "36",
    title: "冯广飞",
    en: "FENG",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与毛囊提取器械改进研发",
      "参与不剃发植发技术的研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与毛囊提取器械改进研发，参与不剃发植发技术的研发。拥有十余年毛发移植临床经验，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210410700002946", "201841110410724198701062039"],
    position: "执业医师",
  },
  {
    numberIndex: "37",
    title: "张辛宁",
    en: "ZHANG",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与精密即插即种植发技术的升级研发",
      "倡导大面积种植精细化操作",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与精密即插即种植发技术的升级研发。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210341225000746", "201834110341221198903040010"],
    position: "执业医师",
  },
  {
    numberIndex: "38",
    title: "孟文祥",
    en: "MENG",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与毛发提取器械改进研发",
      "倡导大面积种植精细化操作",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与毛发提取器械改进研发。从事毛发移植工作多年，积累了丰富的医学理论基础和临床实践经验，倡导大面积种植精细化操作，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["210320582000670", "201832110340121199109013750"],
    position: "执业医师",
  },
  {
    numberIndex: "39",
    title: "杨勇华",
    en: "YANG",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与毛发提取器械改进研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积种植、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与毛发提取器械改进研发。植发手术经验丰富，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["210141000002268", "201814110142603198303123712"],
    position: "执业医师",
  },
  {
    en: "LUO",
    path: "Transplanter",
    type: "3",
    title: "罗继森",
    numberIndex: "40",
    text: [
      "毕业于华中科技大学",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与毛发提取器械改进研发",
      "倡导大面积种植精细化操作",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "毕业于华中科技大学，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与毛发提取器械改进研发，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210420600001401", "201942110372929199106093013"],
    position: "执业医师",
  },
  {
    numberIndex: "41",
    en: "LUO",
    path: "Transplanter",
    type: "3",
    title: "罗道龙",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与新式毛囊提取设备的研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与新式毛囊提取设备的研发。植发手术经验丰富，多次受邀参加国内毛发移植技术交流论坛，擅长大面积植发、艺术种植和不剃发植发，倡导将植发技术与艺术美学结合实施。",
    number: ["110440112001071", "200942110429005198208302054"],
    position: "执业医师",
  },
  {
    numberIndex: "42",
    en: "WANG",
    path: "Transplanter",
    type: "3",
    title: "王建辉",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "碧莲盛植发养护体系制定者之一",
      "参与新式毛囊提取设备的研发",
      "参与不剃发植发技术的研发",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，碧莲盛植发养护体系制定者之一，参与新式毛囊提取设备的研发，参与不剃发植发技术的研发。从事毛发移植工作多年，积累了丰富的医学理论基础和临床实践经验，倡导将植发技术与艺术美学结合实施，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["210340200000457", "20193411036232919930801573X"],
    position: "执业医师",
  },
  {
    numberIndex: "43",
    en: "JIANG",
    path: "Transplanter",
    type: "3",
    title: "姜印",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与碧莲盛头发康养体系的建设",
      "参与精密即插即种植发技术的升级研发",
      "倡导大面积种植精细化操作",
      "擅长多维加密、大面积种植及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与碧莲盛头发康养体系的建设，参与精密即插即种植发技术的升级研发。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，倡导大面积种植精细化操作，倡导将植发技术与艺术美学结合实施，擅长多维加密、大面积种植及不剃发植发。",
    number: ["110370212001034", "201412110130421198603145719"],
    position: "执业医师",
  },
  {
    numberIndex: "44",
    title: "熊超",
    en: "XIONG",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的临床数据搜集",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、不剃发植发及大面积种植",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，碧莲盛骨干植发医生，参与不剃发植发技术的临床数据搜集，多次受邀参加国内毛发移植技术交流论坛，擅长艺术种植、不剃发植发及大面积种植。",
    number: ["110370102002320", "202042110421127198808250856"],
    position: "执业医师",
  },
  {
    numberIndex: "45",
    en: "LI",
    path: "Transplanter",
    type: "3",
    title: "李志杰",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "倡导大面积种植精细化操作",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，倡导大面积种植精细化操作，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210130000003409", "201813110130481198309103792"],
    position: "执业医师",
  },
  {
    title: "蒋海龙",
    numberIndex: "46",
    en: "JIANG",
    path: "Transplanter",
    type: "3",
    text: [
      "植发从业8年以上",
      "碧莲盛植发技术研究中心核心成员",
      "参与毛发提取器械改进研发",
      "倡导大面积种植精细化操作",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "蒋海龙医生是碧莲盛植发研发中心核心成员，参与毛发提取器械改进研发。植发手术经验丰富，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210530103000530", "202053110620121199103066015"],
    position: "执业医师",
  },
  {
    numberIndex: "47",
    title: "徐乐",
    en: "XU",
    path: "Transplanter",
    type: "3",
    text: [
      "郑州大学外科学医学硕士",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与碧莲盛毛发康养体系的建设",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "郑州大学外科学医学硕士，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与碧莲盛毛发康养体系的建设，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发，倡导将植发技术与艺术美学结合实施。",
    number: ["110410100016877", "201841110420683199101291250"],
    position: "执业医师",
  },
  {
    numberIndex: "48",
    title: "宋世伟",
    en: "SONG",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与碧莲盛毛发康养体系的建设",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与碧莲盛毛发康养体系的建设，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发，倡导将植发技术与艺术美学结合实施。",
    number: ["110530000011623", "201953110530324199505201556"],
    position: "执业医师",
  },
  {
    numberIndex: "49",
    en: "FU",
    path: "Transplanter",
    type: "3",
    title: "付海隆",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与新式毛囊提取设备的研发",
      "参与不剃发植发技术的器械改进",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与新式毛囊提取设备的研发，参与不剃发植发技术的器械改进。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210360100000429", "201736110360124199110306318"],
    position: "执业医师",
  },
  {
    numberIndex: "50",
    en: "XU",
    path: "Transplanter",
    type: "3",
    title: "徐雪松",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "碧莲盛植发养护体系制定者之一",
      "参与不剃发植发技术的器械改进",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，碧莲盛植发养护体系制定者之一，参与不剃发植发技术的器械改进。多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["110211121000385", "20142111021110219880113053X"],
    position: "执业医师",
  },
  {
    numberIndex: "51",
    title: "王亚昆",
    en: "WANG",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "碧莲盛植养固体系制定者之一",
      "参与毛发提取器械改进研发",
      "多名网络红人的植发方案设计者和操作医师",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，碧莲盛植养固体系制定者之一，参与毛发提取器械改进研发。植发手术经验丰富，多名网络红人的植发方案设计者和操作医师，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210411681000162", "201941110412702199112033131"],
    position: "执业医师",
  },
  {
    numberIndex: "52",
    en: "ZHOU",
    path: "Transplanter",
    type: "3",
    title: "周健",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与加密即插即种植发技术的升级研发",
      "倡导大面积种植精细化操作",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与加密即插即种植发技术的升级研发。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，倡导大面积种植精细化操作，倡导将植发技术与艺术美学结合实施，擅长艺术种植、多维加密及不剃发植发。",
    number: ["110310118000831", "201032110320981198203206219"],
    position: "执业医师",
  },
  {
    numberIndex: "53",
    en: "YU",
    path: "Transplanter",
    type: "3",
    title: "喻霞",
    show: false,
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的器械升级研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的器械升级研发，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["210430111000739", "202044110430923199306121425"],
    position: "执业医师",
  },
  {
    numberIndex: "54",
    en: "WANG",
    path: "Transplanter",
    type: "3",
    title: "王春梅",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与不剃发植发技术的器械升级研发",
      "多次受邀参加参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械升级研发，多次受邀参加参加国内毛发移植技术交流论坛。擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密和不剃发植发。",
    number: ["110420222100945", "201952110370785198701239623"],
    position: "执业医师",
  },
  {
    numberIndex: "55",
    title: "褚广良",
    en: "CHU",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与加密即插即种植发技术的升级研发",
      "倡导大面积种植精细化操作",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与加密即插即种植发技术的升级研发。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，倡导大面积种植精细化操作，倡导将植发技术与艺术美学结合实施，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210370105000395", "202037110371328199304200713"],
    position: "执业医师",
  },
  {
    numberIndex: "56",
    en: "CHENG",
    path: "Transplanter",
    type: "3",
    title: "成钢",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与精密即插即种植发技术的升级研发",
      "倡导大面积种植精细化操作",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与精密即插即种植发技术的升级研发。从事脱发治疗工作多年，积累了丰富的医学理论基础和临床实践经验，倡导大面积种植精细化操作，倡导将植发技术与艺术美学结合实施，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210510106000519", "202051110511124198104201012"],
    position: "执业医师",
  },
  {
    numberIndex: "57",
    en: "WANG",
    path: "Transplanter",
    type: "3",
    title: "王锦",
    text: [
      "原包头医学院附属第二医院医生",
      "碧莲盛植发技术研究中心核心成员",
      "参与新式毛囊提取设备的研发",
      "倡导大面积种植精细化操作",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "原包头医学院附属第二医院医生，碧莲盛植发技术研究中心核心成员，参与新式毛囊提取设备的研发，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发，深受发友信赖。",
    number: ["110110105013830", "201915110152624198907196019"],
    position: "执业医师",
  },
  {
    numberIndex: "58",
    en: "NA",
    path: "Transplanter",
    type: "3",
    title: "马发林",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与不剃发植发技术的器械升级研发",
      "多次受邀参加参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械升级研发，多次受邀参加参加国内毛发移植技术交流论坛。擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密和不剃发植发。",
    number: ["210420881001197", "202042110420881199311012932"],
    position: "执业医师",
  },
  {
    title: "邱红杰",
    numberIndex: "59",
    en: "QIU",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "倡导大面积种植精细化操作",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，拥有近十年外科临床经验，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["210130502000622", "201813110130535198609243110"],
    position: "执业医师",
  },
  {
    title: "赵生智",
    numberIndex: "60",
    en: "ZHAO",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的升级研发",
      "参与加密即插即种植发技术的升级研发",
      "倡导植养固体系与植发手术紧密结合",
      "擅长大面积种植、艺术种植及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的升级研发，参与加密即插即种植发技术的升级研发。多年毛发移植临床经验，倡导植养固体系与植发手术紧密结合，擅长大面积种植、艺术种植及不剃发植发，在治脱方案选择及术后指导方面有独到心得。",
    number: ["210620602000212", "201662210622301198208062654"],
    position: "执业医师",
  },
  {
    title: "马啸天",
    numberIndex: "61",
    en: "MA",
    path: "Transplanter",
    type: "3",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与毛发提取器械改进研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与毛发提取器械改进研发，多次受邀参加国内毛发移植技术交流论坛。拥有多年毛发移植临床经验，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210610124000428", "201661110610202198912162816"],
    position: "执业医师",
  },
  {
    title: "李晓龙",
    en: "LI",
    path: "Transplanter",
    type: "3",
    numberIndex: "62",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与毛发提取器械改进研发",
      "参与碧莲盛毛发康养体系的建设",
      "倡导植养固体系与植发手术紧密结合",
      "擅长大面积种植、艺术种植及不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与毛发提取器械改进研发，参与碧莲盛毛发康养体系的建设。多年毛发移植临床经验，倡导植养固体系与植发手术紧密结合，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积种植、艺术种植及不剃发植发。",
    number: ["110150102001222", "201515110152827198704033316"],
    position: "执业医师",
  },
  {
    en: "WANG",
    path: "Transplanter",
    type: "3",
    title: "王旭东",
    numberIndex: "63",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与制定植养固体系",
      "参与新式毛囊提取设备的研发",
      "参与碧莲盛毛发康养体系的建设",
      "擅长个性化定制方案和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与制定植养固体系，参与新式毛囊提取设备的研发，参与碧莲盛毛发康养体系的建设，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。",
    number: ["210610100002157", "201961110411282198807187013"],
    position: "执业医师",
  },
  {
    numberIndex: "64",
    title: "艾俊波",
    en: "AI",
    path: "Transplanter",
    type: "3",
    show: false,
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与毛囊提取器械改进研发",
      "参与不剃发植发技术的研发",
      "倡导大面积种植精细化操作",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与毛囊提取器械改进研发，参与不剃发植发技术的研发。多年外科临床经验，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长大面积植发、艺术种植和不剃发植发。",
    number: ["210410990000080", "20174111041090119880215115X"],
    position: "执业医师",
  },
  {
    numberIndex: "65",
    en: "PAN",
    path: "Transplanter",
    type: "3",
    title: "潘愿",
    text: [
      "原海南省第五人民医院医生",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的核心研发",
      "参与精密即插即种技术的升级研发",
      "倡导大面积种植精细化操作",
      "擅长大面积种植、艺术种植及不剃发植发",
    ],
    detail:
      "原海南省第五人民医院医生，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的核心研发，参与精密即插即种技术的升级研发。多年毛发移植临床经验，倡导大面积种植精细化操作，擅长大面积种植、艺术种植及不剃发植发，在治脱方案选择及术后指导方面有独到心得。",
    number: ["110460100008230", "201846110460033199010195096"],
    position: "执业医师",
  },
  {
    numberIndex: "66",
    en: "NV",
    path: "Transplanter",
    type: "3",
    title: "牛刚",
    text: [
      "植发从业6年以上",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与不剃发植发技术的器械升级研发",
      "多次受邀参加参加国内毛发移植技术交流论坛",
      "擅长艺术种植、多维加密和不剃发植发",
    ],
    healthImg: "/team/Famous/certificate/%E7%89%9B%E5%88%9A/3.png",
    healthNum: "610623199009090172",
    detail:
      "牛刚主治医师是碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发。参与不剃发植发技术的器械升级研发，多次受邀参加国内毛发移植技术交流论坛。曾任职于三甲医院兰州军区总院 擅长根据脱发者具体情况量身打造植发方案，擅长艺术种植、多维加密和不刻发植发",
    number: ["110620102000105", "201562110610623199009090172"],
    position: "主治医师",
  },
  {
    numberIndex: "67",
    en: "ZHENG",
    path: "Transplanter",
    type: "3",
    title: "郑利勤",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的核心研发",
      "参与毛发提取器械改进研发",
      "多次受邀参加国内毛发移植技术交流论坛",
      "擅长艺术种植、大面积种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的核心研发，参与毛发提取器械改进研发。植发手术经验丰富，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、大面积种植和不剃发植发，在治脱方案选择及术后指导方面有独到心得。",
    number: ["210430500001485", "202043110431128199112285957"],
    position: "执业医师",
  },
  {
    numberIndex: "68",
    en: "BAI",
    path: "Transplanter",
    type: "3",
    title: "白腾飞",
    text: [
      "美容外科主诊医师",
      "碧莲盛昆明院部技术院长",
      "碧莲盛植发技术研究中心核心成员",
      "参与不剃发植发技术的研发",
      "参与不剃发植发技术的器械升级研发",
      "多次受邀参加参加国内毛发移植技术交流论",
      "擅长艺术种植、多维加密和不剃发植发",
    ],
    detail:
      "主治医师，美容外科主诊医师，碧莲盛昆明院部技术院长，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的研发，参与不剃发植发技术的器械升级研发。多年外科临床经验，多次受邀参加国内毛发移植技术交流论坛，擅长艺术种植、多维加密和不剃发植发，倡导将植发技术与艺术美学结合实施。",
    number: ["110530426000116", "200850110513523197809163397"],
    position: "主治医师",
    locality: true,
  },
  {
    numberIndex: "69",
    en: "JIANG",
    path: "Transplanter",
    type: "3",
    title: "江龙",
    text: [
      "碧莲盛植发技术研究中心核心成员",
      "多次受邀参加国内毛发移植技术交流论坛",
      "参与不剃发植发技术的研发",
      "参与精密即插即种植发技术研发",
      "擅长大面积植发、艺术种植和不剃发植发",
    ],
    detail:
      "碧莲盛植发技术研究中心核心成员，多次受邀参加国内毛发移植技术交流论坛，参与不剃发植发技术的研发，参与精密即插即种植发技术研发，擅长大面积植发、艺术种植和不剃发植发。拥有多年毛发移植从业经验。他毛囊提取精准，后枕均匀美观，责任心强，把医疗质量放第一位，关心每一位发友的感受，带领团队用精湛的技术，优质服务对待每一位患者。",
    number: ["210530100000188", "202053110430068119880306712"],
    position: "主治医师",
    locality: true,
  },
];
