<template>
  <div class="project">
    <div class="banner">
      <img
        :src="`${$img}/home/Banner4.jpg`"
        style="vertical-align: middle; width: 100vw"
        alt=""
      />
      <div class="banner-fixed">
        <div class="allTitle" style="color: #ffffff">种植项目</div>
        <div class="allTitle-En" style="color: #ffffff; opacity: 0.6">
          SIMSON PROJECT
        </div>
      </div>

      <div class="banner-bottom">
        <div
          class="banner-item"
          :class="{ 'select-banner': selectBanner == 1 }"
          @mouseenter="selectBanner = 1"
        >
          <img :src="`${$img}/home/white/1.png`" alt="" />
          <div>
            <div>顶部</div>
            <div>加密</div>
          </div>
        </div>
        <div
          class="banner-item"
          :class="{ 'select-banner': selectBanner == 2 }"
          @mouseenter="selectBanner = 2"
        >
          <img :src="`${$img}/home/white/2.png`" alt="" />
          <div>
            <div>眉毛</div>
            <div>种植</div>
          </div>
        </div>
        <div
          class="banner-item"
          :class="{ 'select-banner': selectBanner == 3 }"
          @mouseenter="selectBanner = 3"
        >
          <img :src="`${$img}/home/white/3.png`" alt="" />
          <div>
            <div>发际线</div>
            <div>种植</div>
          </div>
        </div>
        <div
          class="banner-item"
          :class="{ 'select-banner': selectBanner == 4 }"
          @mouseenter="selectBanner = 4"
        >
          <img :src="`${$img}/home/white/4.png`" alt="" />
          <div>
            <div>胡须</div>
            <div>种植</div>
          </div>
        </div>
        <div
          class="banner-item"
          :class="{ 'select-banner': selectBanner == 5 }"
          @mouseenter="selectBanner = 5"
        >
          <img :src="`${$img}/home/white/5.png`" alt="" />
          <div>
            <div>鬓角</div>
            <div>种植</div>
          </div>
        </div>
        <div
          class="banner-item"
          :class="{ 'select-banner': selectBanner == 6 }"
          @mouseenter="selectBanner = 6"
        >
          <img :src="`${$img}/home/white/6.png`" alt="" />
          <div>
            <div>疤痕</div>
            <div>种植</div>
          </div>
        </div>
        <div
          class="banner-item"
          :class="{ 'select-banner': selectBanner == 7 }"
          @mouseenter="selectBanner = 7"
        >
          <img :src="`${$img}/home/white/7.png`" alt="" />
          <div>
            <div>体毛</div>
            <div>种植</div>
          </div>
        </div>
        <div
          class="banner-item"
          :class="{ 'select-banner': selectBanner == 8 }"
          @mouseenter="selectBanner = 8"
        >
          <img :src="`${$img}/home/white/8.png`" alt="" />
          <div>
            <div>美人尖</div>
            <div>种植</div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in list" :key="item.title">
      <div class="project-item box" v-show="index == selectBanner - 1">
        <img
          :src="`${$img}/home/Cartoon/${index + 1}.png`"
          alt=""
          class="InLeft"
        />
        <div class="InRight">
          <div class="project-title">{{ list[selectBanner - 1].title }}</div>
          <div class="project-text">
            {{ list[selectBanner - 1].text }}
          </div>
          <div class="project-line"></div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="allTitle">适用人群</div>
      <div class="allTitle-En">APPLICABLE PEOPLE</div>
      <div class="intended">
        <div
          class="intended-item"
          :class="{ 'select-intended': list[selectBanner - 1].index == ind }"
          v-for="(i, ind) in list[selectBanner - 1].project"
          :key="ind"
          @mouseenter="list[selectBanner - 1].index = ind"
        >
          0{{ ind + 1 }}
          <div class="intended-bg">
            <div>{{ i.projectTitle }}</div>
            <div>{{ i.projectSubTitle }}</div>
          </div>
        </div>
      </div>
      <div class="intended-line box"></div>
      <div class="intended-text">
        {{
          list[selectBanner - 1].project[list[selectBanner - 1].index]
            .projectText
        }}
      </div>
      <div class="intended-text">
        {{
          list[selectBanner - 1].project[list[selectBanner - 1].index]
            .projectTextT
        }}
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      selectBanner: 1,
      wow: null,
      list: [
        {
          title: "精准加密 多快好省",
          text: "顶部加密是对头顶稀疏区的加密种植，从后枕部位提取健康的毛囊，经分离培养后，分散式、多点位、种植到头顶头发稀疏的部位，相较于光裸头皮种植，需更全面考虑头皮环境，对毛囊密度的把控要求更高。",
          index: 0,
          project: [
            {
              projectTitle: "先天性",
              projectSubTitle: "头发稀疏",
              projectText:
                "在遗传性脱发当中，并不是所有人都是秃顶，也有一部分人脱发成稀疏状态，具体的表现是头发细软、密度小，需要通过种植进行加密",
            },
            {
              projectTitle: "弥散性",
              projectSubTitle: "脱发",
              projectText:
                "大多数的女性发友脱发都呈现弥散性，整体部位稀疏，需要通过加密手术在头顶进行加密",
            },
            {
              projectTitle: "二次种植",
              projectSubTitle: "的完善",
              projectText:
                "一些面积比较大的脱发，或者受其他因素影响的不能一次性种植成功，大多数人会选择加密种植，进一步完善自己的头发",
            },
          ],
        },
        {
          title: "修眉重塑 气质大不同",
          text: "眉毛种植是根据客户需求和美学原理重塑眉型的一种变美方式，一般是提取后枕部靠近耳侧的单根毛囊，经分离、培养后，种植于眉毛区域，解决患者无眉、少眉、修眉的问题。这种种植方式可以保证新移植的毛发与原眉毛保持一致性状，生长方向契合，效果自然。",
          index: 0,
          project: [
            {
              projectTitle: "眉毛",
              projectSubTitle: "稀少",
              projectText:
                "眉毛过稀过淡，显得缺少个性，可以通过眉毛种植，改变眉毛原生状态，改善五官协调性，重塑颜值新高度",
            },
            {
              projectTitle: "眉毛",
              projectSubTitle: "散乱",
              projectText:
                "眉毛生长比较散乱，没有比较鲜明的眉型，可以通过眉毛种植，勾画出符合个体特征的眉型轮廓",
            },
            {
              projectTitle: "眉形",
              projectSubTitle: "不全",
              projectText:
                "半截眉或空缺眉形，可以通过眉毛种植，以天然眉形为底子，设计出一条完整的眉形，对半截或空缺的地方进行增补",
            },
            {
              projectTitle: "眉毛",
              projectSubTitle: "疤痕",
              projectText:
                "如果眉毛有疤痕，毛发一直都没有长出来，这就意味着这部分的毛囊已经受到损伤，完全闭合了，需要进行眉毛种植",
            },
            {
              projectTitle: "个性眉形",
              projectSubTitle: "调整",
              projectText:
                "私人订制个性化方案，利用眉毛自然的弧度与线条，可以让脸型变得更加立体，赋予眉毛新状态，彰显个人新气质",
            },
          ],
        },
        {
          title: "细微改变 精致脸庞",
          text: "发际线种植是时下最受欢迎的一种种植类别之一，应用人脸“三庭五眼”美学概念，根据男女不同的脸型，结合发友的变美需求，设计出定制化个人发际线种植方案，轻松解决发际线后移、大额头等问题。",
          index: 0,
          project: [
            {
              projectTitle: "M形",
              projectSubTitle: "额头",
              projectText:
                "M型发际线让女性脸型略显男性化,看上去比实际年龄大,影响面部的美观度。通过植发手段来调整发际线,可以提升面部的柔美度",
            },
            {
              projectTitle: "高",
              projectSubTitle: "额头",
              projectText:
                "高额头也就是发际线后移,眉宇至正中发际线起始点本身距离比较长的额型,这种情况让人感觉比较“显老”",
              projectTextT:
                "通过扩大发际线边缘绒毛可以很好的修饰脸型，使人更“显小”",
            },
            {
              projectTitle: "方形",
              projectSubTitle: "额头",
              projectText:
                "方形额头属于M型额+高额型,在做女性发际线形态矫正美学设计时,需考虑脸部的整体轮廓",
              projectTextT:
                "发际线种植后不但对发际线部位起到修饰作用,也能提升面部整体的流畅度",
            },
            {
              projectTitle: "个性",
              projectSubTitle: "发际线调整",
              projectText:
                "一直以来，“三庭五眼”都是备受推崇的审美标准，如果比较在意个人形象，可以通过发际线种植来实现自己三线之间等距",
            },
          ],
        },
        {
          title: "经典侧颜 魅力首选",
          text: "胡须种植专属于男性，而胡须的形状与文化有着紧密关联，不同的胡须表达着不同的气质和魅力，胡须种植同样选取后枕部毛囊，在种植时尤其要注意种植须和自然须的生长方向保持一致，这样的种植效果才显得浑然天成，气质MAX。",
          index: 0,
          project: [
            {
              projectTitle: "先天性",
              projectSubTitle: "胡须稀少",
              projectText:
                "通过胡须种植，改善先天不足，释放男性独特魅力，与原生胡须种植衔接自然，宛如天生",
            },
            {
              projectTitle: "不满意",
              projectSubTitle: "胡须形状",
              projectText:
                "按照三庭五眼美学比例及角度设计适合的专属胡须，自然和谐，满足男性个性需求",
            },
          ],
        },
        {
          title: "有鬓有型 阳刚散发",
          text: "鬓角种植一般受男性欢迎，在塑造脸型，打造气质方面效果显著，鬓角的种植同样是采用后枕部长寿毛囊移种，同时也要延续鬓角处原生发的生长方向，保证术后效果自然。",
          index: 0,
          project: [
            {
              projectTitle: "鬓角",
              projectSubTitle: "先天性缺失",
              projectText:
                "鬓角种植可以通过头发种植将头部其他部位的头发种植到鬓角，使鬓角重新长出来，长出来的鬓角效果非常自然",
            },
            {
              projectTitle: "鬓角",
              projectSubTitle: "形状不佳",
              projectText:
                "私人订制个性化方案，根据脸型、气质，结合现代美学标准设计最适合的鬓角造型，达到弥补和美化鬓角的效果",
            },
            {
              projectTitle: "个性化",
              projectSubTitle: "鬓角调整",
              projectText:
                "根据脸型设计鬓角形状，掩盖脸型不足，改善面部轮廓，彰显男性阳刚个性",
            },
          ],
        },
        {
          title: "巧妙隐藏",
          text: "疤痕种植是一种特别有效且和缓的消除疤痕影响的方式，在面对烫伤、烧伤，外力或化学灼伤后形成的疤痕时，通过移植毛囊到已损伤区域，可以起到很好的掩饰作用，对于有意外疤痕的发友非常有价值。",
          index: 0,
          project: [
            {
              projectTitle: "重度",
              projectSubTitle: "疤痕",
              projectText:
                "因撕脱伤、电击伤或火深层烧伤而形成，血运较差，在做疤痕种植时相对较深，达到骨膜浅层，因其生长所需营养来源于骨膜浅层的血供",
              projectTextT:
                "疤痕植发后头发长出时间相对较长，起初发丝较细，成卷曲状，且移植时密度相对要稀，以免血供不足，影响成活",
            },
            {
              projectTitle: "轻度",
              projectSubTitle: "疤痕",
              projectText:
                "因烫伤或火浅层烧伤而形成，血运比较好，疤痕种植的深度较正常",
              projectTextT:
                "相比正常植发的深度略深即可，头发生长情况与正常头皮上移植的头发生长基本无区别",
            },
            {
              projectTitle: "感染性",
              projectSubTitle: "疤痕",
              projectText:
                "一般较浅，血运较好，疤痕种植的深度达到疤痕的深层即可，因其生长所需营养来源于疤痕深层的血供",
              projectTextT:
                "疤痕种植后头发生长情况与正常头皮上植发的生长基本无区别",
            },
          ],
        },
        {
          title: "还原度高 宛如天生",
          text: "体毛种植同样也是把后枕部毛囊移植到特定区域的一种种植方式，对于发友的一些私人化定制，可以很好地满足需求。",
          index: 0,
          project: [
            {
              projectTitle: "先天性",
              projectSubTitle: "无体毛",
              projectText:
                "后天弥补，焕颜新生，精心种植后的再生毛发持有原毛发所有特点，种植效果生动、逼真、有形",
            },
            {
              projectTitle: "个人形象",
              projectSubTitle: "需要",
              projectText:
                "根据不同发友需求设计专属种植方案，与原生无差别，毛发密度高，精致有型，为发友量身打造理想形象",
            },
          ],
        },
        {
          title: "一点改变 魅力无限",
          text: "美人尖种植是特别受欢迎的一种调整发际线的方式，通过在发际线正中间延伸出一个发尖，能够让脸型显得更为精致，发际线更为美观。",
          index: 0,
          project: [
            {
              projectTitle: "美人尖",
              projectSubTitle: "稀疏",
              projectText:
                "美人尖过稀过淡，显得缺少个性，美人尖种植可以设计美人尖形状，解决发际线高的问题，整个面部看起来更加的精神美观",
            },
            {
              projectTitle: "先天无",
              projectSubTitle: "美人尖",
              projectText:
                "通过美人尖种植，改善先天不足，延长发际线长度，增加发顶的高度，使脸型稍稍拉长，给人以协调、自然的美感",
            },
            {
              projectTitle: "美人尖",
              projectSubTitle: "形态不佳",
              projectText:
                "根据脸型设计出更加美观的形状，改善脸型线条流畅度，加上美人尖的修饰更加彰显精致感",
            },
          ],
        },
      ],
    };
  },
  beforeDestroy() {
    this.wow.stop();
  },
  created() {
    if (this.$route.params.id) {
      this.selectBanner = this.$route.params.id;
    }
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
  },
};
</script>
  
<style lang="scss">
.InLeft {
  animation: bounceInLeft 0.8s linear both;
  transform: bounceInLeft 0.8s;
}
.InRight {
  animation: bounceInRight 0.8s linear both;
  transform: bounceInLeft 0.8s;
}
</style>