<template>
    <div class="aboutUs">
        <img :src="`${$img}/aboutUs/Banner.jpg`" class="banner" alt="" />
        <div class="item box">
            <div class="allTitle wow fadeInUp">品牌介绍</div>
            <div class="allTitle-En wow fadeInUp" data-wow-delay="0.1s">
                BRAND INTRODUCTION
            </div>
            <div class="Brand">
                <img src="https://static.drlianzhuren.com/brandWeb/banner/%E5%93%81%E7%89%8C%E4%BB%8B%E7%BB%8D%E5%9B%BE.jpg"
                    alt="" class="Brand-left wow fadeInUp" data-wow-delay="0.3s" />
                <div class="Brand-right wow fadeInUp" data-wow-delay="0.3s">
                    <p>
                        碧莲盛成立于2005年，是一家以植发技术的自主研发和临床应用为核心，致力于毛发健康产业发展的全国直营连锁机构。18年来精钻植发技术，连续13年参加世界植发大会，向世界展示中国植发技术。
                    </p>
                    <p>
                        在华盖资本5亿融资加持下，碧莲盛全力推动品牌战略升级，通过成立技术研发中心、重塑服务体系、扩展旗舰医院、研发自有品牌护发产品等，不断深化行业布局，加强上下游资源整合，线上线下链路打通，构建一个集养发、护发、健发、植发于一体的毛发健康产业生态闭环。
                    </p>
                    <p>
                        目前，碧莲盛在全国开设了40余家直营机构，拥有100余位执业医生，700余位执业护士，其中，主任/副主任医师10余位，为35万+发友解决了脱发困扰。
                    </p>
                    <div class="Brand-ellipsis">...</div>
                </div>
            </div>
        </div>
        <div class="item" style="background: #f7f8fc">
            <div class="box">
                <div class="layout">
                    <div class="layout-left">
                        <div class="allTitle wow fadeInUp" style="text-align: left">
                            战略布局
                        </div>
                        <div class="allTitle-En wow fadeInUp" style="text-align: left" data-wow-delay="0.1s">
                            BRAND LAYOUT
                        </div>
                        <div class="layout-text wow fadeInUp" data-wow-delay="0.3s">
                            碧莲盛是一家辐射全国的大型连锁植发机构，自成立以来，致力于为全国发友带来无差异的、高标准的植发服务。凭借创新的植发技术、贴心的医疗服务、深厚的用户口碑，碧莲盛先后在全国建立了40余家直营连锁医院，建立了以省会和重点城市辐射周边的医疗体系，不断推进实体旗舰医院的战略布局。碧莲盛对所有院部实施统一的管理制度，确保所有城市的消费者都能享受到品质如一的服务。
                        </div>
                        <div class="Brand-ellipsis wow fadeInUp" data-wow-delay="0.4s">
                            ...
                        </div>
                    </div>
                    <div class="layout-right wow fadeInUp" data-wow-delay="0.5s">
                        <div class="laboratory" :style="{
                            'background-image': `url(${$img}/home/strategicLayout.jpg?v=2) `,
                        }"></div>
                    </div>
                    <!-- <div class="layout-right wow fadeInUp" data-wow-delay="0.5s">
            <div class="map" :style="{
                'background-image': `url(${$img}home/map.png) `,
              }">
              <div class="mapBox"></div>
              <div class="mapBox" style="top: 358px; left: 315px"></div>
              <div class="mapBox" style="top: 57px; left: 525px"></div>
              <div class="mapBox" style="top: 86.4px; left: 530px"></div>
              <div class="mapBox" style="top: 86.4px; left: 484px"></div>
              <div class="mapBox" style="top: 80px; left: 419px"></div>
              <div class="mapBox" style="top: 119px; left: 382px"></div>
              <div class="mapBox" style="top: 96px; left: 422px"></div>
              <div class="mapBox" style="top: 61px; left: 460px"></div>
              <div class="mapBox" style="top: 76px; left: 345px"></div>
              <div class="mapBox" style="top: 76px; left: 384px"></div>
              <div class="mapBox" style="top: 82px; left: 403px"></div>
              <div class="mapBox" style="top: 49px; left: 432px"></div>
              <div class="mapBox" style="top: -19px; left: 320px"></div>
              <div class="mapBox" style="top: -28px; left: 295px"></div>
              <div class="mapBox" style="top: 48px; left: 326px"></div>
              <div class="mapBox" style="top: 67px; left: 393px"></div>
              <div class="mapBox" style="top: 26px; left: 432px"></div>
              <div class="mapBox" style="top: -9.6px; left: 451px"></div>
              <div class="mapBox" style="top: -220px; left: 341px"></div>
              <div class="mapBox" style="top: -105px; left: 301px"></div>
              <div class="mapBox" style="top: -38px; left: 263px"></div>
              <div class="mapBox" style="top: -105px; left: 364px"></div>
              <div class="mapBox" style="top: -163px; left: 470px"></div>
            </div>
          </div> -->
                </div>
                <div class="list wow fadeInUp" id="StrategicLayoutAdd">
                    <div style="font-weight: 500">
                        <countTo ref="countTo1" :startVal="0" :endVal="18" :duration="1000" :autoplay="false" :decimals="0"
                            :useEasing="true"></countTo>
                        <span style="font-size: 15px; margin-left: -10px; display: inline-block">年</span>
                        <div style="font-size: 15px; margin-top: 18px" class="list-top">
                            专研植发
                        </div>
                    </div>
                    <div style="font-weight: 500">
                        <div style="display: flex; justify-content: center">
                            <countTo ref="countTo2" :startVal="0" :endVal="40" :duration="1000" :autoplay="false"
                                :decimals="0" :useEasing="true"></countTo><span style="font-size: 15px">
                                <div>
                                    <div style="font-size: 31px; margin-bottom: 2px">+</div>
                                    家
                                </div>
                            </span>
                        </div>

                        <div style="font-size: 15px; margin-top: 18px" class="list-top">
                            直营机构
                        </div>
                    </div>
                    <div style="font-weight: 500">
                        <div style="display: flex; justify-content: center">
                            <countTo ref="countTo3" :startVal="0" :endVal="100" :duration="1000" :autoplay="false"
                                :decimals="0" :useEasing="true"></countTo>
                            <span style="font-size: 15px">
                                <div>
                                    <div style="font-size: 31px; margin-bottom: 2px">+</div>
                                    位
                                </div>
                            </span>
                        </div>
                        <div style="font-size: 15px; margin-top: 18px" class="list-top">
                            执业医师
                        </div>
                    </div>
                    <div style="font-weight: 500">
                        <div style="display: flex; justify-content: center">
                            <countTo ref="countTo4" :startVal="0" :endVal="700" :duration="1000" :autoplay="false"
                                :decimals="0" :useEasing="true"></countTo>
                            <span style="font-size: 15px">
                                <div>
                                    <div style="font-size: 31px; margin-bottom: 2px">+</div>
                                    位
                                </div>
                            </span>
                        </div>
                        <div style="font-size: 15px; margin-top: 18px" class="list-top">
                            执业护士
                        </div>
                    </div>
                    <div style="font-weight: 500">
                        <div style="display: flex; justify-content: center">
                            <countTo ref="countTo5" :startVal="0" :endVal="35" :duration="1000" :autoplay="false"
                                :decimals="0" :useEasing="true"></countTo><span
                                style="font-size: 42px; line-height: 46px">万</span>
                            <span style="font-size: 15px">
                                <div>
                                    <div style="font-size: 31px; margin-bottom: 2px">+</div>
                                    台
                                </div>
                            </span>
                        </div>
                        <div style="font-size: 15px; margin-top: 18px" class="list-top">
                            植发手术
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item box">
            <div class="allTitle wow fadeInUp">发展历程</div>
            <div class="allTitle-En wow fadeInUp" data-wow-delay="0.1s">
                DEVELOPMENT HISTORY
            </div>
            <div class="development wow fadeInUp" data-wow-delay="0.3s">
                <div :class="{ 'select-development': isMilepost == 0 }" @click="editisMilepost(0)">
                    2022
                </div>
                <div :class="{ 'select-development': isMilepost == 1 }" @click="editisMilepost(1)">
                    2021
                </div>
                <div :class="{ 'select-development': isMilepost == 2 }" @click="editisMilepost(2)">
                    2020
                </div>
                <div :class="{ 'select-development': isMilepost == 3 }" @click="editisMilepost(3)">
                    2019
                </div>
                <div :class="{ 'select-development': isMilepost == 4 }" @click="editisMilepost(4)">
                    2018
                </div>
                <div :class="{ 'select-development': isMilepost == 5 }" @click="editisMilepost(5)">
                    2017
                </div>
                <div :class="{ 'select-development': isMilepost == 6 }" @click="editisMilepost(6)">
                    2015
                </div>
                <div :class="{ 'select-development': isMilepost == 7 }" @click="editisMilepost(7)">
                    2013
                </div>
                <div :class="{ 'select-development': isMilepost == 8 }" @click="editisMilepost(8)">
                    2012
                </div>
                <div :class="{ 'select-development': isMilepost == 9 }" @click="editisMilepost(9)">
                    2010
                </div>
                <div :class="{ 'select-development': isMilepost == 10 }" @click="editisMilepost(10)">
                    2008
                </div>
                <div :class="{ 'select-development': isMilepost == 11 }" @click="editisMilepost(11)">
                    2005
                </div>
            </div>
            <div class="aboutUs-swiper wow fadeInUp" data-wow-delay="0.4s">
                <div class="swiper-left" @click="delisMilepost">
                    <div class="swipeLeft-after" :style="{
                        borderColor: isMilepost == 0 ? '#cfd2d3' : '#363636',
                    }"></div>
                </div>
                <div class="swiper-box">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2022.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>
                                        发起了六一爱心助学公益行活动，为山西河津市上市小学捐赠爱心助学物质。
                                    </div>
                                    <div>
                                        荣登第五届未来医疗100强大会中国创新医疗服务榜100强，企业估值45亿。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2021.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>
                                        参加第五届中国毛发移植大会，共同起草《毛发移植规范》团体标准。
                                    </div>
                                    <div>
                                        成立碧莲盛名医堂，20位在碧莲盛执业7年以上、主治/主任资质的医生入围首批名单。
                                    </div>
                                    <div>
                                        携手鸿基金启动“爱的翅膀·助力成才计划”，为山西河津市上市小学捐赠“爱的图书馆”。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2020.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>
                                        武汉新冠疫情爆发后，成立100万专项基金，采购医疗专用防疫物质，并运往疫情前线。
                                    </div>
                                    <div>
                                        与中国平安保险合作，为发友的植发安全与效果提供有力保障，让发友“放心植发，安全变美”。
                                    </div>
                                    <div>
                                        在深圳举办发布会，正式推出不剃发植发技术，将世界植发技术标准推向一个全新的高度。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2019.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>与中国人保（PICC）达成战略合作，保障发友合法权益。</div>
                                    <div>
                                        与中国平安保险合作，为发友的植发安全与效果提供有力保障，让发友“放心植发，安全变美”。
                                    </div>
                                    <div>
                                        参加中国植发大会，董事长尤丽娜发表《植发手术行业规范》重要演讲。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2018.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>与中国人保（PICC）达成战略合作，保障发友合法权益。</div>
                                    <div>
                                        华盖资本投资碧莲盛，两大巨头强强联手，促进植发行业健康发展。
                                    </div>
                                    <div>
                                        与阿里健康达成战略合作，加入天猫医美“安心美颜”联盟。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2017.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>
                                        董事长尤丽娜成为中国首位入驻世界植发大会案例中心的医生。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2015.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>受邀参加央视315晚会。</div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2013.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>
                                        受邀参加第四届国际资本峰会，董事长尤丽娜受到法国总统奥朗德接见。
                                    </div>
                                    <div>
                                        亲赴雅安地震灾区，捐出价值约100万的救灾物资，资助1000多个学生和家庭。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2012.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>
                                        自主研发精密即插即种植发技术，再次成为世界植发大会焦点。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2010.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>
                                        自主研发加密即插即种植发技术，并在当年世界植发大会上公开展示。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2008.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>受邀参加世界植发大会，向世界展示中国植发技术。</div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide my-swiper-slide">
                            <div class="aboutUsSwiper">
                                <img :src="`${$img}/year/2005.jpg`" alt="" />
                                <div class="aboutUsSwiper-text">
                                    <div>
                                        碧莲盛首家植发医院——北京碧莲盛医疗美容门诊部正式成立。
                                    </div>
                                    <div>成为世界植发协会会员和欧洲植发协会会员。</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-right" @click="addisMilepost">
                    <div class="swiperRight-after" :style="{
                        borderColor: isMilepost == 11 ? '#cfd2d3' : '#363636',
                    }"></div>
                </div>
            </div>
        </div>
        <div class="item" style="background: #f7f8fc">
            <div class="box">
                <div class="allTitle wow fadeInUp">企业文化</div>
                <div class="allTitle-En wow fadeInUp">CORPORATE CULTURE</div>
                <div class="culture">
                    <img :src="`${$img}/aboutUs/${Culture}.jpg`" class="culture-left" alt="" :key="+new Date()" />

                    <div class="culture-right">
                        <div class="culture-item wow fadeInUp" @mouseenter="Culture = 17" data-wow-delay="0.2s">
                            <div class="culture-title">品牌愿景</div>
                            <div class="culture-subTitle" v-if="Culture == 17">
                                成为中国毛发健康服务创领者
                            </div>
                            <div class="culture-text" v-if="Culture == 17"></div>
                        </div>
                        <div class="culture-item wow fadeInUp" @mouseenter="Culture = 18" data-wow-delay="0.3s">
                            <div class="culture-title">品牌使命</div>
                            <div class="culture-subTitle" v-if="Culture == 18">
                                医者仁心、医疗为本，为发友的健康美好生活提供终生服务
                            </div>
                            <div class="culture-text" v-if="Culture == 18"></div>
                        </div>
                        <div class="culture-item wow fadeInUp" data-wow-delay="0.4s" @mouseenter="Culture = 11"
                            style="border: none">
                            <div class="culture-title">品牌价值观</div>
                            <div class="BrandValues" v-if="Culture !== 17 && Culture !== 18">
                                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 16 }"
                                    @mouseenter="Culture = 16">
                                    客户第一
                                </div>
                                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 11 }"
                                    @mouseenter="Culture = 11">
                                    至诚守信
                                </div>
                                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 12 }"
                                    @mouseenter="Culture = 12">
                                    务实进取
                                </div>
                                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 13 }"
                                    @mouseenter="Culture = 13">
                                    责任担当
                                </div>
                                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 14 }"
                                    @mouseenter="Culture = 14">
                                    创新求变
                                </div>
                                <div class="BrandValues-item" :class="{ 'select-BrandValues': Culture == 15 }"
                                    @mouseenter="Culture = 15">
                                    高效执行
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item box">
            <div class="allTitle wow fadeInUp">荣誉奖项</div>
            <div class="allTitle-En wow fadeInUp" data-wow-delay="0.1s">
                HONORARY AWARDS
            </div>
            <div class="honor">
                <div class="honor-item wow fadeInUp" data-wow-delay="0.3s" v-for="(item, index) in honorList" :key="index">
                    <img :src="item.img" alt="" />
                    <div class="honor-mask">
                        <div>
                            <div>{{ item.title }}</div>
                            <div>{{ item.text }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import countTo from 'vue-count-to'
import Milepost from 'swiper'
import { observerBox } from '../utils/Intersection'
import 'swiper/css/swiper.css'
export default {
  components: {
    countTo
  },
  data () {
    return {
      wow: null,
      Culture: 17,
      isMilepost: 0,
      timer: null,
      honorList: [
        {
          title: '福布斯中国',
          text: '植发行业杰出品牌',
          img: `${this.$url}home/fbs.jpg`
        },
        {
          title: '中国(行业)十大领军品牌',
          text: '亚洲品牌十大创新人物',
          img: `${this.$url}home/sd.jpg`
        },
        {
          title: '中国创新医疗',
          text: '服务榜单100强',
          img: `${this.$img}/aboutUs/honor/1.jpg`
        },
        {
          title: '最青睐的',
          text: '高端植发品牌',
          img: `${this.$img}/aboutUs/honor/2.jpg`
        },
        {
          title: '植发行业领军企业',
          text: '医美行业科技创新奖',
          img: `${this.$img}/aboutUs/honor/3.jpg`
        },
        {
          title: '中国经济',
          text: '十大杰出女性',
          img: `${this.$img}/aboutUs/honor/4.jpg`
        },
        {
          title: '21世纪',
          text: '全国创新公司50强',
          img: `${this.$img}/aboutUs/honor/5.jpg`
        },
        {
          title: '突破性',
          text: '技术创新案例',
          img: `${this.$img}/aboutUs/honor/6.jpg`
        }
      ]
    }
  },
  methods: {
    addisMilepost () {
      if (this.isMilepost === 11) {
        return
      }
      this.isMilepost++
      this.mySwiper.slideTo(this.isMilepost)
    },
    delisMilepost () {
      if (this.isMilepost === 0) {
        return
      }

      this.isMilepost--
      this.mySwiper.slideTo(this.isMilepost)
    },
    editisMilepost (a) {
      this.isMilepost = a
      this.mySwiper.slideTo(a)
    },
    initSwiper () {
      const that = this
      this.mySwiper = new Milepost('.swiper-box', {
        direction: 'horizontal',

        slidesPerView: 1,
        loopFillGroupWithBlank: true,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,
        on: {
          slideChangeTransitionStart: function () {
            that.isMilepost = this.activeIndex
          }
        }
      })
    }
  },
  beforeDestroy () {
    this.wow.stop()
  },
  mounted () {
    const that = this
    const dom = document.querySelector('#StrategicLayoutAdd')
    observerBox(dom, (target) => {
      that.$refs.countTo1.start()
      that.$refs.countTo2.start()
      that.$refs.countTo3.start()
      that.$refs.countTo4.start()
      that.$refs.countTo5.start()
    })
    this.initSwiper()
    this.wow = new this.$wow.WOW({ live: false })
    this.wow.init()
  }
}
</script>

<style scss lang="scss"></style>
