var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team"},[_c('img',{staticClass:"banner",attrs:{"src":`${_vm.$img}/team/banner.jpg`}}),_c('div',{staticClass:"box"},[_c('div',{staticClass:"team-search",attrs:{"id":"location"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticStyle:{"font-size":"16px","color":"#666666","padding-left":"10px"},attrs:{"type":"text","placeholder":"请输入医生姓名"},domProps:{"value":(_vm.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$router.push({ path: 'team', query: { search: _vm.name } })},"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),_c('div',{staticClass:"search",on:{"click":function($event){return _vm.$router.push({ path: 'team', query: { search: _vm.name } })}}},[_c('img',{attrs:{"src":`${_vm.$img}/team/search.png`,"alt":""}})])]),(_vm.Dean !== 4)?_c('div',{staticClass:"Dean"},[_c('div',{staticClass:"Dean-item",class:{ selectDean: _vm.Dean == 1 },on:{"click":function($event){_vm.Dean = 1;
          _vm.index = 1;
          _vm.isAdd = false;}}},[_vm._v(" 名医堂 ")]),_c('div',{staticClass:"Dean-item",class:{ selectDean: _vm.Dean == 2 },on:{"click":function($event){_vm.Dean = 2;
          _vm.index = 1;
          _vm.isAdd = false;}}},[_vm._v(" 技术院长 ")]),_c('div',{staticClass:"Dean-item",class:{ selectDean: _vm.Dean == 3 },on:{"click":function($event){_vm.Dean = 3;
          _vm.index = 1;
          _vm.isAdd = false;}}},[_vm._v(" 植发医师 ")])]):_vm._e(),(_vm.Dean == 1)?_c('div',{staticClass:"temaList"},_vm._l((_vm.list),function(item){return _c('div',{key:item.title,staticClass:"temaList-item",on:{"click":function($event){return _vm.details(item.numberIndex)}}},[_vm._m(0,true),_c('div',{staticClass:"temaList-bg"},[_vm._v(_vm._s(item.en))]),_c('img',{attrs:{"src":`${_vm.$img}/team/Famous/list/${
            Number(item.numberIndex) + 1
          }.png`,"alt":""}}),_c('div',{staticClass:"temaList-line"}),_c('div',{staticClass:"temaList-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"temaList-position"},[_vm._v(_vm._s(item.position))]),_c('div',{staticClass:"temaList-details"},[_vm._v("详细介绍")])])}),0):_vm._e(),(_vm.Dean == 2)?_c('div',{staticClass:"temaList"},_vm._l((_vm.list),function(item,index){return _c('div',{key:item.title,staticClass:"temaList-item",on:{"click":function($event){return _vm.details(item.numberIndex)}}},[_vm._m(1,true),_c('div',{staticClass:"temaList-bg"},[_vm._v(_vm._s(item.en))]),(item.src)?_c('img',{attrs:{"src":`${_vm.$img}${item.src}?v=1`,"alt":""}}):_c('img',{attrs:{"src":`${_vm.$img}/team/Technical/list/${
            Number(item.numberIndex) + 2
          }.png`,"alt":""}}),_c('div',{staticClass:"temaList-line"}),_c('div',{staticClass:"temaList-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"temaList-position"},[_vm._v(_vm._s(item.position))]),_c('div',{staticClass:"temaList-details"},[_vm._v("详细介绍")])])}),0):_vm._e(),(_vm.Dean == 3)?_c('div',{staticClass:"temaList"},_vm._l((_vm.list),function(item,index){return _c('div',{key:item.title,staticClass:"temaList-item",on:{"click":function($event){return _vm.details(item.numberIndex)}}},[_vm._m(2,true),_c('div',{staticClass:"temaList-bg"},[_vm._v(_vm._s(item.en))]),(index <= 7)?_c('img',{attrs:{"src":`${_vm.$img}/team/Transplanter/list/${
            Number(item.numberIndex) + 1
          }.png`,"alt":""}}):_c('img',{attrs:{"src":`${_vm.$img}/team/Transplanter/list/${
            Number(item.numberIndex) + 2
          }.png?v=1`,"alt":""}}),_c('div',{staticClass:"temaList-line"}),_c('div',{staticClass:"temaList-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"temaList-position"},[_vm._v(_vm._s(item.position))]),_c('div',{staticClass:"temaList-details"},[_vm._v("详细介绍")])])}),0):_vm._e(),(_vm.Dean == 4)?_c('div',{staticClass:"temaList"},_vm._l((_vm.selectList),function(item,index){return _c('div',{key:index,staticClass:"temaList-item",on:{"click":function($event){return _vm.details(item.numberIndex, item.type)}}},[_vm._m(3,true),_c('div',{staticClass:"temaList-bg"},[_vm._v(_vm._s(item.en))]),(item.type == 1)?_c('img',{attrs:{"src":`${_vm.$img}/team/${item.path}/list/${
            Number(item.numberIndex) + 1
          }.png`,"alt":""}}):(item.type == 2 && item.src)?_c('img',{attrs:{"src":`${_vm.$img}${item.src}`,"alt":""}}):(item.type == 2 && !item.src)?_c('img',{attrs:{"src":`${_vm.$img}/team/${item.path}/list/${
            Number(item.numberIndex) + 2
          }.png`,"alt":""}}):(item.type == 3 && item.numberIndex <= 7)?_c('img',{attrs:{"src":`${_vm.$img}/team/${item.path}/list/${
            Number(item.numberIndex) + 1
          }.png`,"alt":""}}):_c('img',{attrs:{"src":`${_vm.$img}/team/Transplanter/list/${
            Number(item.numberIndex) + 2
          }.png?v=1`,"alt":""}}),_c('div',{staticClass:"temaList-line"}),_c('div',{staticClass:"temaList-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"temaList-position"},[_vm._v(_vm._s(item.position))]),_c('div',{staticClass:"temaList-details"},[_vm._v("详细介绍")])])}),0):_vm._e(),(!_vm.isAdd && _vm.Dean !== 4)?_c('div',{staticClass:"tema-Add",on:{"click":_vm.addIndex}},[_vm._v(" 查看更多 ")]):_vm._e(),_c('div',{staticClass:"tema-margin"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"temaList-bg"},[_vm._v("DOCTO"),_c('span',[_vm._v("R")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"temaList-bg"},[_vm._v("DOCTO"),_c('span',[_vm._v("R")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"temaList-bg"},[_vm._v("DOCTO"),_c('span',[_vm._v("R")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"temaList-bg"},[_vm._v("DOCTO"),_c('span',[_vm._v("R")])])
}]

export { render, staticRenderFns }