<template>
  <div class="direct">
    <div class="direct-top">
      <div
        class="box"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
        "
      >
        <img
          :src="`${$img}/home/rightLogo.png`"
          alt=""
          class="directTop-left"
        />
        <div class="directTop-right">
          <div class="directTop-consulting">在线咨询</div>
          <div class="directTop-phone">
            <div class="directTop-icon">
              <i class="iconfont icon-24gf-telephone"></i>
            </div>
            400-888-9882
          </div>
        </div>
      </div>
    </div>
    <div class="box item">
      <div class="National">
        <div class="National-left">
          <div id="container" style="height: 100%; width: 100%"></div>
        </div>
        <div class="National-right">
          <div class="National-title">全国直营院部</div>
          <div class="National-lop">
            <div class="National-select">
              <Select
                :selectData="selectData"
                :selValue="selValue"
                ref="dataValue"
                color="white"
                name="区域"
                @getValue="getValue"
                :key="1"
              ></Select>
              <Select
                :selectData="selectAddress"
                :selValue="selaAdress"
                ref="Adress"
                color="white"
                name="城市"
                @getValue="getAdress"
                :key="2"
              ></Select>
            </div>
          </div>
          <div class="National-subStitle">
            {{ addressData[0].name }}
          </div>
          <div class="National-item National-one">
            <div class="National-label">联系电话：</div>
            <div class="National-phone">
              {{ addressData[0].iphone }}
            </div>
          </div>
          <div class="National-item">
            <div class="National-label">门店地址：</div>
            <div class="National-phone">
              {{ addressData[0].address }}
            </div>
          </div>
          <div class="National-item">
            <div class="National-label">营业时间：</div>
            <div class="National-phone">
              {{ addressData[0].date }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="direct-bottom">
      Copyright © 2016 zhifajiage.com Inc.All Rights Reserved
      北京碧莲盛不剃发植发医疗美容门诊部有限责任公司 京ICP备12001479号-2
    </div>
  </div>
</template>
  
<script>
import list from "../utils/Adress";
import Select from "@/components/select.vue";
export default {
  components: {
    Select,
  },
  data() {
    return {
      selValue: 1,
      selaAdress: 1,

      selectData: [
        {
          name: "华北",
          value: 1,
        },
        {
          name: "东北",
          value: 2,
        },
        {
          name: "华东",
          value: 3,
        },
        {
          name: "华中",
          value: 4,
        },
        {
          name: "华南",
          value: 5,
        },
        {
          name: "西南",
          value: 6,
        },
        {
          name: "西北",
          value: 7,
        },
      ],
      addressData: [
        {
          name: "北京碧莲盛",
          date: "09:00-18:00",
          iphone: "18710178805",
          address: "北京市海淀区复兴路甲36号百朗园底商207",
          value: 1,
          point: [116.270052, 39.912878],
        },
      ],
      selectAddress: [],
      index: 0,
    };
  },
  mounted() {
    let id = this.$route.query;
    if (this.$route.query.type !== undefined) {
      this.index = id.index;
      this.$refs.dataValue.triggerValue(
        this.selectData[id.type].name,
        this.selectData[id.type].value,
        id.type
      );

      this.$refs.Adress.triggerValue(
        this.selectAddress[this.index].name,
        this.selectAddress[this.index].value,
        this.index
      );
    } else {
      this.$refs.dataValue.triggerValue(
        this.selectData[0].name,
        this.selectData[0].value,
        0
      );
    }
    this.setMap();
  },
  methods: {
    setMap() {
      var map = new BMapGL.Map("container"); // 创建Map实例
      let initLeft = this.addressData[0].point[0];
      let initright = this.addressData[0].point[1];
      map.centerAndZoom(new BMapGL.Point(initLeft, initright), 12); // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放fp
      var point = new Array(); //存放标注点经纬信息的数组
      var marker = new Array(); //存放标注点对象的数组
      var label = new Array(); //存放提示信息窗口对象的数组
      this.addressData.forEach((item, i) => {
        point[i] = new BMapGL.Point(item.point[0], item.point[1]);
        marker[i] = new BMapGL.Marker(point[i]);
        map.addOverlay(marker[i]);
        map.addOverlay(label[i]); // 将标注添加到地图中
      });
    },
    getValue(name, value, index) {
      this.selectAddress = list[index];
    },
    getAdress(name, value, index) {
      this.selaAdress = index + 1;
      this.addressData[0] = this.selectAddress[index];
      this.setMap();
    },
  },
};
</script>
  
<style lang="scss">
</style>