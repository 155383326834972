<template>
  <div class="mark">
    <div class="alert">
      <!-- 内容 -->
      <div class="cont">
        <!-- <div style="width:60vw;margin: 9vw auto 0;" v-if="select !== 0">

                    <video autoplay style="object-fit: cover;outline: none;" width="100%" :src="imgList[select].video"
                        ref="videoPlay" controls :poster="imgList[select].img">
                    </video>
                </div> -->
        <div style="width:60vw;margin: 8vw auto 0;border-right: 0;position: relative;">
          <div class="errorDiv1">
            <div class="error" @click="cancel"></div>
          </div>
          <div class="propVidoDiv" style="width:65vw;margin: 0 auto ;height: 30vw;">
            <!-- <video autoplay style="object-fit: cover;outline: none;margin-left: -2.3vw;" width="100%" height="100%" :src="propUrl" ref="videoPlay" controls :poster="propImg">
            </video> -->
            <div id="container" style="width: 100%;height:95%"></div>
            <div class="mark-text" id="mark-text" style="width:100%;height: 5%;margin: 0 auto;
                      line-height: 2.552vw;font-size: 1vw;background-color: #f9fafd;">
              {{ propTitle }}
            </div>
            <div class="image-item">
              <div class="mage-item" style="width: 14vw; margin-bottom: 2vw;
                    margin-right: 1.3vw;" v-for="(item, index) in propList" :key="index" :class="propId == item.id ? 'active' : ''" @click="next(item.id)">
                <img :src="item.pcImgUrl" alt="" style="width: 2.8vw;height: 8vw;">
                <div style="font-size: 0.1vw;padding: 0.3vw;">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="btns">

      </div>
    </div>
  </div>
</template>




<script>
export default {
  props: {
    url: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    propList: {
      type: Array,
      default: []
    },
  },
  mounted() {

  },
  watch: {
    url: {
      handler: function (newValue,oldValue) {
        this.propUrl = newValue;
        this.player = new Txplayer({
          //创建dom元素的id:
          containerId: 'container',
          //视频id:
          vid: newValue,
          //视频播放器的宽度
          width: '100%',
          //视频播放器的高度
          height: '800',
          //是否自动播放
          autoplay: true
        })
      },
      deep: true,
      immediate: true
    },
    title: {
      handler: function (newValue,oldValue) {
        this.propTitle = newValue
      },
      deep: true,
      immediate: true
    },
    img(newEle) {
      this.propImg = newEle
    },
    propList: {
      handler: function (newValue,oldValue) {
        this.propId = newValue[0].id
      },
      deep: true
    }
  },
  data() {
    return {
      propUrl: "",
      propImg: "",
      propTitle: "",
      propId: 0,
      player: null,
    }
  },
  methods: {
    // 点击取消
    cancel() {
      this.$emit('cancel')
    },
    next(id) {
      this.propId = id;
      this.propTitle = this.propList.find(item => item.id == id).title;
      let data = {
        id: id,
        secondType: "12",
        tagId: ""
      }
      axios.post(`${this.$request}v1/info/queryPcVideo`,data).then((res) => {
        this.getUrl(res.data.data.data[0].content);
        this.propImg = res.data.data.data[0].imgUrl
      }).catch((err) => {
        console.log(err);
      });
    },
    getUrl(vid) {
      this.player = new Txplayer({
        //创建dom元素的id:
        containerId: 'container',
        //视频id:
        vid: vid,
        //视频播放器的宽度
        width: '100%',
        //视频播放器的高度
        height: '800',
        //是否自动播放
        autoplay: true
      })
      //   let that = this;
      //   $.ajax({
      //     url: "https://vv.video.qq.com/getinfo?vids=" + vid + "&platform=101001&charge=0&otype=json",
      //     dataType: 'jsonp',
      //     jsonp: 'callback',
      //     success: function (data) {
      //       let ul = data.vl.vi;
      //       if (ul.length > 0) {
      //         let fn = ul[0].fn;
      //         let vkey = ul[0].fvkey;
      //         let uis = ul[0].ul.ui;
      //         that.propUrl = uis[0].url + fn + "?vkey=" + vkey;

      //       }
      //     }
      //   });
    },
  },
};
</script>

<style scoped lang="scss">
.mage-item {
  text-align: center;
  background-color: #f9fafd;

  overflow: hidden;
  cursor: pointer;

  img {
    width: 100% !important;
  }
}

.image-item {
  // margin-left: -10%;
  width: 120%;
  display: flex;

  flex-wrap: wrap;
}

.error {
  width: 2vw;
  height: 2vw;
  line-height: 26px;

  position: relative;
  cursor: pointer;
}

.error::before,
.error::after {
  content: '';
  position: absolute;
  height: 30px;
  width: 2px;
  top: 5px;
  left: 12px;
  background: rgb(0, 0, 0);
  opacity: 0.4;
}

.error::before {
  transform: rotate(45deg);
}

.error::after {
  transform: rotate(-45deg);
}

.mark {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  margin: 0 auto;
}

.cont {
  position: relative;
}

.cont > div {
  flex: 1;
}

.cont > div:nth-child(1) {
  border-right: 1px #ccc solid;
}

.btns {
  height: 100px;
  text-align: center;
}

.left dd {
  height: 50px;
  margin-bottom: 10px;
  position: relative;
}

.left dd label {
  display: flex;
  height: 50px;
  align-items: center;
  cursor: pointer;
}

.left dd label:hover {
  background: #f0f0f0;
}

.left dd label img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 5px;
}

.left dd em {
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  right: 0;
  top: 0;
  transition: all 0.8s;
}

.left dd em:hover {
  transform: rotate(360deg);
}

.active {
  border: 1px solid #0392ff;
}

.out-top {
  width: 2vw;
  height: 2vw;
  border-left: 2px solid #000000;
  border-bottom: 2px solid #000000;
  transform: translate(0, 50%) rotate(45deg);
  opacity: 0.4;
  float: right;
  margin-top: 3vw;
  margin-right: 0.5vw;
  cursor: pointer;
}

.out-topTwo {
  cursor: pointer;
  width: 2vw;
  height: 2vw;
  border-right: 2px solid #000000;
  border-top: 2px solid #000000;
  transform: translate(0, 50%) rotate(45deg);
  opacity: 0.4;
  float: right;
  margin-top: 3vw;
  margin-left: 0.5vw;
}
</style>
