var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header box"},[_c('img',{staticClass:"header-logo",attrs:{"src":`${_vm.$img}/home/log.png`,"alt":""}}),_c('ul',{staticClass:"header-tab"},[_c('li',{class:{ selectTab: _vm.selectTab == 0 },on:{"click":function($event){_vm.selectTab = 0;
            _vm.$router.push('/home');},"mouseenter":function($event){_vm.left = 0.2},"mouseleave":_vm.leaveTab}},[_vm._v(" 首页 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 1 },on:{"click":function($event){_vm.selectTab = 1;
            _vm.$router.push('/innovation');},"mouseenter":function($event){_vm.left = 21.5},"mouseleave":_vm.leaveTab}},[_vm._v(" 技术创新 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 2 },on:{"click":function($event){_vm.selectTab = 2;
            _vm.$router.push('/team');},"mouseenter":function($event){_vm.left = 45},"mouseleave":_vm.leaveTab}},[_vm._v(" 医师团队 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 4 },on:{"click":function($event){_vm.selectTab = 4;
            _vm.$router.push('/report');},"mouseenter":function($event){_vm.left = 69.5},"mouseleave":_vm.leaveTab}},[_vm._v(" 权威报道 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 5 },on:{"click":function($event){_vm.selectTab = 5;
            _vm.$router.push('/aboutUs');},"mouseenter":function($event){_vm.left = 93},"mouseleave":_vm.leaveTab}},[_vm._v(" 关于我们 ")]),_c('div',{staticClass:"buoy",style:({ left: _vm.left + '%' })},[_c('div',{staticClass:"sds"})])]),_vm._m(0)]),(_vm.isShow)?_c('div',{staticClass:"all-fixed"},[_vm._m(1),_vm._m(2),_vm._m(3)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-right"},[_c('div',{staticClass:"header-consulting"},[_vm._v("在线咨询")]),_c('div',{staticClass:"header-phone"},[_c('div',{staticClass:"header-icon"},[_c('i',{staticClass:"iconfont icon-24gf-telephone"})]),_vm._v(" 400-888-9882 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"fixed-first"},[_vm._v("在线客服")]),_c('img',{attrs:{"src":"\nhttps://static.drlianzhuren.com/HairTransplant/%E6%82%AC%E6%B5%AE%E7%AA%97%E5%9B%BE%E6%A0%87_%E7%94%BB%E6%9D%BF%201.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"fixed-two"},[_c('img',{attrs:{"src":"https://static.drlianzhuren.com/brandWeb/contactUs/wx.png","alt":""}}),_c('div',[_vm._v("微信公众号")])]),_c('img',{attrs:{"src":"https://static.drlianzhuren.com/HairTransplant/%E6%82%AC%E6%B5%AE%E7%AA%97%E5%9B%BE%E6%A0%87_%E7%94%BB%E6%9D%BF%201%20%E5%89%AF%E6%9C%AC.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"fixed-Tree"},[_c('img',{attrs:{"src":"https://static.drlianzhuren.com/brandWeb//xcxCode.jpg","alt":""}}),_c('div',[_vm._v("官方小程序")])]),_c('img',{attrs:{"src":"\nhttps://static.drlianzhuren.com/HairTransplant/%E6%82%AC%E6%B5%AE%E7%AA%97%E5%9B%BE%E6%A0%87_%E7%94%BB%E6%9D%BF%201%20%E5%89%AF%E6%9C%AC%202.png","alt":""}})])
}]

export { render, staticRenderFns }