<template>
  <div class="mark">
    <div class="alert">
      <!-- 内容 -->
      <div class="cont">
        <div style="width:60vw;margin: 9vw auto 0;" v-if="select !== 0">

          <video autoplay style="object-fit: cover;outline: none;" width="100%" :src="imgList[select].video" ref="videoPlay" controls :poster="imgList[select].img">
          </video>

        </div>
        <div v-else style="width:60vw;margin: 9vw auto 0;background-color: #000000;">
          <div style="width:45vw;margin: 0 auto ;">
            <video autoplay style="object-fit: cover;outline: none;" width="100%" :src="imgList[select].video" ref="videoPlay" controls :poster="imgList[select].img">
            </video>
          </div>
        </div>
        <div class="mark-text" style="width:60vw;height: 2.552vw;margin: 0 auto;
                      line-height: 2.552vw;font-size: 1vw;
                      margin-bottom: 0.5vw;background-color: #f9fafd;">
          {{ imgList[select].text }}
        </div>

        <div style="    position: absolute;right: 15vw; top: 0;">

          <div class="error" @click="cancel"></div>
        </div>

        <div class="image-item">
          <div class="out-top" @click="delselect"></div>
          <div class="mage-item" style="width: 14vw; margin-bottom: 2vw;margin-right: 1.3vw;" v-for="(item, index) in selectimgList" :key="index" :class="select == item.inde ? 'active' : ''" @click="select = item.inde">
            <img :src="item.img" alt="" style="
        width: 2.8vw;
      ">
            <div style="    font-size: 0.1vw;padding: 0.3vw;">
              {{ item.text }}
            </div>
          </div>
          <div class="out-topTwo" @click="Addselect"></div>
        </div>

      </div>

      <div class="btns">

      </div>
    </div>
  </div>
</template>




<script>
export default {
  props: {
    isSelect: {
      type: Number,
      default: 0
    }
  },
  created() {
    this.select = this.isSelect
    if (this.select >= 6) {

      this.isplay = true
      this.selectimgList = this.imgList.filter(item => item.inde > 5)
    } else {
      this.selectimgList = this.imgList.filter(item => item.inde < 6)

      this.isplay = false
    }
  },

  data() {
    return {

      select: 0,
      selectimgList: [],
      isplay: false,
      imgList: [
        {
          img: `${this.$url}/Journalism/Journalism1.jpg`,
          video: `${this.$url}/Journalism/vido/vido1.mp4`,
          text: "CCTV 《创新之路》",
          inde: 0
        },
        {
          img: `${this.$url}/Journalism/Journalism2.jpg`,
          text: "CCTV13 《新闻直播间》 ",
          video: `${this.$url}/Journalism/vido/vido2.mp4`,
          inde: 1
        },
        {
          img: `${this.$url}/Journalism/Journalism3.jpg`,
          video: `${this.$url}/Journalism/vido/vido3.mp4`,
          text: "人民网《人民会客厅·两会时刻》 ",
          inde: 2
        },
        {
          img: `${this.$url}/Journalism/Journalism4.jpg`,
          video: `${this.$url}/Journalism/vido/20160829%E5%B0%8F%E8%94%A1-%E5%A4%AE%E8%A7%86%E4%B8%80%E5%A5%97%E7%94%9F%E6%B4%BB%E5%9C%88-%E5%B0%B9%E6%A2%93%E8%B4%BB%E5%87%BA%E5%B8%AD%281%29%281%29.mp4`,
          text: "CCTV1 《生活圈》 ",
          inde: 3
        },
        {
          img: `${this.$url}/Journalism/Journalism5.jpg`,
          video: `${this.$url}/Journalism/vido/vido5.mp4`,
          text: "BTV 《品质生活》",
          inde: 4
        },
        {
          img: `${this.$url}/Journalism/Journalism6.jpg`,
          video: `${this.$url}/Journalism/vido/vido6.mp4`,
          text: "CCTV 《连线中国》 ",
          inde: 5
        },{
          img: `${this.$url}Journalism/style7.jpg`,
          video: `${this.$url}/Journalism/vido/vido7.mp4`,
          text: "CCTV《焦点关注》",
          inde: 6
        },
        {
          img: `${this.$url}Journalism/style8.jpg`,
          video: `${this.$url}/Journalism/vido/vido8.mp4`,
          text: "钛媒体《华楠直播间》",
          inde: 7
        },
        {
          img: `${this.$url}Journalism/style9.jpg`,
          video: `${this.$url}/Journalism/vido/vido9.mp4`,
          text: "山西电视台《黄河新闻》",
          inde: 8
        },
        {
          img: `${this.$url}Journalism/style10.jpg`,
          video: `${this.$url}/Journalism/vido/vido10.mp4`,
          text: "广东电视台《创新广东》",
          inde: 9
        },
        {
          img: `${this.$url}Journalism/style11.jpg`,
          video: `${this.$url}/Journalism/vido/vido11.mp4`,
          text: "甘肃电视台《今日文化君",
          inde: 10
        },
        {
          img: `${this.$url}Journalism/style12.jpg`,
          video: `${this.$url}/Journalism/vido/vido12.mp4`,
          text: "南昌电视台《新闻说报》",
          inde: 11
        },

      ],


    }
  },
  methods: {
    Addselect() {

      if (this.isplay) {
        this.selectimgList = this.imgList.filter(item => item.inde < 6)

      } else {
        this.selectimgList = this.imgList.filter(item => item.inde > 5)
      }
      this.isplay = !this.isplay


    },
    delselect() {
      if (this.isplay) {
        this.selectimgList = this.imgList.filter(item => item.inde < 6)

      } else {
        this.selectimgList = this.imgList.filter(item => item.inde > 5)
      }
      this.isplay = !this.isplay
    },
    // 点击取消
    cancel() {
      this.$emit('cancel')
    },
  }
};
</script>

<style scoped lang="scss">
.mage-item {
  text-align: center;
  background-color: #f9fafd;

  overflow: hidden;
  cursor: pointer;

  img {
    width: 100% !important;
  }
}

.image-item {
  // margin-left: -10%;
  width: 120%;
  display: flex;

  flex-wrap: wrap;
}

.error {
  width: 2vw;
  height: 2vw;
  line-height: 26px;

  position: relative;
  cursor: pointer;
}

.error::before,
.error::after {
  content: '';
  position: absolute;
  height: 30px;
  width: 2px;
  top: 5px;
  left: 12px;
  background: rgb(0, 0, 0);
  opacity: 0.4;
}

.error::before {
  transform: rotate(45deg);
}

.error::after {
  transform: rotate(-45deg);
}

.mark {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  margin: 0 auto;
}

.cont {
  position: relative;
}

.cont > div {
  flex: 1;
}

.cont > div:nth-child(1) {
  border-right: 1px #ccc solid;
}

.btns {
  height: 100px;
  text-align: center;
}

.left dd {
  height: 50px;
  margin-bottom: 10px;
  position: relative;
}

.left dd label {
  display: flex;
  height: 50px;
  align-items: center;
  cursor: pointer;
}

.left dd label:hover {
  background: #f0f0f0;
}

.left dd label img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 5px;
}

.left dd em {
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  right: 0;
  top: 0;
  transition: all 0.8s;
}

.left dd em:hover {
  transform: rotate(360deg);
}

.active {
  border: 1px solid #0392ff;
}

.out-top {
  width: 2vw;
  height: 2vw;
  border-left: 2px solid #000000;
  border-bottom: 2px solid #000000;
  transform: translate(0, 50%) rotate(45deg);
  opacity: 0.4;
  float: right;
  margin-top: 3vw;
  margin-right: 0.5vw;
  cursor: pointer;
}

.out-topTwo {
  cursor: pointer;
  width: 2vw;
  height: 2vw;
  border-right: 2px solid #000000;
  border-top: 2px solid #000000;
  transform: translate(0, 50%) rotate(45deg);
  opacity: 0.4;
  float: right;
  margin-top: 3vw;
  margin-left: 0.5vw;
}
</style>
