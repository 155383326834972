export default [
  [{
      name: "北京碧莲盛",
      value: 1,
      point: [116.270052, 39.912878],
      date: "09:00-18:00",
      iphone: "18710178805",
      address: "北京市海淀区复兴路甲36号百朗园底商207",
    }, {
      name: "石家庄碧莲盛",
      value: 27,
      point: [114.47955, 38.052876],
      date: "09:00-18:00",
      iphone: "0311-85390714 ",
      address: "石家庄市新华区和平西路315号自东向西第四间",
    },
    {
      name: "太原碧莲盛",
      value: 29,
      point: [112.578827, 37.873856],
      date: "09:00-18:00",
      iphone: "17303432581",
      address: "太原市迎泽区桥头街新月大厦一层",
    },
    {
      name: "呼和浩特碧莲盛",
      value: 12,
      point: [111.715351, 40.837865],
      date: "09:00-18:00",
      iphone: "15384716079",
      address: "呼和浩特市新城区东风路祥泰花园小区祥泰商厦1号楼",
    },
    {
      name: "天津碧莲盛",
      point: [117.1857, 39.124863],
      value: 50,
      date: "09:00-18:00",
      iphone: "15802491401",
      address: "天津市南开区卫津路与万德庄大街交口西南侧新都大厦",
    },
  ],
  [
    {
      name: "沈阳碧莲盛",
      value: 26,
      point: [123.426634, 41.810121],
      date: "09:00-18:00",
      iphone: "024-31321980",
      address: "沈阳市和平区北市场街道市府大路137号",
    },
    {
      name: "大连碧莲盛",
      value: 3,
      point: [121.638834, 38.923203],
      date: "09:00-18:00",
      iphone: "13204083898 ",
      address: "大连市中山区青泥洼桥124号渤海饭店二楼",
    },
    {
      name: "长春碧莲盛",
      point: [125.317149, 43.898185],
      value: 43,
      date: "09:00-18:00",
      iphone: "0431-88405593",
      address: "长春市朝阳区西安大路鸿基名筑1323号",
    },
  ],
  [{
      name: "上海碧莲盛",
      value: 24,
      point: [121.444486, 31.250812],
      date: "09:00-18:00",
      iphone: "021-62996616",
      address: "上海市普陀区澳门路618号",
    }, {
      name: "南京碧莲盛",
      value: 16,
      point: [118.741934, 32.049197],
      date: "09:00-18:00",
      iphone: "025-52225790",
      address: "南京市鼓楼区东宝路8号向阳渔港4楼",
    }, {
      name: "苏州碧莲盛",
      value: 28,
      point: [120.619801, 31.34553],
      date: "09:00-18:00",
      iphone: "18013602588",
      address: "苏州市姑苏区新平江商业广场一层102铺位",
    }, {
      name: "杭州碧莲盛",
      value: 10,
      point: [120.149319, 30.28342],
      date: "09:00-18:00",
      iphone: "0571-87756737",
      address: "杭州市西湖区文三路141号",
    }, {
      name: "宁波碧莲盛",
      value: 19,
      point: [121.567379, 29.887219],
      date: "09:00-18:00",
      iphone: "0574-87190433 分号8000",
      address: "宁波市江北区中马街道 人民路176-178号",
    }, {
      name: "合肥碧莲盛",
      value: 11,
      point: [117.221668, 31.866365],
      date: "09:00-18:00",
      iphone: "0551-64855114",
      address: "合肥市蜀山区井岗镇环湖东路39号",
    }, {
      name: "南昌碧莲盛",
      value: 17,
      point: [115.920609, 28.670004],
      date: "09:00-18:00",
      iphone: "13367911688",
      address: "南昌市西湖区天佑路5号",
    }, {
      name: "济南碧莲盛",
      value: 13,
      point: [117.022981, 36.672709],
      date: "09:00-18:00",
      iphone: "17863827922",
      address: "济南市历下区趵突泉北路11号",
    },
    {
      name: "青岛碧莲盛",
      value: 21,
      point: [120.392901, 36.075429],
      date: "09:00-18:00",
      iphone: "0532-85710016 转 8007",
      address: "青岛市市南区闽江路33号",
    },  {
      name: "厦门碧莲盛",
      value: 23,
      point: [118.126045, 24.49341],
      date: "09:00-18:00",
      iphone: "0592-6021257",
      address: "厦门市思明区长青北里108号103-104单元",
    }, {
      name: "泉州碧莲盛",
      value: 22,
      point: [118.617659, 24.889714],
      date: "09:00-18:00",
      iphone: "15880701079",
      address: "泉州市丰泽区泉秀路与坪山路交叉口豪盛花苑店面11号",
    },
  ],
  [{
      name: "郑州碧莲盛",
      point: [113.706792, 34.792057],
      value: 36,
      date: "09:00-18:00",
      iphone: "0371-60208377",
      address: "郑州市金水区丰产路街道农业路与东明路交叉口",
    },
    {
      name: "武汉碧莲盛",
      point: [114.264795, 30.619173],
      value: 31,
      date: "09:00-18:00",
      iphone: "027-85310948",
      address: "武汉市江汉区马场路金贸大厦一楼",
    },
    {
      name: "长沙碧莲盛",
      point: [112.989737, 28.251891],
      value: 35,
      date: "09:00-18:00",
      iphone: "0731-82694695",
      address: "长沙市开福区芙蓉北路街道湘江北路三段1388号",
    },
  ],
  [{
      name: "广州碧莲盛",
      value: 6,
      point: [113.36901, 23.135164],
      date: "09:00-18:00",
      iphone: "020-85532162",
      address: "广州市天河区天府路145号",
    },
    {
      name: "深圳碧莲盛",
      value: 25,
      point: [114.050967, 22.533005],
      date: "09:00-18:00",
      iphone: "0755-83274543",
      address: "深圳市福田区滨河大道9013号嘉洲豪园",
    },
    {
      name: "深圳莲空间",
      value: 40,
      point: [114.090079, 22.544589],
      date: "09:00-18:00",
      iphone: "0755-83209821",
      address: "深圳市福田区世界贸易广场裙楼5楼501A",
    },
    {
      name: "东莞碧莲盛",
      value: 4,
      point: [113.76449, 23.027793],
      date: "09:00-18:00",
      iphone: "0769-26988281",
      address: "东莞市南城街道体育路南城段30号",
    },
    {
      name: "珠海碧莲盛",
      point: [113.590441, 22.259542],
      value: 38,
      date: "09:00-18:00",
      iphone: "0756-3230015",
      address: "珠海市香洲区海滨南路68号诚丰银座2楼",
    }, {
      name: "南宁碧莲盛",
      value: 18,
      point: [108.397712, 22.809287],
      date: "09:00-18:00",
      iphone: "0771-5784691",
      address: "南宁市青秀区青秀路16-2号右江花园",
    }, {
      name: "海口碧莲盛",
      value: 9,
      point: [110.308083, 20.022192],
      date: "09:00-18:00",
      iphone: "0898-68536692",
      address: "海口市龙华区海秀111-1号濠江花园商铺",
    }, {
      name: "佛山碧莲盛",
      point: [113.114676, 22.997476],
      value: 41,
      date: "09:00-18:00",
      iphone: "13760751350",
      address: "佛山市禅城区汾江南路51号万通国际",
    },
    {
      name: "中山碧莲盛",
      point: [117.1857, 39.124863],
      value: 42,
      date: "09:00-18:00",
      iphone: "13568010580",
      address: "中山市东区中山四路财富大厦一楼",
    },
  ],
  [{
      name: "重庆碧莲盛",
      point: [106.536223, 29.571247],
      value: 37,
      date: "09:00-18:00",
      iphone: "023-67026816",
      address: "重庆市江北区金源路东方家园车站旁",
    },
    {
      name: "成都碧莲盛",
      value: 2,
      point: [103.9707, 30.678599],
      date: "09:00-18:00",
      iphone: "028-86949293",
      address: "成都市青羊区光华北三路160号",
    },
    {
      name: "绵阳碧莲盛",
      point: [104.744846, 31.466873],
      value: 39,
      date: "09:00-18:00",
      iphone: "13501288858",
      address: "绵阳市涪城区长虹大道中段附131号得月星城1幢",
    },
    {
      name: "贵阳碧莲盛",
      value: 7,
      point: [106.685879, 26.562979],
      date: "09:00-18:00",
      iphone: "0851-85756822/15186969175",
      address: "贵阳市南明区花果园K区1栋203号",
    },
    {
      name: "昆明碧莲盛",
      value: 14,
      point: [102.701492, 25.03517],
      date: "09:00-18:00",
      iphone: "0871-65522829",
      address: "昆明市西山区环城西路328号太阳城1层",
    },
  ],
  [{
      name: "西安碧莲盛",
      point: [108.9458, 34.263293],
      value: 32,
      date: "09:00-18:00",
      iphone: "029-62303176",
      address: "西安市碑林区南广济街83号",
    },
    {
      name: "兰州碧莲盛",
      value: 15,
      point: [103.83306, 36.061745],
      date: "09:00-18:00",
      iphone: "13669325512",
      address: "兰州市城关区酒泉路374-376号乾昌大厦二楼",
    },
    {
      name: "西宁碧莲盛",
      point: [101.745778, 36.646098],
      value: 33,
      date: "09:00-18:00",
      iphone: "17797084389",
      address: "西宁市城西区五四西路48-1号",
    },
    {
      name: "银川碧莲盛",
      point: [106.272197, 38.475494],
      value: 34,
      date: "09:00-18:00",
      iphone: "17795082162",
      address: "银川市兴庆区解放西路红旗文化大厦二层",
    },
    {
      name: "乌鲁木齐碧莲盛",
      point: [87.57836, 43.883835],
      value: 30,
      date: "09:00-18:00",
      iphone: "0991-4559985",
      address: "乌鲁木齐高新区紫金长安二期北区1栋2层",
    },
  ]
]
