import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "animate.css"
import "wowjs/css/libs/animate.css"
import wow from 'wowjs'
import Router from 'vue-router'
import './permission'
Vue.prototype.$wow = wow
Vue.config.productionTip = false
const originalPush = Router.prototype.push
Vue.prototype.$url = 'https://static.drlianzhuren.com/brandWeb/'
Vue.prototype.$img = 'https://static.drlianzhuren.com/HairTransplant/'
// Vue.prototype.$request = 'https://wxtest.blsgroup.com.cn/'
Vue.prototype.$request = 'https://wx.drlianzhuren.com/'



Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
