<template>
  <div>
    <div class="header box">
      <img :src="`${$img}/home/log.png`" alt="" class="header-logo" />
      <ul class="header-tab">
        <li
          @click="
            selectTab = 0;
            $router.push('/home');
          "
          :class="{ selectTab: selectTab == 0 }"
          @mouseenter="left = 0.2"
          @mouseleave="leaveTab"
        >
          首页
        </li>
        <li
          @click="
            selectTab = 1;
            $router.push('/innovation');
          "
          :class="{ selectTab: selectTab == 1 }"
          @mouseenter="left = 21.5"
          @mouseleave="leaveTab"
        >
          技术创新
        </li>
        <li
          @click="
            selectTab = 2;
            $router.push('/team');
          "
          :class="{ selectTab: selectTab == 2 }"
          @mouseenter="left = 45"
          @mouseleave="leaveTab"
        >
          医师团队
        </li>
        <!-- <li @click="
            selectTab = 3;
            $router.push('/service');
          " :class="{ selectTab: selectTab == 3 }" @mouseenter="left = 55" @mouseleave="leaveTab">
          透明服务
        </li> -->
        <li
          @click="
            selectTab = 4;
            $router.push('/report');
          "
          :class="{ selectTab: selectTab == 4 }"
          @mouseenter="left = 69.5"
          @mouseleave="leaveTab"
        >
          权威报道
        </li>
        <li
          @click="
            selectTab = 5;
            $router.push('/aboutUs');
          "
          :class="{ selectTab: selectTab == 5 }"
          @mouseenter="left = 93"
          @mouseleave="leaveTab"
        >
          关于我们
        </li>
        <div class="buoy" :style="{ left: left + '%' }">
          <div class="sds"></div>
        </div>
      </ul>
      <div class="header-right">
        <div class="header-consulting">在线咨询</div>
        <div class="header-phone">
          <div class="header-icon">
            <i class="iconfont icon-24gf-telephone"></i>
          </div>
          400-888-9882
        </div>
      </div>
    </div>
    <div class="all-fixed" v-if="isShow">
      <div>
        <div class="fixed-first">在线客服</div>
        <img
          src="
https://static.drlianzhuren.com/HairTransplant/%E6%82%AC%E6%B5%AE%E7%AA%97%E5%9B%BE%E6%A0%87_%E7%94%BB%E6%9D%BF%201.png"
          alt=""
        />
      </div>
      <div>
        <div class="fixed-two">
          <img
            src="https://static.drlianzhuren.com/brandWeb/contactUs/wx.png"
            alt=""
          />
          <div>微信公众号</div>
        </div>

        <img
          src="https://static.drlianzhuren.com/HairTransplant/%E6%82%AC%E6%B5%AE%E7%AA%97%E5%9B%BE%E6%A0%87_%E7%94%BB%E6%9D%BF%201%20%E5%89%AF%E6%9C%AC.png"
          alt=""
        />
      </div>
      <div>
        <div class="fixed-Tree">
          <img
            src="https://static.drlianzhuren.com/brandWeb//xcxCode.jpg"
            alt=""
          />
          <div>官方小程序</div>
        </div>
        <img
          src="
https://static.drlianzhuren.com/HairTransplant/%E6%82%AC%E6%B5%AE%E7%AA%97%E5%9B%BE%E6%A0%87_%E7%94%BB%E6%9D%BF%201%20%E5%89%AF%E6%9C%AC%202.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      left: 0.2,
      selectTab: 0,
      isShow: false,
    };
  },
  mounted() {
    switch (this.$route.path) {
      case "/home":
        this.selectTab = 0;
        break;
      case "/innovation":
        this.selectTab = 1;
        break;
      case "/team":
        this.selectTab = 2;
        break;
      case "/service":
        this.selectTab = 3;
        break;
      case "/report":
        this.selectTab = 4;
        break;
      case "/aboutUs":
        this.selectTab = 5;
        break;
      case "/info":
        this.selectTab = 4;
        break;
      case "/Personal":
        this.selectTab = 2;
        break;
      case "/project":
        this.selectTab = 0;
        break;
    }
    this.leaveTab();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    getScroll() {
      return {
        top:
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0,
      };
    },
    handleScroll() {
      const scrollTop = this.getScroll().top;
      if (533 < scrollTop) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    leaveTab() {
      switch (this.selectTab) {
        case 0:
          this.left = 0.2;
          break;

        case 1:
          this.left = 21.5;
          break;
        case 2:
          this.left = 45;
          break;
        case 3:
          this.left = 49;
          break;
        case 4:
          this.left = 69.5;
          break;
        case 5:
          this.left = 93;
          break;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.all-fixed {
  position: fixed;
  z-index: 99;
  bottom: 100px;
  right: 20px;
  div {
    background-color: #cfd2d3;
    margin-bottom: 2px;
    cursor: pointer;
    padding: 5px;
    position: relative;
    img {
      width: 40px;
      height: 40px;
      vertical-align: middle;
    }
    &:nth-child(1):hover {
      .fixed-first {
        display: block;
      }
    }
    &:nth-child(2):hover {
      .fixed-two {
        display: block;
      }
    }
    &:nth-child(3):hover {
      .fixed-Tree {
        display: block;
      }
    }
  }
  .fixed-first {
    display: none;
    width: 113px;
    height: 50px;
    background-color: #cfd2d3;
    position: absolute;
    right: 50px;
    top: 0;
    color: #ffffff;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
  .fixed-two {
    display: none;
    position: absolute;
    right: 60px;
    top: 0;
    width: 115px;
    height: 130px;
    background-color: #ffffff;
    border: solid 1px #cfd2d3;
    text-align: center;
    padding-top: 5px;
    img {
      width: 100px;
      height: 100px;
    }
    div {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 13px;
      letter-spacing: 0px;
      color: #707070;
      background-color: #ffffff;
    }
  }
  .fixed-Tree {
    display: none;
    position: absolute;
    right: 60px;
    top: 0;
    width: 115px;
    height: 130px;
    background-color: #ffffff;
    border: solid 1px #cfd2d3;
    text-align: center;
    padding-top: 5px;
    img {
      width: 100px;
      height: 100px;
    }
    div {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 13px;
      letter-spacing: 0px;
      color: #707070;
      background-color: #ffffff;
    }
  }
}
</style>
