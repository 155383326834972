import router from './router'




//   每次切换tab时让滚动条回到原点
router.beforeEach(async (to, from, next) => {
  //  && from.name !== 'info' && from.name !== 'home' && from.name !== 'team' report
  if (to.name !== 'home' && to.name !== 'team' && to.name !== 'report' && to.name !== 'search') {
    let t = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    let time = setInterval(() => {
      t -= 180
      document.documentElement.scrollTop = t
      if (t <= 0) {
        clearInterval(time)
      }
    }, 5)
  }
  next()
})
