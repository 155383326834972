const url = 'https://static.drlianzhuren.com/brandWeb/'
const img = 'https://static.drlianzhuren.com/HairTransplant/'
export default [{
    title: '《2022中国植发行业研究报告》发布 不剃发植发重塑植发行业赛道',
    date: '发布时间：2022-07-01',
    list: [{
        text: '近日，国内消费领域权威媒体《消费日报》、知名第三方研究机构秒针联合发布了《2022中国植发行业研究报告》（下文简称《报告》），《报告》深度聚焦了我国植发行业消费人群、植发行业主流技术、植发机构医学养护服务等内容，深入探讨了植发行业和医学养护行业的发展趋势。'
      },
      {
        text: '颜值经济时代，人们对美感和容貌的要求不断提高，但是现实很残酷，社会压力大、生活节奏快、不健康的生活方式等因素共同作用下，脱发群体不断壮大，脱发焦虑已经成为一个社会普遍的现象和问题，脱发群体的困境值得引起整个社会的关注。'
      },
      {
        text: '颜值经济催生植发需求，年轻人群对外貌关注度极高，在个人审美上有较高追求，加上他们面临的求职、求偶等人生重大事件，他们很重视给他人留下的印象，茂密健康的头发有助于提升社交自信，他们更加期待植发带来工作和生活质量的提升。'
      },
      {
        smallTile: '市场规模持续扩大，年轻人群成消费主力'
      }, {
        text: '国家卫健委2019年调查数据显示，我国脱发人数超2.5亿，平均每六人中就有一人脱发，其中男性约1.63亿，女性约0.88亿。其中，30岁前脱发的人群占比高达84%，比上一代人的脱发年龄提前了20年，呈现明显的年轻化趋势。'
      },
      {
        text: '随着消费者认知和接受水平的不断提升，对植发的需求也在不断增加，因此尽管受到疫情的影响，植发行业整体的客户规模仍在持续扩大。另一方面，越来越多的年轻人对自身的整体形象有了更高的要求，这就使得植发消费群体的年轻化趋势进一步增强，以90后为代表的年轻人群已经成为植发的主力军。'
      },
      {
        text: '《报告》指出，2015-2018年这几年，行业进入到一个快速发展的时期，从三个维度来说，业绩成倍递增、连锁机构数量成倍增加以及个体机构的面积、规模的扩增。2016年，中国植发行业开始蓬勃发展，当年市场规模为58亿元，随着市场规模的不断扩大，至2021年，中国植发行业市场规模达到169亿元，比2020年增长了26.1%。'
      },
      {
        Imag: `${img}/report/42/1.png`,
      }, {
        text: '随着人们消费能力的増长以及对提升个人形象的重视，有植发需求的消费者数量日益増多，因此，植发行业在近年来有着较为迅速的发展。《报告》显示，植发业的消费人群中本科学历占比达63%、大专及以下学历占比达29%、硕士及以上学历占比达8%。'
      },
      {
        Imag: `${img}/report/42/2.png`,
      },
      {
        text: '可以看出，目前植发人群以高收入、高学历人群为主，且此类人群大多对生活品质也有着较高要求。一方面，他们重视颜值，在个人审美上有较高追求，另一方面，他们看重品质，对自身生活的品质和感受有较高的追求。这类人群愿意为品质买单，也有能力享受更好的产品和服务，他们往往是消费潮流的带动者，代表未来趋势。'
      },
      {
        smallTile: '技术关注度持续提升，不剃发植发重塑赛道'
      },
      {
        text: '《报告》指出，植发行业消费人群在2021年的新变化主要体现在技术关注度提升。整体而言，消费者决策早期对技术的关注度有所提升，对于植发过程中的体验感，如疼痛、舒适度、医疗团队的服务也更为关注。'
      },
      {
        text: '随着市场的不断扩大，植发技术也日渐成熟，但是并不能满足年轻一代的消费者提出的新需求，他们不仅要通过植发满足爱美之心，也在个性化、定制化、隐私化等方面提出了更高的要求。不少专家和医生都表示，对于植发行业而言，不剃发植发技术逐渐成为中高端消费者的首选。'
      },
      {
        text: '《报告》显示，在各项主流技术中，不剃发植发技术近年来受到越来越多的关注，根据舆情数据显示，不剃发植发技术的网络讨论热度相比去年提升了76%，该项技术也因其效果自然、恢复快、能有效避免尴尬期等优势，在消费者中的使用意愿最高。'
      },
      {
        Imag: `${img}/report/42/3.png`,
      },
      {
        text: '根据中国整形美容协会2021年发布的《毛发移植规范》团体标准，植发主要分为毛囊提取和种植两个维度：毛囊提取国内普遍采用毛囊单位钻取术（FUE），而毛囊种植根据不同的种植器械，主要是镊子种植、种植笔和即插即种三种技术，以上技术均需在手术前剃发，对工作、生活造成一定的影响。'
      },
      {
        text: '据了解，不剃发植发是目前行业最前沿的技术，它颠覆了植发的传统，实现了毛囊提取、种植技术的双重革新，该项技术是碧莲盛在2020年率先推出的，是植发行业的创举。《报告》显示，使用不剃发植发技术的用户中有92.8%来自碧莲盛。'
      },
      {
        Imag: `${img}/report/42/4.png`,
      },
      {
        text: '据碧莲盛相关负责人介绍，碧莲盛旗下39家直营院部已全部普及不剃发植发技术，2021年月均不剃发植发手术量约1500台，占机构同期手术量的30%-40%。同时，《报告》显示，雍禾、大麦、新生三家头部机构的不剃发植发月均手术量约10台，在机构手术量的占比低于5%。毫无疑问，在不剃发植发这一细分赛道，碧莲盛已经领先了不止一个身位。'
      },
      {
        smallTile: '植养闭环形成，医学养护撑起万亿新风口'
      },
      {
        text: '《报告》数据显示，2017年-2019年开始，中国养发服务行业市场规模高速增长，截至2022年上半年市场规模达到 883 亿元。一名行业协会专家告诉记者，“因为脱发是慢性发展的过程，所以头皮的养护也需要长期的过程，市场潜力是巨大的，未来很大可能会突破万亿规模。”'
      },
      {
        Imag: `${img}/report/42/5.png`,
      },
      {
        text: '《报告》认为，对于植发行业而言，复购率低一直是各机构在运营中需要解决的一个重要问题。对于品牌而言，如何能够吸引消费者再次到店成为其营销过程中的关键。因此，打造“植养护一体”的闭环就显得格外重要。'
      },
      {
        text: '随着消费者认知水平的提升以及对于生活品质的追求的再次提升，“养疗结合”的理念逐渐深入人心。近年来医学养护市场也在逐渐扩大，养发的发展一定程度上弥补了植发复购率低的弊端。未来几年，医学养护将成为植发行业不可或缺的重要一环。'
      },
      {
        text: '莲主任是碧莲盛旗下医学养护的子品牌，莲主任在重视养护的同时，更加强调医学治疗的严谨性和专业性，倡导养护与治疗相结合、药物与仪器相结合，头皮治疗与真皮层治疗相结合，由表及里、由浅入深，为客户提供多层次、多维度、全周期的医学养护管理方案。'
      },
      {
        text: '莲主任相关负责人告诉记者，“从医学的角度来说，手术与非手术共同作用，才能达到更好效果；然后从市场的角度，非手术治疗可以让客户可以长期不断的作为你的一个消费者。所以18、19年以后各家机构都逐渐把医学养护当做一个新的核心战略去重点发展。”'
      },
      {
        text: '对于不脱发或脱发严重程度较轻的人群，头发的养护和防脱是愈发凸显的新需求点，药物、仪器、按摩治疗服务将获得广泛青睐。另一方面，已植发人群对于原生发的护理意识更强，对于医学养护服务关注度和敏感度将不断升高。《报告》指出，未来将出现行业洗牌，满足消费者多种综合性头皮养护需求的专业养发机构或成最后赢家。'
      }
    ]
  },
  {
    title: '估值再创新高，“头部”企业碧莲盛如何实现逆势增长？',
    date: '发布时间：2022-06-15',
    list: [{
        text: '2022年6月15日，“2022未来医疗100强主榜”发布。我们惊喜地发现，碧莲盛已连续数年闯进“创新医疗服务榜“，并创下估值新高。入选2020届“未来医疗100强”时，其估值仅为20亿元。两年时间，碧莲盛已完成估值翻倍，如今估值达45亿元。'
      },
      {
        Imag: `${url}/JournalismInfo/1/%E5%9B%BE%E7%89%871.png`
      },
      {
        text: '“头部”企业碧莲盛如何实现连年估值攀升？经济大环境趋冷之下，又如何做到营收逆势增长？'
      },
      {
        Imag: `${url}/JournalismInfo/1/%E5%9B%BE%E7%89%872.png`
      },
      {
        smallTile: '不剃发植发技术，市占率超九成'
      },
      {
        text: '根据艾媒咨询的统计，2019年我国约有2.5亿脱发人群。随着生活习惯和压力的增加，脱发已出现明显的年轻化趋势，约60%的人在25岁就已经出现脱发现象，年轻群体已经成为植发的主要消费群体。'
      },
      {
        text: '随着脱发困扰人群的不断壮大，植发市场也迎来广阔的成长空间。2016年至 2020 年，我国植发医疗服务市场规模从 58亿元提升至134亿元，复合增速达23.3%，实现快速增长。另根据沙利文的预测，2030年我国植发医疗服务市场规模有望达到756亿元。'
      },
      {
        text: '市场虽大，但就植发本身而言，还面临一些痛难点。首先就是消费者隐私。如何保证隐私不受侵犯，不被看出已经植发，是年轻群体对植发项目一个重要需求。同时，植发手术的安全性风险、植发后的毛囊存活率问题，也是行业发展的一大难点。'
      },
      {
        text: '针对此，碧莲盛首先从技术上做了创新与保障。2020年8月，碧莲盛首次推出不剃发植发技术。该技术真正做到了长发取、长发种，最长可提取8公分的原生长发，提取、种植全程不剃发，手术当天就能看到最终的植发效果，避免了剃发植发对脱发患者个人形象的短期影响，满足了消费者保护隐私、快速变美的需求。'
      },
      {
        text: '据碧莲盛介绍，目前碧莲盛已完成逾2万台不剃发植发手术，在整个行业不剃发植发手术数量的占比超过了90%。NHT不剃发植发已成为碧莲盛在行业内品牌优势的护城河。'
      },
      {
        smallTile: '百位医生、千位医护，打造优势植发团队'
      },
      {
        text: '其实，不剃发植发技术诞生已久，但目前国内植发行业能够真正熟练应用的植发机构少之又少，其壁垒事实上并不在技术本身，而在于是否拥有大量具备成熟操作经验的手术医生。'
      },
      {
        text: '目前，碧莲盛组建了一支经验丰富的医护团队，拥有100余位实战派植发医生，800多名专业医护人员。其中，主治医师占比达超六成，10位为主任/副主任医师，这在植发行业，甚至医美行业都较为少见。'
      },
      {
        text: '在人才吸引、选择与培养方面，碧莲盛也下了不少功夫。首先，碧莲盛建立了严格的选聘制度和专业考核制度，确保每一位医生都有国家颁发的合规资质证书。'
      },
      {
        text: '其次，碧莲盛建立了成熟的培训体系，比如医生带教模式，碧莲盛在7大区域均配备了医疗带教官，他们都是资历身后、经验丰富的医生，新医生入职之后，都会在他们的言传身教之下提升技术。最后，碧莲盛打造了完善的医生成长体系，通过专利申报、学术论文发表、媒体报道、新媒体推广等方式，帮助医生们打造个人IP品牌。'
      },
      {
        text: '在技术研发方面，碧莲盛也正在不断增加研发费用，鼓励医生不断去创新技术，特别是不剃发植发技术相关的研发，是碧莲盛目前在技术研发方面的重点。其次，碧莲盛选择通过制度创新保证技术研发的推进，碧莲盛的7大区域都设立了主任/副主任医师级别的医疗技术带头人，并以他们为核心组建了研发团队。最后，碧莲盛也在知识产权和专利申报方面着力，进一步强化不剃发植发领域的技术优势。'
      },
      {
        smallTile: '拓展养发新蓝海，满足毛发产业链多重需求'
      },
      {
        text: '2021-2022年，我国护发市场成交额以28.7%的速度增长，护发及相关产业达到400亿规模。由于植发业务本身复购率较低，加之消费者对毛发养护的需求水平不断提升，近年来医学养护业务在植发行业得到发展。'
      },
      {
        text: '为此，碧莲盛于2020年年底，碧莲盛正式推出养护市场全资独立品牌——莲主任，而“医学养发”就是莲主任与传统养发的最大区别。传统养发是通过“以养代疗”的方式改善毛发健康，重点在养护，并不能从医学层面解决问题。'
      },
      {
        text: '莲主任则在重视养护的同时，更加强调医学治疗的严谨性，倡导养护与治疗相结合、药物与仪器相结合，头皮治疗与真皮层治疗向相结合，由表及里、由浅入深，为客户提供多层次、多维度、全周期的医学生发管理方案。'
      },
      {
        text: '在治疗方法层面，传统养发机构的重点在于养护头皮，清洁表皮层头皮环境，只解决表层及表象问题，莲主任则是将重点放在治疗毛囊，通过医学的方式，解决毛发健康的深层问题。'
      },
      {
        text: '在服务人员层面，传统养发机构一般由养发师提供服务，养发馆对养发师进行短期的培训即可上岗，对医疗资质并没有要求，莲主任则组建了一支经验丰富的护师团队，人人持有合规的医疗资质，为用户提供的是医疗服务。'
      },
      {
        text: '自从“莲主任”品牌投入运营以来，其专业服务也得到了消费者的广泛认可，很多植发用户正在转化为养护用户。通过私域运营以及口口相传，已经产生传播的裂变效果。'
      },
      {
        text: '相比2020年，“莲主任”2021年的营收增速达到了100%。同时，“莲主任”营收在碧莲盛总营收的占比也在逐年提升，目前已经达到了20%。未来，“莲主任”在碧莲盛的业务比重将会进一步提升，市场潜力巨大。'
      },
      {
        smallTile: '行业集中度提升，坚持合规发展与标准化运营'
      },
      {
        text: '2021年6月10日,国家卫健委等八部委联合印发《打击非法医疗美容服务专项整治工作方案》，表示将加大力度整治不正规的医疗机构以及行业乱象。市场的选择也将向头部植发机构靠拢，行业集中度有望进一步提升。'
      },
      {
        text: '作为行业内公认的头部企业，碧莲盛认为内部管理有效有序、服务精细精准是合规经营的保障。经营模式上，碧莲盛采用的是“一城一店”的直营模式，目前已经在全国38个城市开设了直营机构。碧莲盛的所有分院，从院部选址、院部装修开始，均由集团总部统一负责。'
      },
      {
        text: '目前，碧莲盛建立了统一规范的品牌体系、运营体系、培训体系、就诊流程和服务标准，全国院部保持统一的视觉形象输出，同时对前台、顾问、医护团队进行统一的培训，全国所有家分院遵循统一的管理标准，以确保全国用户都能享受到品质如一的服务。'
      },
      {
        text: '植发行业全产业链的市场需求很大。未来，碧莲盛将继续加大研发投入，完善服务体系，扩展直营医院，研发自有品牌产品，不断深化行业布局，加强上下游资源整合，线上线下链路打通，构建一个集植发、养发、固发于一体的毛发健康产业生态闭环。'
      },
      {
        text: '另外，碧莲盛将进一步下沉市场到三四线城市，完成百城百店的战略布局。同时加大在养发领域的投入，将“莲主任”打造成在养发行业极具影响力的品牌，建立起可复制的养发连锁门店。'
      },
      {
        smallTile: '写在最后'
      },
      {
        text: '碧莲盛创始人尤丽娜表示：“疫情给碧莲盛的发展带来了许多不确定性。不过，疫情的爆发让我们可以停下来静心思考，这是弥足珍贵的。这段时间，我们进行了企业的又一次改革，做出了重要的组织架构调整，创新院长责任制，数智化。同时，碧莲盛成立运营中心，做到院部发力、数据驱动、总部赋能三管齐下，这次改革推动了后续碧莲盛在管理上、业绩上、数字化上的前行。”'
      },
      {
        text: '植发行业从最初的“默默无闻”，到如今“骤热”成为舆论焦点话题，离不开全行业的共同努力与突破。作为头部企业，碧莲盛将继续发挥自身优势引领行业发展。具体来说，公司将把合规经营作为底线，完成技术的护城河，探索可复制的发展模式，完成渠道继续下沉的战略布局，稳步推进上市计划，助力植发行业早日攻破千亿市值大关。'
      }

    ]
  },
  {
    title: '播种希望 筑梦未来 碧莲盛开展六一爱心助学公益行',
    date: '发布时间：2022-06-01',
    list: [{
        text: '6月1日，在第72个国际儿童节到来之际，碧莲盛发起了六一爱心助学公益行活动，碧莲盛太原分院院长李丽华等6名爱心人士代表走进河津市柴家镇上市小学，为同学们送上了一份珍贵而有意义的礼物，陪伴孩子们度过了一个专属于他们的六一儿童节。'
      },
      {
        Imag: `${url}/JournalismInfo/2/1.png`
      },
      {
        text: '“少年强，则国强，青少年是国家和民族的未来，希望在全社会的关心关爱下，每一个孩子都能在阳光下健康成长。”李丽华表示，“作为一家用技术为人民创造美好的医疗机构，碧莲盛希望未来能够有更多的机会积极回报社会。”'
      },
      {
        text: '活动现场，碧莲盛为上市学校里的每个学生捐赠了一个“爱的四季包”，书包中还有电动削笔机、电动橡皮擦、电动吸尘器等电动文具套装，每一件都有精致可爱的包装，在提升学习积极性的同时，还省时省力让同学们学习更加高效。'
      },
      {
        text: '据悉，去年的六一儿童节，碧莲盛就来到了上市小学，为同学们捐赠了一座“爱的图书馆”，包括书架、阅读桌、椅子以及各类型的图书。'
      },
      {
        text: '今年，碧莲盛又带来了一批新的图书，碧莲盛的爱心人士代表不仅当场写下爱的寄语，还与同学们一同阅读新书，在体验阅读学习的乐趣的同时，帮助同学们养成良好的阅读习惯。'
      },
      {
        Imag: `${url}/JournalismInfo/2/2.png`
      },
      {
        text: '上市小学樊校长深情地说：“非常感谢碧莲盛的爱心人士，去年为同学们捐赠了一所图书馆，让同学们有了阅读课外书籍的地方。这次又为同学们带来了一批新书、精致的新书包和可爱的电动文具，让学生们更加快乐地学习，真是帮了学校的大忙。”'
      },
      {
        text: '多年来，碧莲盛一直牢记公益初心，努力践行公益使命，积极开展乐学书包捐赠、一对一助学、爱的图书馆、同走上学路等多种形式的爱心助学活动，及时向困难学生伸出援手，不断向留守儿童送出关爱，像呵护树苗一样呵护少年儿童，让他们健康快乐成长。'
      },
      {
        Imag: `${url}/JournalismInfo/2/3.png`
      },
      {
        text: '未来，碧莲盛将继续关注贫困地区的留守儿童，除了捐赠学龄阶段儿童需要的学习物资之外，还会捐赠更多的爱心图书馆，并为爱心图书馆配备更全面的设施，让孩子们接触更精彩的外部世界，为他们梦想的实现提供助力。'
      }

    ]
  },
  {
    title: '碧莲盛荣获胡润百富“最青睐的高端植发品牌”',
    date: '发布时间：2022-01-20',
    list: [{
        text: '1月20日，第十八届胡润百富至尚优品颁奖盛典在上海隆重举行，数百位行业精英、商界领袖和社会名流齐聚盛会，共同见证中国精英群体的品牌倾向及消费风尚。中国植发行业头部品牌碧莲盛荣获“最青睐的高端植发品牌”大奖，以创新科技和高端品质，引领时尚健康生活新格调。'
      },
      {
        text: '据悉，“胡润百富至尚优品颁奖盛典”旨在表彰过去一年被行业所公认、发展成果最显著的品牌。碧莲盛凭借其良好的信誉口碑、卓越的技术创新能力以及不剃发植发技术对植发行业发展的突出贡献，成为唯一获得2021至尚优品奖项的植发品牌，这代表着高端消费市场对碧莲盛的高度认可。'
      },
      {
        text: '作为“还原中国高净值人群生活方式”的最权威榜单之一，胡润研究院连续第十八年发布《至尚优品—中国千万富豪品牌倾向报告》，旨在揭示中国高净值人群的生活方式、消费习惯以及品牌认知的转变与偏好。这份报告被广泛认为是面向高净值人群品牌的ISO，是国内最具影响力的财经榜单之一。'
      },
      {
        text: '碧莲盛华北地区总经理苏林女士在颁奖典礼上表示，“脱发年轻化的趋势目前十分明显，年轻人对自己的形象要求更高，对植发有更清楚的认知，更愿意尝试植发这种新生事物。碧莲盛深耕植发行业17年，就是想通过自己的努力，帮助广大脱发患者找回自信，满足人民对美好生活的向往。”'
      },
      {
        Imag: `${url}/JournalismInfo/5/1.png`
      },
      {
        text: '据了解，碧莲盛成立于2005年，是全行业第一家全部采用无痕植发技术的连锁植发机构，连续十三年参加世界植发大会，向世界展示中国植发技术。2020年推出的不剃发植发技术，是中国植发行业首次将不剃发这一概念正式落地，被行业专家誉为“植发行业的5G技术革新”。'
      },
      {
        text: '活动现场，上海碧莲盛技术院长、副主任医师周宗贵出席同期举办的胡润百富奢侈品领袖论坛，并围绕“主流消费品和高端消费”的主题进行了分享。周宗贵指出，“中国已经进入了新消费时代，年轻人群体有很多新消费需求，我们不仅要通过植发满足他们的爱美之心，还要在个性化、隐私化等方面用功。所以，碧莲盛自主研发了不剃发植发技术，来更好的满足年轻人提出来的新需求，不仅保证植发效果，还全方位提升服务体验。”'
      },
      {
        Imag: `${url}/JournalismInfo/5/2.png`
      },
      {
        text: '据介绍，第五届中国毛发移植大会发布了《毛发移植规范》团体标准，碧莲盛正是起草单位之一。该《规范》指出，植发主要是毛囊提取和种植两个维度，毛囊提取采用传统的FUT、FUE技术，毛囊种植则主要是镊子种植、种植笔和即插即种三种方式。无论是毛囊提取还是种植，剃发都是植发的第一步。碧莲盛推出的不剃发植发则实现了植发技术的革命性突破，彻底改变了植发行业“先剃发后植发”的传统，实现了毛囊提取、种植技术的双重革新。'
      },
      {
        text: '目前，碧莲盛旗下34家直营院部已全部普及不剃发植发技术，截至2021年10月，不剃发植发手术量已突破10000例，平均毛囊种植数量2200单位，涉及毛囊数量更是超过2000万，这是中国植发行业的里程碑时刻，它标志着中国植发行业正式进入不剃发时代。'
      },
      {
        Imag: `${url}/JournalismInfo/5/3.png`
      },
      {
        text: '随着新消费时代的到来，新消费人群对美的需求越来越强烈，植发并不能满足脱发患者日益多元化的需求，养发、护发的需求也将催生巨大的市场。对此，周宗贵认为，“未来对于脱发的治疗，手术和非手术一定是并驾齐驱的，甚至非手术还要超过手术，因为有很多人对手术有恐惧心理，非手术相对更简单，更容易接受，市场也更广阔。”'
      },
      {
        text: '随着新消费时代的到来，新消费人群对美的需求越来越强烈，植发并不能满足脱发患者日益多元化的需求，养发、护发的需求也将催生巨大的市场。对此，周宗贵认为，“未来对于脱发的治疗，手术和非手术一定是并驾齐驱的，甚至非手术还要超过手术，因为有很多人对手术有恐惧心理，非手术相对更简单，更容易接受，市场也更广阔。”'
      },
      {
        text: '苏林表示，此次获奖是对碧莲盛品牌实力的认可，未来，不剃发植发、高品质服务、优秀的医护团队，仍然是碧莲盛的底气所在。同时，碧莲盛也将再接再厉，不断积极进取、开拓创新，用不断升级的技术，为用户提供更加专业的医疗服务。'
      }
    ]
  },
  {
    title: '不忘初心，勇于担当，碧莲盛荣获企业社会责任行业典范奖',
    date: '发布时间：2022-01-14',
    list: [{
        text: '1月14日，由数央公益、数央网联合众多媒体共同主办的第十一届中国公益节在上海圆满落幕。碧莲盛植发凭借在履行企业社会责任方面的杰出表现，以及多年来助力公益事业获得的良好公众评价，赢得第十一届中国公益节评委会一致认可，荣获“2021年度企业社会责任行业典范奖”。'
      },
      {
        Imag: `${url}/JournalismInfo/4/1.png`
      },
      {
        text: '中国公益节设立于2011年，是国内首个由大众媒体联袂发起的以“公益”命名的节日。活动旨在弘扬公益精神，倡导公益行为，搭建多方深度对话、合作沟通的平台。历经十一年的探索和实践，中国公益节已经成为中国公益慈善领域最具影响力的年度盛事。作为植发行业最具影响力的头部品牌，碧莲盛在不断聚焦公益讲座、免费毛囊检测、专家免费问诊、定期赠送养护产品等公益板块的同时，还积极探索企业社会责任未来发展的新方向，并取得了良好的成效。此次在中国公益节斩获大奖，与碧莲盛强大的品牌责任理念和坚持不懈的公益实践探索有着必然的联系，是社会各界对碧莲盛的高度认可与嘉奖。'
      },
      {
        Imag: `${url}/JournalismInfo/4/2.png`
      },
      {
        text: '“在做企业的同时，我认为自己承担着很大的社会责任。企业的发展壮大，离不开社会各界的认可和支持，我会坚持社会效益与企业效益齐头并进，坚持诚信为本、造福社会，做一名有爱心的企业家。”尤丽娜董事长是这样想的，也是这样做的，在经营企业的过程中，她时刻不忘关心弱势群体，常怀感恩之心，倾心回报社会。'
      },
      {
        text: '新冠疫情已经爆发两年，虽然已经得到了有效控制，却仍在不少地方出现反复，在此期间，碧莲盛勇担社会责任，迅速驰援，为抗击疫情做出努力。湖北疫情，碧莲盛第一时间响应国家号召，成立百万专项基金，向疫情前线捐赠口罩、防护服和专业医疗设备；河北疫情，碧莲盛第一时间采购专业防疫物质，驰援南宫疫情前线；西安疫情，碧莲盛迅速组织专业医护团队加入核酸采样队，协助居委会帮市民进行核酸检测。'
      },
      {
        text: '我国经济虽发展迅速，大部分地区的学生都能拥有一个美好的校园生活，但仍有贫困地区的学校里还没有设立专门的图书馆，学生们缺少查阅资料、阅读课外书、丰富知识的途径。据了解，2021年儿童节，碧莲盛携手鸿基金共同举办山西公益行活动，为河津县上市小学捐赠了“爱的图书馆”，为每个学生捐赠一个“爱的四季包”，并认领5名品学兼优的学生进行一对一帮扶资助。'
      },
      {
        text: '之后，碧莲盛又联合腾讯公益、鸿基金共同发起了“携手碧莲盛，一起做好事”公益活动，号召每一位员工和就诊顾客奉献爱心共同帮助贫困地区的困境留守儿童，为其捐款，保障生活贫苦的留守儿童的物质生活。同时，在北京、南昌、太原等地开展“99公益日”社区毛发健康义诊活动，帮助社区居民全面了解自己头发的健康状况，并针对每位居民的头发情况给出专属的建议和解决方案，获得了社区居民的一致好评。'
      },
      {
        text: '为了帮助更多的脱发患者，碧莲盛成立了千万植发公益基金，免费帮助生活上有困难的脱发患者植发。来自西部贫困地区的农村发友白龙，曾经因热水造成1/2头皮损伤并伴随大面积脱发，多年来对自己形象极不自信，后来碧莲盛免费为他植发，帮助他重拾对生活的信心，这一案例也曾经在世界植发大会上展示，瘢痕性秃发领域的临床应用效果得到了与会专家的一致认可，并入选世界植发大会案例中心。'
      },
      {
        text: '据了解，碧莲盛在2020年推出了不剃发植发技术，是中国植发行业首次将不剃发这一概念正式落地，彻底改变了植发行业先剃发后植发的传统，直接提取原生长发种植，实现了毛囊提取、种植技术的双重革新，避免了长发剃短的痛苦，完美保护个人隐私，恢复周期大大缩短，在保证术后效果的同时，满足立即变美的愿望，开创了植发技术的全新篇章，被行业专家誉为“植发行业的5G技术革新”。'
      },
      {
        text: '“不剃发植发技术的成熟，对于疤痕植发也有十分重大的意义。对于疤痕脱发患者来说，传统植发仍然需要等待一年，才能看到最终的效果，这对他们来说，还是有些慢，不剃发植发的出现，则满足了他们快速恢复美好形象的需求。”尤丽娜告诉记者，碧莲盛近期将推出新的公益计划，在全国范围内征集疤痕脱发患者，用最好的不剃发植发技术，最好的医护团队以及最优惠的政策，帮助他们快速恢复美好的形象，重拾对生活的自信与向往。'
      }
    ]
  },
  {
    title: '不剃发植发重塑行业天花板，碧莲盛当选植发行业领军企业',
    date: '发布时间：2022-01-09',
    list: [{
        text: '2021年是“十四五”规划的开局之年，是向第二个百年奋斗目标进军的开启之年，为更好地推动中国经济高质量发展，1月8日至9日，由《环球时报》社和中国企业网联合主办的“第五届博鳌企业峰会暨2021亚洲经济大会”在海南博鳌亚洲论坛国际会议中心隆重举行。'
      },
      {
        Imag: `${url}/JournalismInfo/6/1.png`
      },
      {
        text: '本届论坛以“中国企业变与新”为主题，邀请国家领导人、有关部委领导、专家学者、500强企业领袖和各行业优秀代表等出席，旨在助力中国企业向好、向上发展，凝聚新时期发展共识，搭建交流与合作平台，探寻未来发展新路径。碧莲盛作为植发行业的杰出代表受邀参会，并获得组委会一致提名当选“2021年度植发行业领军企业”，同时凭借不剃发植发技术创新的行业影响力，荣获“2021年度医美行业科技创新奖”。'
      },
      {
        text: '本次大会邀请有关部委领导、经济界知名人士、知名研究机构、专家学者、媒体领袖组成评审委员会，并围绕“创新力、影响力、贡献力、发展力、经营力”五大评选标准进行候选人的资质考核，着力挖掘并表彰了一批新经济时代各个行业的优秀企业代表。'
      },
      {
        text: '自2005年成立以来，碧莲盛在植发技术创新领域积极探索多年，自主研发的不剃发植发实现了整个行业的突破，满足了新经济时代新消费群体的新需求，给评审委员们留下了深刻印象，被一致赞为“在植发行业有引领作用的企业”，并授予“2021年度植发行业领军企业”、“2021年度医美行业科技创新奖”两项大奖。'
      },
      {
        text: '活动现场，碧莲盛董事长尤丽娜女士在接受采访时表示，“口碑积累对于企业发展至关重要，好口碑能赢得消费者的信任，而好口碑更来自于消费者的认可。碧莲盛的技术和服务换来了口口相传的有效传播，也提升了品牌价值。”'
      },
      {
        text: '据了解，碧莲盛成立于2005年，是全行业第一家全部采用无痕植发技术的连锁植发机构，连续十三年参加世界植发大会，向世界展示中国植发技术。2020年推出的不剃发植发技术，是中国植发行业首次将不剃发这一概念正式落地，被行业专家誉为“植发行业的5G技术革新”。'
      },
      {
        text: '此前，第五届中国毛发移植大会发布了《毛发移植规范》团体标准，尤丽娜女士正是起草人之一。该《规范》指出，植发主要是毛囊提取和种植两个维度，毛囊提取采用传统的FUT、FUE技术，毛囊种植则主要是镊子种植、种植笔和即插即种三种方式。无论是毛囊提取还是种植，剃发都是植发的第一步。碧莲盛推出的不剃发植发则实现了植发技术的革命性突破，彻底改变了植发行业“先剃发后植发”的传统，实现了毛囊提取、种植技术的双重革新。'
      },
      {
        text: '随着新消费时代的到来，新消费人群对美的需求越来越强烈，对质量的要求越来越高，他们不仅要通过植发满足爱美之心，也在个性化、定制化、隐私化等方面提出了更高的要求。不剃发植发的出现，无疑满足了这些新需求，也必将引领起植发行业新的发展趋势。'
      },
      {
        text: '尤丽娜告诉记者，“研发不剃发技术的首要原因就是满足更多新消费人群对美的追求。与传统植发相比，不剃发植发可以缩短消费者求美的过程，缩短恢复期和尴尬期。我认为，通过满足需求侧的要求，做好供给侧的不断完善和进化是行业头部企业责任的体现。”'
      },
      {
        text: '目前，碧莲盛旗下33家直营院部已全部普及不剃发植发技术，截至2021年10月，不剃发植发手术量已突破10000例，平均毛囊种植数量2200单位，涉及毛囊数量更是超过2000万，这是中国植发行业的里程碑时刻，它标志着中国植发行业正式进入不剃发时代。'
      },

      {
        text: '“回顾2021年，我们也遇到了很多挑战，但是碧莲盛坚持创新、坚持改革，战胜了挑战，取得了业绩的稳步增长。放眼“十四五”，展望新征程，挑战前所未有，时势总体有利，机遇依然在我。不剃发植发、高品质服务、优秀的医护团队，仍然是碧莲盛的底气所在。”尤丽娜如是说。'
      }

    ]
  },
  {
    title: '碧莲盛董事长尤丽娜荣膺中国经济十大杰出女性',
    date: '发布时间：2021-12-28',
    list: [{
        text: '2021年是“十四五”规划的开局之年，是向第二个百年奋斗目标进军的开启之年，为更好地推动中国经济高质量发展，12月28日，由中国亚洲经济发展协会、《环球时报》社和中国经济新闻联播网联合主办的“2021中国经济高峰论坛暨第十九届中国经济人物年会”在上海隆重举行。'
      },
      {
        Imag: `${url}/JournalismInfo/7/4.png`
      },
      {
        text: '本届论坛以“新经济、新格局、新征程”为主题，邀请了诸多政商领袖、专家学者与主流媒体参会，旨在全面探讨和解读中国企业创新驱动战略，助推中国经济转型升级。作为植发行业的杰出代表，碧莲盛董事长尤丽娜女士受邀参会，并获得组委会一致提名当选“2021中国经济十大杰出女性”。'
      },
      {
        text: '本次大会邀请国务院国资委、中国社科院、中共中央政策研究室等有关部门领导和经济界知名人士担任评审委员会委员，并围绕“创新力、影响力、贡献力、发展力、经营力”五大评选标准进行候选人的资质考核，着力挖掘并表彰了一批新经济时代各个行业的优秀企业家代表。'
      },
      {
        text: '尤丽娜女士自2005年创立碧莲盛以来，在植发技术创新领域积极探索多年，自主研发的不剃发植发实现了整个行业的突破，满足了新经济时代新消费群体的新需求，给评审委员们留下了深刻印象，被一致赞为“植发技术创新的行业天花板”！'
      },
      {
        text: '为了表彰尤丽娜女士多年来辛苦耕耘、自主创新，为植发行业健康发展作出的卓越贡献，本次大会授予她“2021中国经济十大杰出女性”称号。值得一提的是，此次共同当选的企业家还有长城汽车总经理王凤英、碧桂园联席主席杨惠妍、海尔集团执行副总裁谭丽霞、立讯精密董事长王来春等知名品牌的杰出代表。'
      },
      {
        Imag: `${url}/JournalismInfo/7/1.png`
      },
      {
        text: '活动现场，尤丽娜女士受邀出席了“中国经济企业家创新论坛”，围绕“新征程上的管理创新之路”进行了主题演讲。尤丽娜指出，“口碑积累对于企业发展至关重要，好口碑能赢得消费者的信任，而好口碑更来自于消费者的认可。碧莲盛的技术和服务换来了口口相传的有效传播，也提升了品牌价值。”'
      },
      {
        text: '据了解，碧莲盛成立于2005年，是全行业第一家全部采用无痕植发技术的连锁植发机构，连续十三年参加世界植发大会，向世界展示中国植发技术。2020年推出的不剃发植发技术，是中国植发行业首次将不剃发这一概念正式落地，被行业专家誉为“植发行业的5G技术革新”。'
      },
      {
        text: '此前，第五届中国毛发移植大会发布了《毛发移植规范》团体标准，尤丽娜女士正是起草人之一。该《规范》指出，植发主要是毛囊提取和种植两个维度，毛囊提取采用传统的FUT、FUE技术，毛囊种植则主要是镊子种植、种植笔和即插即种三种方式。无论是毛囊提取还是种植，剃发都是植发的第一步。碧莲盛推出的不剃发植发则实现了植发技术的革命性突破，彻底改变了植发行业“先剃发后植发”的传统，实现了毛囊提取、种植技术的双重革新。'
      },
      {
        text: '随着新消费时代的到来，新消费人群对美的需求越来越强烈，对质量的要求越来越高，他们不仅要通过植发满足爱美之心，也在个性化、定制化、隐私化等方面提出了更高的要求。不剃发植发的出现，无疑满足了这些新需求，也必将引领起植发行业新的发展趋势。'
      },
      {
        Imag: `${url}/JournalismInfo/7/2.png`
      },
      {
        text: '尤丽娜告诉记者，“研发不剃发技术的首要原因就是满足更多新消费人群对美的追求。与传统植发相比，不剃发植发可以缩短消费者求美的过程，缩短恢复期和尴尬期。我认为，通过满足需求侧的要求，做好供给侧的不断完善和进化是行业头部企业责任的体现。”'
      },
      {
        Imag: `${url}/JournalismInfo/7/3.png`
      },
      {
        text: '目前，碧莲盛旗下33家直营院部已全部普及不剃发植发技术，截至2021年10月，不剃发植发手术量已突破10000例，平均毛囊种植数量2200单位，涉及毛囊数量更是超过2000万，这是中国植发行业的里程碑时刻，它标志着中国植发行业正式进入不剃发时代。'
      },
      {
        text: '目前，碧莲盛旗下33家直营院部已全部普及不剃发植发技术，截至2021年10月，不剃发植发手术量已突破10000例，平均毛囊种植数量2200单位，涉及毛囊数量更是超过2000万，这是中国植发行业的里程碑时刻，它标志着中国植发行业正式进入不剃发时代。'
      }
    ]
  },
  {
    title: '碧莲盛携手鸿基金启动“爱的翅膀·助力成才计划',
    date: '发布时间：2021-03-20',
    list: [{
        text: '3月20日，第七届中国品牌创新发展论坛在北京会议中心隆重召开。此届论坛站在2021年全球政治经济格局下，围绕中国企业现状与前景等诸多议题，进行分析探讨，为新时代的企业发展提供有价值的品牌思路和变革方向。'
      },
      {
        text: '作为植发行业的唯一企业代表，碧莲盛受邀出席本次论坛。凭借良好的信誉口碑、对植发行业技术发展的突出贡献，碧莲盛荣获“2020中国品牌榜·诚信品牌奖”。'
      },
      {
        Imag: `${url}/JournalismInfo/8/1.png`
      },
      {
        text: '论坛现场鸿基金公益环节，碧莲盛董事长尤丽娜作为公益爱心人士，受邀上台与陈伟鸿和众企业家共同启动了“爱的翅膀——助力成才计划”，跟随鸿基金一起努力践行公益力量，帮助更多的人！未来碧莲盛尤丽娜女士会进入鸿基金公益，用满满的爱心帮助贫困地区留守儿童健康成长。据了解，鸿基金由央视主持人陈伟鸿发起，此次与碧莲盛的强强合作，将会持续关注关爱贫困留守儿童，为他们送去希望，助力他们梦想的实现。'
      },
      {
        Imag: `${url}/JournalismInfo/8/2.png`
      },
      {
        text: '碧莲盛植发成立于2005年，是一家致力于毛发健康产业的全国直营连锁机构。十七年来精钻植发技术，是国内唯一一家连续十三年参加世界植发大会，向世界展示中国植发技术的企业。目前，碧莲盛已在全国32座城市开设了直营分院，拥有100余位植发领域知名专家，400多位专业医护团队，为近35万发友解决脱发困扰。'
      },
      {
        text: '据了解，碧莲盛董事长尤丽娜是我国植发行业中少数具有行医资格和临床经验的创始人，在“医生”和“企业家”两个身份上完美切换。她凭借自己的专业素养和管理能力，带领碧莲盛稳步向前，发展成为全行业当之无愧的“头部”企业。同时，医生的身份让她更懂患者的困难和需求，更好地肩负起她和碧莲盛的社会责任。'
      },
      {
        Imag: `${url}/JournalismInfo/8/3.png`
      },
      {
        text: '为了帮助更多的脱发患者，碧莲盛成立了“千万植发公益基金”，通过举办公益讲座、开展大型公益活动、提供免费毛囊检测、专家免费问诊服务、定期赠送养护产品，无偿为发友提供医疗援助，帮助众多贫困发友重拾信心，为更多求美者提供科学、安全、诚信、专业的医疗服务，不断推动中国植发行业的健康发展。'
      },
      {
        text: '“在做企业的同时，我认为自己承担着很大的社会责任。企业的发展壮大，离不开社会各界的认可和支持，我会坚持社会效益与企业效益齐头并进，坚持诚信为本、造福社会，做一名有爱心的企业家。”尤丽娜董事长是这样想的，也是这样做的，在经营企业的过程中，时刻铭记着“安全”二字，同时她非常的关心弱势群体，常怀感恩之心，倾心回报社会。'
      }

    ]

  }, {
    title: '专家热议“新消费”：多措并举促进新业态新模式健康发展',
    date: '发布时间：2021-03-10',
    list: [

      {
        text: '近日，在人民网《人民会客厅》两会特别版——《两会时刻》系列访谈栏目上，中国消费者协会副秘书长王振宇，十三届全国人大代表、内蒙古自治区扎鲁特旗东萨拉嘎查党支部书记吴云波，商务部国际贸易经济合作研究院流通与消费研究所博士梁威，碧莲盛集团董事长尤丽娜，锅圈食汇联合创始人李欣华，淘宝主播、美one合伙人李佳琦，58同城副总裁李子健围绕“新业态新模式引领‘新消费’”主题展开讨论，共同解读新业态新模式下的新型消费现阶段的特点，以及未来的机遇与挑战。'
      },
      {
        Imag: `${url}/JournalismInfo/9/1.png`
      },
      {
        tilte: '嘉宾做客《人民会客厅》 热议“新消费”'
      },
      {
        text: '节目嘉宾一致认为，随着互联网经济的兴起，数字化浪潮正在带动新业态经济蓬勃发展。以网络购物、新零售等为代表的新型消费规模不断扩大，不仅有效保障了居民日常生活需要，同时也推动了国内消费恢复，促进了经济企稳回升。各类新业态新模式的涌现，在不断改变着国民消费习惯的同时，需及时关注侵害消费者权益的新问题。嘉宾建议继续加强“新消费”领域相关法律法规和制度建设，深化包容审慎和协同监管，健全服务标准体系，规范市场秩序。'
      },
      {
        smallTile: '聚焦“新消费”趋势 “互联网+”助力新业态新模式发展'
      },
      {
        text: '2020年9月，国务院办公厅印发《关于以新业态新模式引领新型消费加快发展的意见》，并作出一系列重要部署。今年政府工作报告也重点提出，要运用好“互联网+”，推进线上线下更广更深融合，发展新业态新模式，为消费者提供更多便捷舒心的服务和产品。'
      },
      {
        Imag: `${url}/JournalismInfo/9/2.png`
      },
      {
        tilte: '中国消费者协会副秘书长王振宇'
      },
      {
        text: '不论对我国经济的内部大循环，还是对百姓自身的消费体验而言，新业态新模式引领新消费都有着重大意义。访谈中，中国消费者协会副秘书长王振宇表示：“以‘互联网+’模式为引导的线上线下共同发展新业态模式，是高科技在消费领域的进一步体现。消费不仅是科技进步的成果检验，也会对科技和生产做进一步的指导。”除此之外，新业态新模式可以更加凸显以消费者为中心的理念。王振宇表示，从消费者的角度而言，新消费领域在传统领域之上，更加关注消费者的新需求及个性化需求，给消费者提供更加精准，更加便利的服务。'
      },
      {
        Imag: `${url}/JournalismInfo/9/3.png`
      },
      {
        tilte: '十三届全国人大代表、内蒙古自治区扎鲁特旗东萨拉嘎查党支部书记吴云波'
      },

      {
        text: '实际上，新业态新模式不仅在推动城市的快速发展，也在广大的农村落地生根，推动了县乡消费潜力。内蒙古自治区扎鲁特旗东萨拉嘎查党支部书记吴云波表示：“随着消费水平、人民收入的提高及社会的进步，县乡线上消费能力也在提升。在电商平台、互联网、大数据的作用下，县乡消费能力能够快速就进入到新模式、新连接当中。这对实现乡村振兴，挖掘线上消费有很大的帮助。”'
      },
      {
        smallTile: '跟紧“新消费”节奏服务业纷纷“上线”开启新实践'
      },
      {
        Imag: `${url}/JournalismInfo/9/4.png`
      },
      {
        tilte: '淘宝主播、美one合伙人李佳琦'
      },
      {
        text: '作为互联网经济的代表，直播电商在2020年蓬勃发展，规模不断扩大，引领了互联网经济新潮流。访谈中，知名主播李佳琦称：“在行业愈加激烈的竞争下，大众对直播带货的新鲜感有限，长久的流量只能以品质为内核，通过持续不断的内容创新，提升内容品质，提供给用户最新的内容和最有趣的体验。”'
      },
      {
        Imag: `${url}/JournalismInfo/9/5.png`
      },
      {
        tilte: '58同城副总裁李子健'
      },
      {
        text: '除了直播电商，各界企业也在积极探索如何在新业态新模式的大背景下获得持续性发展。58同城副总裁李子健表示：“我们线上的订单去年一年有将近10倍的增长，这表明整个社会对服务线上化的接受程度在飞速增加。”谈及企业近年为实践新业态新模式发展而做出的改变，李子健称：“当前，消费者的家政服务需求在不断升级，且不断地倒推着供给侧的改革。在这个大背景下，58同城在两年前推出了‘到家精选’项目，旨在为消费者提供更高品质的服务。”'
      },
      {
        Imag: `${url}/JournalismInfo/9/6.png`
      },
      {
        tilte: '锅圈食汇联合创始人李欣华'
      },
      {
        text: '事实上，新业态经济带来的契机并不限于新兴行业。许多传统行业也在互联网经济的推动下，探索发展机遇，力求更高质量地满足消费者对线上消费、“到家消费”的需求。对此，锅圈食汇联合创始人李欣华表示：“火锅是餐饮行业里最易标准化和零售化的产品，因此催生了很多企业对火锅食材零售化的探索。除了持续为消费者提供高性价比的食材之外，我们将发力物流、仓储等核心的中枢板块，也将加大数据化和智能化的投入水平，引进高端人才，对整个供应链进行全链条的管理，提高效率并降低成本。”'
      },
      {
        smallTile: '构建“新消费”环境各方协力用“新监管”助力新业态'
      },
      {
        text: '在新业态新模式的簇拥下，经济发展不断彰显着新的活力。但与此同时，诸多新的挑战也随之涌现。在新消费环境下，政府监管部门和各界企业仍需相互配合，完善行业标准，共创更加优质的市场环境。'
      },
      {
        Imag: `${url}/JournalismInfo/9/7.png`
      },
      {
        tilte: '碧莲盛集团董事长尤丽娜'
      },
      {
        text: '针对近年快速发展但乱象丛生的医美行业，碧莲盛集团董事长尤丽娜称：“目前，医美行业三非乱象依然存在，即非医疗场所、非专业医生和非合格产品。在政府加大监管力度的同时，建立专业的人才培养体系，用专业的医生、过硬的技术，保障消费者的安全权益，让我们的手术患者安心变美、安全变美。”'
      },
      {
        Imag: `${url}/JournalismInfo/9/8.png`
      },
      {
        tilte: '商务部国际贸易经济合作研究院流通与消费研究所博士梁威'
      },
      {
        text: '由于经营生态的良莠不齐，新业态经济未来的健康发展要建立在完善的监管体系的基础上。而监督、监管，以及良好业态的形成，不仅仅需要监管部门的严格规范，更需要企业高度自律，以及社会各界的力量的共同监督。'
      },
      {
        text: '展望未来新业态新模式的发展，商务部国际贸易经济合作研究院流通与消费研究所博士梁威称：“新消费后续发展应该是一个创新发展和融合发展相结合的图景。是市场主导、政府促进的新的发展愿望。”'
      }
    ]
  },

  {
    title: '潮流大秀视听盛宴隆重开幕 碧莲盛时尚盛典名流汇聚尽显奢华',
    date: '发布时间：2020-11-26',
    list: [{
        text: '11月26日，中国知名时尚医美品牌碧莲盛在杭州国大雷迪森广场酒店莲花厅隆重举办西湖时尚盛典。来自全国的时尚潮流达人、文化娱乐行业名人、时尚相关行业的意见领袖以及知名行业专家共约一百五十人盛装出席，宾朋满坐，高尚典雅。丰富的视听盛宴及热烈的社交媒体互动传播，在疫情减缓经济复苏之际，给与会者以及追求时尚潮流的爱美人士带来了全新的时尚体验和激情。'
      },
      {
        Imag: `${url}/JournalismInfo/10/1.png`
      },
      {
        tilte: '杭州西湖盛典现场'
      },
      {
        text: '西湖时尚盛典，通过美仑美奂的经典弦乐、芭蕾、歌剧以及时装秀等高雅艺术形式，激发与唤起人们对美的理解与追求，倡导更健康、更安全的时尚生活方式和医美技术。'

      },

      {
        text: '本次盛典由碧莲盛董事长尤丽娜女士隆重开启。活动现场，有三十余位来自各个领域的意见领袖、知名艺人、时尚达人盛装出场，通过红毯走秀、时尚拍摄、高级酒会、精美晚宴等形式进行了交流与互动，分享与呈现时尚、美丽、健康、自信的生活方式。'
      },
      {
        Imag: `${url}/JournalismInfo/10/2.png`
      },
      {
        tilte: '碧莲盛集团董事长尤丽娜'
      },
      {
        text: '西湖时尚盛典之际，杭州武林广场国大广场的楼群齐齐点亮，通过霓虹灯、大幅面LED屏幕及楼群的灯光组合，演绎碧莲盛NHT超级时空时尚灯光秀，精彩纷呈，为盛典喝彩，为美丽加油。'
      },
      {
        Imag: `${url}/JournalismInfo/10/3.png`
      },
      {
        tilte: '杭州国大城市广场'
      },
      {
        text: '在感恩节之际，尤丽娜女士率领碧莲盛的高管团队，与全国32家医院的院长以及20名顶级植发专家悉数亲临现场，济济一堂，与时尚潮流达人一道，感恩彼此一路同行，共同努力用技术创造对美的欣赏、诠释与追求。'
      },
      {
        text: '“今天，我们在杭州举办西湖时尚盛典是碧莲盛品牌发展与业务成长的又一个重要里程碑。”碧莲盛董事长尤丽娜女士表示，“杭州是中国经济和文化最发达的地区之一，是互联网经济和人才最活跃的地方，也是生活方式及健康时尚的代表性领军市场。我们在这里开设直营分院整整五年了，一直不遗余力地为客户提供高品质、安全、便捷的植发服务。中国市场有近2亿的脱发患者或潜在需求，更有数亿爱美爱时尚的潮流年轻人群，我们一直致力于技术创新，通过领先的NHT（No Haircut Technology）技术升级，和更多时尚内容的打造，实现品牌升级，为更多爱美的人带来惊喜，让更多人享受时尚和品质生活。疫情终将过去，未来更加美好。”'
      },
      {
        Imag: `${url}/JournalismInfo/10/4.png`
      },
      {
        tilte: '碧莲盛集团副总经理师晓炯'
      },
      {
        text: '著名电影人李海华说，对于碧莲盛呈现的时尚艺术与生活方式结合的盛典印象非常深刻，令人惊叹！对于美的欣赏和追求是人类永无止境的刚需，也是快乐的源泉和生活的根本。'
      },
      {
        text: '演员葛恒瑞、美妆达人景力、时尚达人金洋Jyan、陶陶星仔，美妆博主王子ssoa、快手视频红人怪兽，抖音视频红人查查和张张等数十位艺人和KOL，皆盛妆出席，风采云集，炫酷劲飒。'
      },
      {
        text: '时尚源自美好 倡导健康美丽'
      },
      {
        text: '随着中国成为世界第二大经济体，物质生活水平快速稳步提升的中国人，对美好生活的向往和需求，比以往任何时候都更加迫切。据国家卫健委发布的调查显示，我国脱发人群已超过2.5亿，平均每6个人中就有1个人有脱发症状，脱发人群逐年增多，并呈现年轻化的趋势。'
      },
      {
        Imag: `${url}/JournalismInfo/10/5.png`
      },
      {
        tilte: '碧莲盛集团coo张琦'
      },
      {
        text: '爱美之心，人皆有之。越来越多的人渴望拥有完美的形象，并有能力去追求和提升。碧莲盛的初衷就是创造美好，期待美好，“满足人民对美好生活的向往、对美好形象的追求”，尤丽娜女士认为，所有人都有追求“美”的权利，希望可以通过技术创造美，帮助到更多的脱发人群，让他们因为形象的美好而有更高的幸福感。'
      },
      {
        text: '同时，消费者变美的过程，一定要基于安全的基础之上。目前的植发市场，在医疗器械、合规规范以及操作资质等方面，有着诸多的监管困难与品质风险，消费者面临选择的时候，一定要选择安全值得信赖的技术和品牌。'
      },
      {
        Imag: `${url}/JournalismInfo/10/6.png`
      },
      {
        Imag: `${url}/JournalismInfo/10/7.png`
      },
      {
        text: '持续创新突破 引领技术风潮'
      },
      {
        text: '今年8月，碧莲盛率先推出备受行业关注的NHT不剃发（No haircut technology）植发技术，该技术所具备的优势、所带来的便利，得到了行业专家与广大消费者的一致认可。相比传统植发技术需要大面积剃发的现状而言，NHT不剃发技术可以真正做到提取、种植全程不剃发，完美避免了传统植发过程中剃发对消费者个人形象的短期影响。'
      },
      {
        text: '16年来，碧莲盛经历了从技术追随者到自主创新技术引领者的角色转变，在植发行业凭借创新的技术、精细的器械和优质的服务脱颖而出，体现了中国充满活力与创新的民营企业的强大生命力和勃勃生机。'
      },
      {
        Imag: `${url}/JournalismInfo/10/8.png`
      },
      {
        text: '追求精致完美 实现品牌升级'
      },
      {
        text: '继高速和辉煌的16年发展，碧莲盛迎来了新的业务发展阶段。碧莲盛医院网络已经全面覆盖中国的一二线城市，并且还在迅速扩张。随着医院、医生和专业医护人员的迅速拓展，高效的管理团队和服务体系对碧莲盛的长远来说显得尤为重要。'
      },
      {
        Imag: `${url}/JournalismInfo/10/9.png`
      },
      {
        Imag: `${url}/JournalismInfo/10/10.png`
      },
      {
        text: '尤丽娜女士表示，“碧莲盛在专业领域注重不断技术创新的同时，更会在医院品牌体系、客户服务体系、品质管理体系和人材培训体系等方面，不断深耕细作强化内功，在推动业务快速成长和市场的纵深开拓的同时，进一步夯实碧莲盛品牌的未来发展之路。”'
      },
      {
        text: '据悉，未来几年碧莲盛将投入巨额资金用于技术研发和品牌升级，精益求精，实现产品的更新迭代与技术创新，为市场提供更加丰富的方案选择。同时，碧莲盛也会在人才引进、人才培养和国际技术交流方面，进一步提升总体运营和管理水平，让消费者享受更时尚、更安全、更便捷的健康服务与体验。'
      },
      {
        text: ''
      }

    ]
  },

  {
    title: '斩获两项大奖，碧莲盛成为第七届中国行业影响力品牌峰会焦点',
    date: '发布时间：2020-08-30',
    list: [{
        text: '8月30日，由中国品牌创新发展工程主办的第七届中国行业影响力品牌峰会，在北京会议中心圆满落幕，央视著名主持人陈伟鸿主持了本次峰会。'
      },
      {
        text: '作为植发行业的唯一代表，碧莲盛受邀出席本次峰会。凭借良好的信誉口碑、对植发行业技术发展的突出贡献，碧莲盛入围2020行业影响力品牌榜，并荣获植发行业“诚信品牌奖”，创始人兼董事长尤丽娜荣获植发行业“领军人物奖”。'
      },
      {
        Imag: `${url}/JournalismInfo/11/1.png`
      },
      {
        tilte: '碧莲盛董事长尤丽娜巅峰对话发言，左二'
      },
      {
        smallTile: '规范透明发展，塑造诚信品牌'
      },
      {
        text: '近年来，颜值消费成为很多年轻人追求的消费热潮，这也催生了植发市场的爆发。但是目前的植发市场，仍然存在着低价诱导、虚报毛囊数量、收费标准不透明等问题，“植发三天速成班”等行业乱象频频登上热搜，这让很多脱发患者望而却步。'
      },
      {
        Imag: `${url}/JournalismInfo/11/2.png`
      },
      {
        tilte: '碧莲盛coo张琦领奖，右一'
      },
      {
        text: '在此背景下，碧莲盛植发始终坚守合规底线，多年来一直坚持签约植发的“透明医疗”模式，术前与发友签订协议，公开价格、明确收费项目和标准、明确毛囊数量，通过法律的形式，保障发友的手术安全、毛囊成活率、术后效果，保障发友手术前、手术中、手术后的合法权益，“以身作则”引领行业合规发展。'
      },
      {
        text: '碧莲盛创始人尤丽娜告诉记者，“企业的发展要有底线思维，不能违规经营，不能假大空，要务实，从实际出发，什么能做什么不能做一定要清晰，牢牢把握住安全第一，只有这样，方能谋得企业的行稳致远”。            '
      },
      {
        smallTile: '专业品质服务，打造优质口碑	'
      },
      {
        text: '“发友至上，以人为本，诚信医疗”是碧莲盛植发从创立之初便定下的发展理念，一切从发友的需求出发，围绕“专属、贴心、主动”三大核心诉求，从术前问诊到方案设计，再到手术过程和术后服务，制定了统一规范的就诊流程和服务标准，确保全国32家分院都能让发友享受到统一标准、高品质的服务。'
      },
      {
        text: '在不断完善服务体系的同时，碧莲盛还建立了发友权益保障体系，术前签订协议、术中全程监控、术后追踪服务，实现了价格无忧、安全无忧、术后无忧、诚信无忧、隐私无忧。同时，碧莲盛先后与中国人保(PICC）、中国平安保险达成了战略合作，更进一步保障发友的合法权益。'
      },
      {
        text: '与其他行业不同，在植发这一重度垂直服务领域，发友选择植发机构非常严谨，口碑的好坏直接影响发友的决策。据相关统计，碧莲盛植发大部分的客户，来自于发友之间的口碑传播，这与碧莲盛不断完善服务体系、发友权益保障体系，在发友间积累了强大的口碑是分不开的。'
      },
      {
        smallTile: '坚守医疗本质，成就领军人物	'
      },
      {
        text: '据了解，碧莲盛创始人尤丽娜不仅是一名具有执业资格证书的植发医生，还是中国民营连锁植发行业中，参加ISHRS世界植发大会次数最多的会员，并于2017年成为中国第一个入驻世界植发大会案例中心的医生。此外，碧莲盛还连续12年出席世界植发大会，并多次受邀发表植发技术演讲。'
      },
      {
        text: '得注意的是，尤丽娜的医生出身为碧莲盛植发带来了不一样的医疗理念，碧莲盛发展的每一步都始终坚守医疗本质。尤丽娜对医生团队要求极高，从综合素质、责任心、毕业院校、从业经历等多维度考察，通过特有的传帮带模式培训，以及长期的实践，在专业的考核合格之后方能持证上岗。同时，已在行业领跑的碧莲盛仍然不断完善革新技术，在技术和人才方面的投入从不吝啬。随着最新NHT不剃发技术的诞生，更是将世界植发技术标准推向一个全新的高度。'
      },
      {
        Imag: `${url}/JournalismInfo/11/3.png`
      },
      {
        tilte: '碧莲盛副总经理师晓炯，左一'
      },
      {
        text: '对于履行企业社会责任，尤丽娜十分重视，成立了“植发公益基金”，通过举办公益讲座、开展大型公益活动、提供免费毛囊检测、专家免费问诊服务、定期赠送养护产品，无偿为发友提供医疗援助，帮助众多贫困发友重拾信心。'
      },
      {
        text: '突发疫情给整个中国环境尤其是企业带来不少影响，碧莲盛也受到冲击。“我们在全国的32家直营分院不能开业，给企业确实带来压力。但是，国家给了民营企业最大的帮助，补贴社保、减免税收等政策，支持我们专心工作、专研技术，留住更多人才，有序复工复产。同时，国家遇到困难，碧莲盛也义不容辞。疫情爆发后，我们快速成立专项基金，采购专用医疗设备、口罩、防护服等，分期分批赠送给疫情严重区域。”尤丽娜介绍说。'
      },
      {
        text: '疫情是把双刃剑，给企业带来压力的同时，也激发企业潜能。尤丽娜指出，在互联网高度发展的时代，线上线下融合仍然是大趋势，开展业务一定要线上线下联动，提升企业抵挡抗风险能力。同时，企业的发展要有底线思维，在医疗行业更要规范严谨，认认真真做好技术和服务，在国家大力推动双循环的背景下，企业要认真做好区域精细化运营，从大而全模式转变为精耕区域用户市场的差异化运营模式，通过技术的不断革新，渠道下沉，让植发像理发一样安全便捷。'
      },
      {
        text: '日后，碧莲盛也将继续秉承“回归医疗，以人为本”的理念，坚持“发友至上，诚信为本”的原则，为更多发友带来科学、安全、诚信、专业的医疗服务，不断推动中国植发行业的健康发展。'
      }

    ]
  },

  {
    title: '创新 匠心 仁心，植发行业赋能美好生活',
    date: '发布时间：2020-08-18',
    list: [{
        Imag: `${url}/JournalismInfo/12/1.png`
      },
      {
        text: '随着“颜值时代”的来临，追求美丽和展示美丽的效应使得医美行业实现快速发展。作为医美行业的细分产业，植发业兼具医疗的刚需和医美的消费升级属性，正迎来黄金发展时期。'
      },
      {
        text: '有数据显示，我国植发业2016年的市场规模约为57亿元，到2019年时已增长到约163亿元，增幅约为186%，预计到2020年底，市场规模将突破200亿元。'
      },
      {
        text: '面对巨大市场，植发行业的头部企业如何实现创新驱动、助力行业高质量发展？近日，碧莲盛植发技术研究院（以下简称碧莲盛）董事长尤丽娜携植发业最新前沿技术亮相“创新引领变革 科技赋能美好生活——NHT植发技术发布会”。在采访中，尤丽娜表示，植发行业应秉持创新驱动、匠心筑梦、仁心惠民的初心，助力健康中国、赋能美好生活。'
      },
      {
        smallTile: '创新驱动，技术变革引领行业前行'
      },
      {
        text: '“近几年，我国植发行业的用户保持60%-80%的增幅，市场很大。但要想真正引领行业发展，需要依靠技术和服务的‘双轮驱动’。”在这场由中国改革报•政企智库支持、碧莲盛举办的发布会上，尤丽娜介绍了碧莲盛16年来从追随到自主创新植发技术的发展过程。'
      },
      {
        text: '据介绍，碧莲盛在技术迭代的路上已走过五个技术代级，这五个技术分别是SHT无痕微针、BHT超精细多维高密、AFHT美学培固、PLCHT超精定位毛囊焕活，NHT（No haircut technology）微针不剃发技术。此次发布会向消费者推出的，正是碧莲盛最新的植发技术——NHT微针无痕不剃发技术。'
      },
      {
        text: '相比传统植发技术需要大面积剃发的现状而言，NHT不剃发技术可以真正做到提取种植全程不剃发，完美避免剃发对发友形象的短期影响，真正做到了即刻美丽无痕。据碧莲盛临床医疗数据显示，来到碧莲盛咨询、植发的多为20-30岁之间的年轻人，其中26岁左右的人群植发需求最为强烈，他们面临着就业、交友等多个人生重要时刻，对不剃发的技术需求更为迫切。'
      },
      {
        text: '娜介绍，NHT微针不剃发技术拥有三个优势，一是在植发时移植区发型不变，仅对毛囊供区进行均匀分散式提取，无需剃发即可实现隐形植发过程，彻底告别以往“先剃发再植发”的传统方式；二是采用碧莲盛最新技术提取，毛囊选取更精准、更均匀。整个过程微创无痛, 提取的毛囊完整度更高、无损伤，使术后恢复快不留痕迹；三是可根据毛发生长的最终形态选取合适的细软或粗壮毛发移植，术后效果更加自然，保留原有头发的长度和浓密,不影响个人形象。'
      },
      {
        text: '对于碧莲盛勇于突破、不断进取的精神，业界一直给予高度评价。复旦大学附属华山医院皮肤科手术室主任、植发中心主任吴文育表示，多年来碧莲盛一直用技术创新给行业带来惊喜，推动植发行业不断前行。我非常看好NHT不剃发植发技术，它让发友能够更快回归工作和生活。'
      },
      {
        text: '尤丽娜深信，服务为本、技术为根，只有掌握核心技术才能根深蒂固，才是对发友最好的回馈，也才能在激烈的竞争中立于不败之地。'
      },
      {
        smallTile: '匠心筑梦，植发从来没有捷径可走'
      },
      {
        text: '头发的缺失，影响的不仅仅是容貌，对患者的自信心更是一种巨大伤害。而植发是目前解决脱发问题最直接、最有效的方式。对于患者而言，医疗机构是否具备合法合规资质、医护团队是否具有相应医疗技能是首要关心的问题。'
      },
      {
        text: '据了解，一名患者一般需要移植上千个毛囊单位，整个手术过程中需要多名医护人员相互配合，用时少则四五个小时，多则十几个小时。尤丽娜说，植发从来没有捷径可走，不存在速成。植发手术必须由正规执业医师才可以操作，而一名正规专业的植发医师需要数年去培养，需要多年经验才能主刀。'
      },
      {
        text: '为此，碧莲盛建立了严格的选聘考核制度，对医护人员进行长期专业的培训，组建了一支技术精湛的医护团队，目前已有超过100位实战派植发医生，专业医护团队更是超过400人，其中有行业内唯一一位享受国务院特殊津贴的专家。'
      },
      {
        text: '为了把技术提上来、把速度练起来，碧莲盛的医护人员都经历了非常艰苦的学习过程。尤丽娜表示，一个优秀的植发医生至少需要500台手术的经验积累。而如今，经过大量的实践积累，碧莲盛医生们在手术过程中的速度、流畅度、美感度令人震撼。采植疾如风，精妙夺天功，很难想象是人手工操作完成的，这就是匠心精神的体现。'
      },
      {
        text: '精心的治疗、显而易见的术后效果在发友之间口口相传。随着时间的推移,碧莲盛实实在在的植发技术让咨询电话及上门求助的患者络绎不绝。'
      },
      {
        text: '“我觉得碧莲盛每一步都走得很实，这么多年来我始终对团队强调，我们夯实技术，把精力都用在练‘内功’上，‘好’字要让发友说出来。”尤丽娜说。'
      },
      {
        smallTile: '仁心惠民，赋能美好生活需要'
      },
      {
        text: '庞大的市场需求造就了快速发展的植发市场，同时也引发了诸多行业乱像，产品以次充好、无从医资格、收费乱定价、虚假宣传、隐患操作等现象突出，这些都对市场产生了不良影响。为了抢夺市场，有些企业展开了激烈的广告战和价格战。据统计，国内植发行业广告投入常年居高不下，每年广告投入高达30多亿元。'
      },
      {
        text: '在尤丽娜看来，靠广告和价格不能赢得真正的口碑。在发展过程中，碧莲盛稳扎稳打，在开店策略上，采用了“一城一店”直营连锁模式，把资源精力都投入到满足用户需求上，通过标准化、规范化、诚信化服务运营体系，不断强化在市场的核心竞争力。目前，碧莲盛拥有32家直营连锁机构，并在持续布局中。'
      },
      {
        text: '2018年，碧莲盛获华盖资本5亿元战略投资。投资方表示，碧莲盛提供的植发服务在精细度和专业性方面具有极好的口碑，未来碧莲盛完全有实力将业务进一步扩展到国内更多的城市乃至国际市场。'
      },
      {
        text: '在资金使用方面，碧莲盛目前研发投入占到营收的1/4，医生专业技术学习培训、考察、锻造再提升等方面投入非常大。在尤丽娜看来，将资金更多用在技术研发、对患者关心、给患者创造良好医疗条件，才是真正地为患者服务，才是真正的医者仁心。'
      },
      {
        text: '近年来，碧莲盛积极参与各项社会公益事业，定期举办公益讲座等活动为广大发友提供免费咨询和养护指导。与此同时，碧莲盛还成立了“碧莲盛千万植发基金”，持续帮助贫困脱发人群重拾信心，并建立完善的信息反馈系统。截至目前，“碧莲盛千万植发基金”已帮助千余人。'
      },
      {
        text: '“植发行业之所以存在并发展，就是因为有一部分人渴望拥有完美的形象。”尤丽娜最后说，我提倡美好、期待美好，而且我创办碧莲盛的初衷就是满足人民对美好生活的向往、对美好形象的追求，我认为所有人都有追求美的权利，我希望可以帮助到更多不富裕的脱发人群，让他们因为形象的美好而有更高的幸福感。'
      }

    ]
  },

  {
    title: '2020国际质造节圆满落幕，碧莲盛植发荣获两大奖项',
    date: '发布时间：2020-07-29',
    list: [{
        text: '7月29日，以“质·创未来”为主题的2020国际质造节在北京圆满落幕。碧莲盛植发凭借强大的技术创新能力，良好的公众综合评价和品牌影响力，与TCL、科大讯飞、飞鹤、浪潮信息、西门子医疗、碧水源、红豆集团、德力西电气等公司，一起荣获“2020匠心质造奖-杰出企业奖”，碧莲盛植发创始人尤丽娜荣获“2020匠心质造奖-杰出人物奖”。'
      },
      {
        Imag: `${url}/JournalismInfo/13/1.png`
      },
      {
        text: '如今，“质造”的含义正在发生深刻的变化，已经不单纯代表着质量的好坏，更是技术服务、品牌创意、智能创新、产品功能的全方位升级。在此背景下，2020国际质造节邀请了具有全球视野的专家学者、商业领袖、创新经营者等“质造”品牌头部力量，多种形式展示质造成果，传递质造精神，探寻企业高品质成长路径。'
      },
      {
        smallTile: '垂直深耕16年，微针技术领跑行业前沿'
      },
      {
        text: '作为我国最早成立的植发机构之一，碧莲盛植发早在2005年就成立了第一家植发医院。在创始人尤丽娜的带领下，碧莲盛的技术团队将研究重心放在了FUE植发技术上，经过深入了解，尤丽娜认为当时流行的种植器械宝石刀和种植笔，都存在着本身固有的一些弊端和缺陷。'
      },
      {
        text: '2007年，碧莲盛摒弃传统种植器械（宝石刀、种植笔），颠覆性创新以微针为载体器械。之后十余年，碧莲盛一直致力于微针植发技术的研发和升级，先后推出了SHT无痕微针植发技术、BHT2.0超精细多维高密微针植发技术、AFHT美学培固微针植发技术、PLCHT超精定位毛囊焕活微针植发技术，可以满足发友的不同需求，并根据发友的不同特点制定精细化的毛发健康方案。'
      },
      {
        text: '对于植发手术而言，具备合法合规资质的医生同样关键，手术时长、毛囊成活率、植发效果都和操作者的熟练程度息息相关。据悉，碧莲盛建立了严格的选聘考核制度，对医护人员进行长期专业的培训，组建了一支技术精湛的医护团队，目前已有超过100位实战派植发医生，专业医护团队更是超过400人，其中有行业内唯一一位享受国务院特殊津贴的专家。'
      },
      {
        text: '尤丽娜表示，“企业只有紧跟世界先进技术水平，在研发上不断投入，才可以拥有核心技术，才能保证自己的技术具有世界一流水平。”在竞争激烈的市场中，碧莲盛正是凭借长久技术积累，才能在中国乃至全球植发技术领域取得领先地位。'
      },
      {
        text: '据了解，碧莲盛是行业唯一一家代表中国连续12年受邀参加世界植发大会，展示中国植发技术的植发医院。如今，碧莲盛已经在全国32个城市开设了直营连锁分院，形成了以省会为中心辐射全国的植发医疗体系，成为植发行业最具影响力的民族品牌。'
      },
      {
        smallTile: '以发友利益为中心，不断推动中国植发技术发展'
      },
      {
        text: '对于脱发患者而言，最关心的莫过于植发效果，而种植器械对植发效果有着很大的影响。随着植发技术的发展，种植器械也不断升级，传统植发一般采用宝石刀打孔种植，口径一般为0.8mm、1.0mm、1.2mm、1.3mm。碧莲盛微针采用全进口定制专用微针，其硬度、长度、精细度最适合亚洲人头皮及毛囊结构，口径极小（将口径进一步升级到0.6mm-0.8mm），穿透性强，创面极小，出血量极少，恢复极快，存活率更高，效果更自然。'
      },
      {
        text: '据介绍，碧莲盛目前正在申请微针实用新型专利、国家医疗器械证书，该技术碧莲盛率先掌握，并即将成为全行业唯一双认证专用种植微针。'
      },
      {
        text: '传统植发一般为多人协作种植，存在着多次接触污染毛囊、不协调、不统一、不专注等问题，影响最终的植发效果。为此，碧莲盛自主研发了单人种植-即插即种技术，手术全程由一名种植师一步精准完成，精准掌握头皮弹性以及种植深浅程度，保证种植的疏密度、深浅度、协调度、美感度。值得注意的是，碧莲盛还是中国植发行业唯一一家采用单人种植技术的植发机构。'
      },
      {
        text: '未来，碧莲盛将继续加大研发投入，在技术、硬件、标准三个领域，紧紧围绕广大发友的服务需求，始终坚持“诚信为本、发友至上”核心理念，不断为患者带来最好的植发服务和体验，推动中国植发行业的健康可持续发展。'
      }
    ]
  },

  {
    title: '《2022 福布斯中国颜值经济白皮书》发布碧莲盛当选植发行业杰出品牌',
    date: '发布时间：2022-11-06',
    list: [{
        text: '11月6日，2022 福布斯生活秋季智享会暨《2022福布斯中国颜值经济白皮书》（以下简称《白皮书》）发布会在上海举行，福布斯中国高层、各大美业企业代表、知名投资人、行业专家、媒体代表等嘉宾莅临现场，共话“颜值经济”的价值与机遇，探索行业下一个风口。作为植发行业的唯一代表，碧莲盛受邀出席并斩获“植发行业杰出品牌”大奖。-杰出企业奖”，碧莲盛植发创始人尤丽娜荣获“2020匠心质造奖-杰出人物奖”。'
      },
      {
        Imag: `${img}/report/43/1.png`,
      },
      {
        tilte: '福布斯代表钱厚琳女士为碧莲盛颁奖'
      },
      {
        text: '福布斯生活作为生活爱好者与创新者的跨界交流平台，始终关注着新一代消费者对个性化生活方式的创造力，以及当今时代对于生活美学的创新表达。在这一个主流消费者迁移、品牌策略改变和营销方式重构场景的“颜值经济4.0”时代，福布斯生活见证着大众对于新生活态度的真实投射。'
      }, {
        text: '《白皮书》由福布斯中国联合福科无限联合发布，设置“国际视野洞察”、“国内行业洞察”及“新时代颜值经济洞察和案例分析”几大板块，立足于宏观及微观的不同视角，分析颜值经济行业已然趋于成熟的美韩两国的发展轨迹，我国渠道发展、消费者需求和行业监管的现状，以及细分行业优秀案例的洞察及分析，碧莲盛、麦德龙中国等知名品牌作为行业案例呈现。'
      },
      {
        text: '在“颜值经济”兴起的今天，从行业发展的趋势来看，“颜值经济”行业的竞争逐渐趋于多样化、个性化、健康化的方向，同时，消费者将会更加重视品牌形象和产品口碑，流量消费也将进一步发展。如何顺应市场趋势做到差异化，已经成为诸多品牌关心的话题。'
      },
      {
        text: '活动现场，碧莲盛医疗技术带头人、副主任医师周宗贵与麦德龙中国副首席执行官陈志宇共同出席了圆桌对话，该对话由福布斯U30精英获得者、Apede MOD创始人林清清主持，围绕“生活新态度引领新消费”话题，对行业发展的趋势与壁垒进行了深入探讨，各自分享了顺应市场趋势做到差异化的独特见解。'
      },
      {
        Imag: `${img}/report/43/2.png`,
      }, {
        tilte: '碧莲盛医疗技术带头人、副主任医师周宗贵（右一）'
      },
      {
        text: '周宗贵表示，“消费者年轻化态势明显，女性价值凸显，是行业发展的一个重要趋势。在未来的市场竞争当中，谁能率先把握女性市场，谁就能走在行业前端。所以，我们碧莲盛行业内第一个推出不剃发植发技术，在注重外观的女性消费者中已经形成良好的口碑。”'
      },
      {
        text: '周宗贵进一步表示，“打造毛发全产业链生态，形成植养闭环，是植发行业发展的另一个重要趋势。对于植发行业而言，复购率低一直是各机构在运营中需要解决的一个重要问题，养发的发展一定程度上弥补了植发复购率低的弊端。未来几年，养发会成为植发行业不可或缺的重要一环。”'
      },
      {
        Imag: `${img}/report/43/3.png`,
      },
      {
        text: '据了解，碧莲盛成立于2005年，是一家致力于植发技术的自主研发和临床应用的全国直营连锁机构。18年来精钻植发技术，连续13年参加世界植发大会，向世界展示中国植发技术。目前，碧莲盛在全国开设了40余家直营机构，拥有100余位实战派医生，800余位专业医护团队，其中，主任/副主任医师11位，主治医师占比达六成，在整个行业的占比是最高的。'
      },
      {
        text: '早在2020年，碧莲盛推出了自主研发的不剃发植发技术，彻底改变了“先剃发后植发”的传统，直接提取原生长发种植，避免了长发剃短的痛苦，完美保护个人隐私，恢复周期大大缩短，满足立即变美的愿望，将植发技术提升到一个新高度。据统计，截至今年10月，碧莲盛不剃发植发手术台数接近三万台，女性不剃发植发消费占比超过六成。'
      },
      {
        text: '周宗贵表示，“安全监管与技术发展一定要顺应行业监管以及消费者的需求，医疗机构将全面实现合规化、专业化，市场的选择也将倾向于头部植发机构。未来，碧莲盛将进一步做好有效管理与合规经营，建立数智化的运营体系，共同推进行业向规范化、健康化、精细化发展。”'
      }
    ]
  },
  {
    title: '碧莲盛获评“21世纪全国创新公司50强” 不剃发植发价值凸显',
    date: '发布时间：2021-12-15',
    list: [{
        text: '2021年12月15日，由南方财经全媒体集团指导，21世纪创新资本研究院等联合主办的“21世纪商业模式高峰论坛(2021)暨21世纪创新资本研究院•潮经济对话”正式召开，高峰论坛上公布了《21世纪全国创新公司50强(2021)》榜单。碧莲盛植发凭借行业领先的不剃发植发技术和高品质服务，获评2021年度21世纪全国创新公司50强。'
      },
      {
        Imag: `${img}/report/44/1.png`,
      },
      {
        text: '据了解，该奖项从差异度、启示度和重要度三大层面评估企业的创新实力，要求企业的创新实力在同领域、或者同模式里引发的变化剧烈程度要足够打破常规，同时还能对他人的创新思维予以启发，并解决大多数人所面临的重要问题。为从全国大量企业中筛选出前50强，21世纪创新资本研究院特邀创新、经济、资本等领域专业顾问，为参评企业打分。'
      },
      {
        text: '碧莲盛成立于2005年，多年来在植发技术创新领域积极探索，自主研发的不剃发植发实现了整个行业的突破，给评审委员们留下了深刻印象，被活动主办方及评委组一致赞为“碧莲盛创新植发技术引领植发行业发展”，因而获评2021年度21世纪全国创新公司50强。'
      },
      {
        text: '此前，传统植发最大的痛点就是植发前需要剃掉头发，对个人的形象和隐私造成不小的影响。基于多数脱发患者不想剃发的需求，碧莲盛多次开会探讨如何解决植发先剃发的难题。'
      },
      {
        text: '经过多年潜心研发和临床实践，碧莲盛于2020年8月在深圳正式发布了不剃发植发技术，将植发行业中一直被提及却始终难实现的不剃发概念正式落地，彻底改变了植发行业“先剃发再植发”的传统，在引领植发行业全新变革的同时，将国内植发技术推向了一个全新高度，不断引领植发行业向更高标准探索。'
      },
      {
        text: '碧莲盛创始人兼董事长尤丽娜表示，与传统植发相比，不剃发植发可以缩短消费者求美的过程，缩短恢复期和尴尬期。另一方面，不剃发植发符合行业发展趋势，新消费人群对美的需求越来越强烈，对质量的要求越来越高。'
      },
      {
        text: '目前，碧莲盛已在旗下33家直营院部全部普及不剃发植发技术。数据显示，截至2021年10月，不剃发植发手术量已突破10000例，平均种植毛囊数量2200单位，涉及毛囊数量更是超过2000万，这是中国植发行业的里程碑时刻，它标志着中国植发行业正式进入不剃发时代。'
      },
      {
        text: '尤丽娜表示，长远来看，植发行业正在进入一个高质量发展的新阶段。接下来，技术创新和高品质服务将成为关键。'
      }

    ]
  },
  {
    title: '10000台手术实力“保卫”发际线——碧莲盛不剃发植发技术助推“颜值经济”',
    date: '发布时间：2021-11-01',
    list: [{
        text: '为了避免术后尴尬期，发友小郑选择通过不剃发植发手术进行发际线调整。对比传统植发，不剃发植发手术的难度较大。凭借丰富的经验与默契的配合,仅花4个小时植发手术就顺利完成。由此，碧莲盛不剃发植发手术第10000台手术成功完成。'
      },
      {
        text: '10000台真实案例对于碧莲盛植发而言是其在不剃发领域成功探索的见证，对于植发行业而言，更是我国植发业迈向不剃发时代的重要里程碑超越。'
      },
      {
        smallTile: '技术创新：新需求催生“不剃发”技术'
      },
      {
        text: '近年来，随着经济水平的提高，大众审美需求也不断提升，在消费升级的驱动下，医美行业迅速发展。植发作为医美产业的细分板块，在庞大新消费群体和强大诊疗需求的加持下，发展潜力巨大。'
      },
      {
        text: '据了解，毛发移植手术是从20世纪70年代开始引入我国的，当时行业内是以FUT（毛囊单位头皮条切取术）为主流技术。2001年，FUE（毛囊单位钻取术）技术诞生，随后传入国内。当时，植发仍然比较小众，少有关注。'
      },
      {
        text: '如今，受高压,熬夜和饮食不规律问题等多种因素的影响，脱发现象正在日益普遍化和低龄化。'
      },
      {
        text: '国家卫健委2019年调查数据显示，我国脱发人数超2.5亿，平均每六人中就有一人脱发，其中男性约1.63亿，女性约0.88亿。其中，30岁前脱发的人群占比高达84%，比上一代人的脱发年龄提前了20年，呈现明显的低龄化趋势。'
      },
      {
        text: '因此，从2016年起，中国植发行业开始蓬勃发展，当年市场规模为58亿元。到了2020年，中国植发市场规模已达到134亿元。方正证券曾在研报中表示，到了 2030年，我国植发医疗服务市场规模有望达到756亿元。近日，医美平台美呗发布《2021当代青年植发数据洞察报告》显示，2021年1至9月，美呗植发交易人数翻倍提升，2年来增至2.5倍。其中，成交的“90后”植发人群占比超5成。'
      },
      {
        Imag: `${img}/report/45/1.png`,
      },
      {
        tilte: '碧莲盛植发创始人兼董事长尤丽娜'
      },
      {
        text: '对此，碧莲盛植发董事长尤丽娜指出，新消费群体对品质、品牌的追求更加看重，因此能够匹配人们真实需求，解决人们生活痛点的产品或技术未来会有较大的增长空间。就植发行业而言，传统植发需要“剃发”的痛点显然让很多想要植发的消费者望而却步。'
      },
      {
        text: '的确，传统植发做完手术后，需要包着一块纱布走出医院，之后还要经历漫长的恢复和生长期，回到正常状态至少要9个月，期间不免经历各种尴尬与不便，女性患者更加难以接受。'
      },
      {
        Imag: `${img}/report/45/2.png`,
      },
      {
        tilte: '碧莲盛植发工作人员为发友进行毛囊检测'
      },
      {
        text: '“目前植发市场上包装的植发技术品类繁多，但是，从本质上来说，植发技术主要是毛囊提取和种植两个维度：毛囊提取是传统的FUT、FUE两种方式，毛囊种植则主要是镊子种植、种植笔和即插即种，无论采用哪一种技术，剃发都是植发的第一步。”碧莲盛济南医疗负责人解释说，碧莲盛推出的不剃发植发技术，则颠覆了行业传统，实现了毛囊提取、种植技术的双重革新。'
      },
      {
        text: '其实早在2014年，尤丽娜便正式提出研究和探索不剃发技术。直至去年，不剃发技术已经非常成熟，碧莲盛正式在深圳召开发布会，将植发行业中一直被提及却始终难实现的不剃发概念正式落地。发布会当天，6名患者当场体验不剃发植发技术，专家、媒体代表现场见证不剃发植发的全过程和即可效果。'
      },
      {
        text: '截至2021年10月，不剃发植发手术量已突破10000例，平均毛囊种植数量2200单位，涉及毛囊数量更是超过2000万。'
      },
      {
        smallTile: '医者仁心：回归医疗本质“谱写”不忘初心'
      },
      {
        text: '先进技术得益于专业的医师加持。医师团队据介绍，碧莲盛植发机构中副主任、主任医师占比大于10%，高于市场平均水平（主流机构占比约为5%-10%），且所有医生均具备五年以上临床经验。'
      },
      {
        text: '碧莲盛植发医疗技术负责人蒋学在采访中指出，不剃发植发的真正的壁垒并不是方法和设备，而在于是否拥有大量受过训练的植发医生。长发提取长发种植的手术，非常依赖医生水平。从剃发到不剃发，技术难度迅速增加，对医生体力和精力的要求更高，手术时间也更长。碧莲盛深知医生的价值，也十分重视医护团队的培养。'
      },
      {
        text: '“对于医疗企业而言，需要以医为先，医生才是企业的核心价值。植发属于医美行业的细分板块，更考验着医生的技术水平和审美能力。”尤丽娜补充说。'
      },
      {
        text: '尤丽娜本人就具有医师执业资格，碧莲盛植发也因此成为国内植发行业少有的、创始人具有医师执业资格的植发服务机构。因此，尤丽娜格外重视医疗团队，也更能体会到患者的心情与不安。'
      },
      {
        Imag: `${img}/report/45/3.png`,
      },
      {
        tilte: '碧莲盛植发医生为发友个性定制设计方案'
      },
      {
        text: '成立17年来，尤丽娜带领碧莲盛坚持履行企业社会责任，用实际行动诠释了“医生忍心”深刻内涵。'
      },
      {
        text: '今年6月，碧莲盛携手鸿基金共同举办山西公益行活动，为山西省一所小学捐赠了“爱的图书馆”，用图书为小学生丰富精神世界；7月，河南遭遇特大暴雨灾情，碧莲盛郑州分院为市民提供临时休息场所，并安排留守医生和护士为市民包扎伤口等；9月，碧莲盛植发联合腾讯公益发起“携手碧莲盛，一起做好事”公益活动，将捐赠的资金通过鸿基金帮助欠发达地区的困境留守儿童；10月，“1024程序员节”现场开展义诊，并走进金山集团等企业帮助广大程序员了解自己头发的健康状况……'
      },
      {
        text: '此前，碧莲盛还发起“碧莲盛千万植发基金”，用于救助渴望通过植发改变形象重获自信，但确实有难处，无法支付植发费用的发友。目前，该基金已累计救助300余人。发友小白就是“碧莲盛千万植发基金”救助的患者之一。小白两岁时因烧伤造成1/2头皮损伤并伴随大面积脱发，多年来对自己形象极不自信，后来小白家人找到碧莲盛，经过碧莲盛植发专家的不懈努力，小白顺利完成难度较大的疤痕植发，重拾了对生活的信心。'
      },
      {
        text: '“首先，小白年龄太小，治疗不当会给孩子造成心理阴影，因此合适的毛发缺损介入时机尤为关键；其次，孩子毛发在抽取的过程中需要特别细致的观察瘢痕区头发的移植密度，还要特别注意深度，切记影响孩子的神经主干。”见到小白露出自信的笑容，尤丽娜很开心。'
      },
      {
        text: '当然，碧莲盛凭借多年来为公益事业发展所做出的贡献和成效，在众多参与企业中脱颖而出，曾获中国公益节两项大奖。'
      },
      {
        text: '就植发行业而言，回归医疗本质不仅体现在人为关怀上，也体现在自身价值的输出。而价值输出的最好方法就是培养好医生，给优秀医生、特别是技术先进的医生创造更多机会。'
      },
      {
        text: '近日，碧莲盛推出了全新的价格标准体系，从毛囊提取和种植的角度重新定义植发技术，制定统一的价格标准，让广大消费者一目了然。或许，此举将成为植发行业回归医疗本质、实现规范有序发展的一个标杆！'
      }
    ]
  },
  {
    title: '以品质促发展，碧莲盛荣获2021国际品质节“杰出质造品牌奖”',
    date: '发布时间：2021-06-24',
    list: [{
        text: '6月24日，以“品质驱动增长”为主题的2021国际品质节在北京香格里拉酒店圆满落幕，植发行业头部品牌碧莲盛受邀出席，并凭借强大的技术创新能力、完善的服务保障体系与良好的消费者口碑，与伊利集团、格力电器、海信集团、苏宁易购、红豆集团、58集团等知名品牌一起荣获“2021杰出质造品牌奖”。'
      }, {
        Imag: `${img}/report/46/1.png`,
      },
      {
        text: '作为中国品质领域的顶级盛会，国际品质节由数央网、数央公益联合国内众多媒体共同发起，活动集合“品质”品牌的头部力量，以独立演讲、高端对话、成果展示、深度分享等形式全面展示品质成果，传递品质精神，探寻企业高品质成长路径，助推中国企业实现品质升级，共同引领中国经济高质量发展。'
      },
      {
        smallTile: '碧莲盛植发创始人兼董事长尤丽娜'
      },
      {
        text: '如今，“品质”的含义正在发生深刻的变化，“品质”已经不单纯是指产品质量的好坏，而是核心技术、服务体系、产品功能全方位的升级。'
      },
      {
        text: '据了解，自2005年成立以来，碧莲盛始终将植发技术的创新研发当做发展的核心方向，先后推出了SHT、BHT2.0、AFHT等多项植发技术，在更契合中国人毛发特点的同时，根据消费者的不同特点制定精细化的毛发健康方案。'
      },
      {
        Imag: `${img}/report/46/2.png`,
      },
      {
        tilte: '碧莲盛植发副主任医师尹梓贻'
      },
      {
        text: '随着居民消费需求升级，高品质消费日益成为消费者新的消费需求。目前，植发技术虽然日益成熟，但是并不能满足新消费群体提出的新需求，他们在个性化、定制化、隐私化等方面都提出了更高的要求。在这种背景下，碧莲盛于2020年8月正式推出了NHT不剃发植发技术，通过技术创新，解决消费者关心的术后尴尬、隐私问题。'
      },
      {
        text: '碧莲盛董事长尤丽娜表示，“新消费将引导企业加快产品与服务升级步伐，这要求企业要不断的突破传统、突破自我，要随时根据消费者、市场、技术的变化进行调整，这是整个行业转型升级的机遇所在。”'
      },
      {
        text: '今年5月，第五届中国毛发移植大会发布了《毛发移植规范团体标准》，从官方的层面确认了植发技术的五次迭代升级，分别是FUT、镊子种植、种植笔、即插即种、不剃发植发。其中，即插即种、不剃发植发两大技术，碧莲盛都走在了行业前沿，为整个行业的高质量发展树立了品质标杆。'
      }, {
        text: '据了解，NHT不剃发技术是国内植发行业首次将“不剃发”这一概念真正落地，彻底改变了植发行业“先剃发再植发”的传统，长发取、长发种，真正做到了提取、种植全程不剃发，完美避免了传统植发过程中剃发对脱发患者个人形象的短期影响，在引领植发行业技术革新的同时，更是将世界植发技术推向了一个全新高度。'
      },
      {
        smallTile: '顺应新消费趋势，重塑毛发产业生态'
      },
      {
        text: '随着我国经济的快速发展和居民收入水平的不断提高，以传统消费提质升级、新型消费蓬勃兴起为主要内容的新消费快速发展。消费者愈发重视个性化、多样化、品质化的消费体验，特别是这批悄然成为消费主力的“90后”、“00后”年轻人。'
      },
      {
        Imag: `${img}/report/46/3.png`,
      },
      {
        text: '尤丽娜告诉记者，“消费者的需求是不断变化的，企业一定要深入挖掘消费者的需求。植发虽然满足了年轻人的爱美之心，但是还未能满足他们养发固发的消费需求，所以，碧莲盛通过产品延伸，推出了莲主任康疗品牌，构建了毛发健康产业生态。”'
      },
      {
        text: '根据国际著名市场研究机构弗若斯特沙利文的预测，2025年及2030年，医疗养固服务市场将分别占据我国毛发医疗服务市场总量的32.9%及45.3%。也就是说，未来医疗养固服务市场将成为毛发医疗服务市场增长的主要驱动力。 '
      },
      {
        text: '为此，碧莲盛已在积极布局，先后与南京同仁堂、佐今明药业达成合作，共同推出了植物头皮康养中药粉、七宝美髯口服液，并从美国Capillus、韩国生发研究院引进激光活发治疗、美丝沛中胚层治疗、光动力毛发康养，根据脱发患者的性别和病因，提供针对性的毛发健康管理方案，提供高品质的毛发健康服务。'
      },
      {
        text: '与此同时，作为碧莲盛的全资独立品牌，莲主任康疗于2020年正式上市。据悉，莲主任康疗已在全国32个城市布局，建立了全方位的毛发健康管理体系，为脱发患者提供精细化的头皮毛发康疗流程、一对一的专属顾问服务、量身定制的针对性康疗方案，由内而外地改善头皮毛发健康。'
      },
      {
        text: '“新消费、新需求、新趋势，消费市场每年都会发生很多变化，但是，无论市场如何变换，最终都要落到产品服务的品质上来，只要不忘初心，坚守品质，就一定能赢得消费者的信任！”尤丽娜如是说。'
      }
    ]
  },
  {
    title: '碧莲盛不剃发技术斩获2021国际科创节“行业创新典范奖”',
    date: '发布时间：2021-12-08',
    list: [{
        text: '6月24日，以“品质驱动增长”为主题的2021国际品质节在北京香格里拉酒店圆满落幕，植发行业头部品牌碧莲盛受邀出席，并凭借强大的技术创新能力、完善的服务保障体系与良好的消费者口碑，与伊利集团、格力电器、海信集团、苏宁易购、红豆集团、58集团等知名品牌一起荣获“2021杰出质造品牌奖”。'
      }, {
        Imag: `${img}/report/47/1.png`,
      },
      {
        text: '作为科技领域最具影响力的年度盛会之一，国际科创节由数央网、数央公益联合国内众多科技及财经媒体共同发起，旨在通过构建多元、开放的交流与合作平台，聚焦科技最新发展趋势，全面展示科创成果，传递科创精神，为助力科技强国贡献力量。'
      },
      {
        Imag: `${img}/report/47/2.png`,
      },
      {
        Imag: `${img}/report/47/3.png`,
      },
      {
        text: '本次评选，组委会邀请知名研究机构、咨询公司、专家学者、媒体领袖组成评审委员会，从“企业文化、社会贡献、创新能力、经济成长、品牌影响、行业地位”六项考核指标上对参评企业进行严格甄选，每一个奖项都分量极重。'
      },
      {
        text: '碧莲盛成立于2005年，多年来在植发技术创新领域积极探索，自主研发的不剃发植发实现了整个行业的突破，给评审委员们留下了深刻印象，被活动主办方及评委组一致赞为“在植发行业有引领作用的企业”，并授予“行业创新典范”大奖。'
      },
      {
        Imag: `${img}/report/47/4.png`,
      },
      {
        text: '国家卫健委2019年调查数据显示，我国脱发人数超2.5亿，平均每六人中就有一人脱发，其中男性约1.63亿，女性约0.88亿，脱发已经成为一个影响极大的社会问题。同时，随着“颜值经济”时代的到来，新消费群体也对植发提出了更多的要求。'
      },
      {
        text: '碧莲盛董事长尤丽娜告诉记者，在经营碧莲盛的十几年里，她曾经见过几千名脱发患者，他们最大的痛点就是植发前需要剃掉头发，虽然很多人最终还是选择了植发，但是对个人的形象和隐私，还是造成了不小的影响。面对患者的困惑，碧莲盛曾经多次开会探讨如何解决植发先剃发的难题。'
      },
      {
        text: '经过多年潜心研发和临床实践，碧莲盛于2020年8月在深圳正式发布了不剃发植发技术，将植发行业中一直被提及却始终难实现的不剃发概念正式落地，彻底改变了植发行业“先剃发再植发”的传统，在引领植发行业全新变革的同时，将国内植发技术推向了一个全新高度，不断引领植发行业向更高标准探索。'
      },
      {
        text: '尤丽娜表示，研发NHT不剃发技术的首要原因就是满足更多新消费人群对美的追求。与传统植发相比，不剃发植发可以缩短消费者求美的过程，缩短恢复期和尴尬期。另一方面，不剃发符合行业发展趋势，新消费人群对美的需求越来越强烈，对质量的要求越来越高。'
      },
      {
        text: '此前，中国植发大会发布了《毛发移植规范》团体标准，从官方的层面确认了植发技术的五次迭代升级，分别是FUT、镊子种植、种植笔、即插即种、不剃发植发，这可以理解为植发行业的“5G”技术革新。其中，碧莲盛在即插即种、不剃发植发都在了行业前沿，带领行业开启5G黑科技时代，成为行业跨越式发展的“新基建”。'
      },
      {
        text: '据了解，碧莲盛已在旗下33家直营院部全部普及不剃发植发技术，截至2021年10月，不剃发植发手术量已突破10000例，平均种植毛囊数量2200单位，涉及毛囊数量更是超过2000万，这是中国植发行业的里程碑时刻，它标志着中国植发行业正式进入不剃发时代。'
      },
      {
        text: '尤丽娜表示，此次获奖是对碧莲盛科技创新实力的认可，未来，碧莲盛将再接再厉，不断积极进取、开拓创新，将继续立足自身，充分拓宽创新领域，增强自主研发能力，用不断升级的技术，为用户提供更加专业的医学服务，为行业带来更广阔的发展前景。'
      }

    ]
  },
  {
    title: '不负众望 璀璨绽放 碧莲盛获第17届亚洲品牌盛典品牌大奖',
    date: '发布时间：2022-10-01',
    list: [{
        text: '近日，享有亚洲品牌年度“奥斯卡”之称的“第17届亚洲品牌盛典”在海南自贸港举行，该活动由专业品牌价值评价机构Asiabrand发起，中国亚洲经济发展协会、一带一路总商会、东盟-中国工商总会、澳门商报和亚太第一卫视等联合主办。中国知名植发机构碧莲盛受邀出席，并当选中国行业十大领军品牌，碧莲盛董事长尤丽娜当选亚洲十大创新人物。'
      }, {
        Imag: `${img}/report/48/1.jpg`,
      }, {
        text: '活动现场，尤丽娜受邀出席了“企业家健康与企业品牌竞争力”主题论坛。在论坛上，尤丽娜表示，“品牌的建立，离不开扎实的技术实力和管理手段。在技术方面，碧莲盛于2020年推出了自主研发的不剃发植发技术，在此以前，不剃发始终是一个经常被提及，但是却很难实现的概念，碧莲盛的不剃发植发技术，使这个概念第一次被真正落地。”'
      },
      {
        text: '尤丽娜进一步表示，“在管理方面，碧莲盛建立了统一规范的品牌体系、运营体系、培训体系、就诊流程和服务标准，全国所有家分院遵循统一的管理标准，确保全国用户都能享受到品质如一的服务。在医生团队层面，碧莲盛建立了严格的选聘制度、专业考核制度和完善的培训体系，确保每一位医生都有国家颁发的合规资质证书，确保每一台手术操作规范安全。”'
      }, {
        Imag: `${img}/report/48/2.jpg`,
      },
      {
        smallTile: '碧莲盛董事长尤丽娜在论坛发言'
      }, {
        text: '据了解，碧莲盛成立于2005年，是一家致力于植发技术的自主研发和临床应用的全国直营连锁机构。18年来精钻植发技术，连续13年参加世界植发大会，向世界展示中国植发技术。目前，碧莲盛在全国开设了40余家直营机构，拥有100余位实战派医生，800余位专业医护团队，其中，主任/副主任医师11位，在整个行业的占比是最高的。'
      },
      {
        Imag: `${img}/report/48/3.jpg`,
      },
      {
        text: '在品牌经济时代，企业的品牌价值已经不再是一个虚拟的概念，它是企业未来发展能力的真正体现。也是企业乃至地区经济竞争力的综合标志。目前，碧莲盛正在全力推动品牌战略升级，通过成立技术研发中心、重塑服务体系、扩展旗舰医院、研发自有品牌护发产品等，不断深化行业布局，加强上下游资源整合，构建一个集养发、护发、健发、植发于一体的毛发健康产业生态闭环。'
      },
      {
        text: '尤丽娜表示，“未来，碧莲盛将再接再厉，不断积极进取、开拓创新，将继续立足自身，响应消费者需求，迭代升级服务体系，创新研发优质产品。为用户提供更专业的植发、养发服务，为行业带来更广阔的发展前景。”'
      }

    ]
  }
]
