<template>
  <div class="info">
    <div class="info-line"></div>
    <div class="info-box">
      <div class="info-box">
        <div class="top">
          <span style="cursor: pointer" @click="$router.push(`/home`)">首页</span>
          >
          <span style="cursor: pointer" @click="$router.push(`/report`)">权威报道</span>
          >
          <span style="cursor: pointer" @click="$router.push(`/report`)">企业资讯</span>
        </div>
        <div class="title">{{ obj.title }}</div>
        <div class="date">{{ obj.date }}</div>
        <div class="line"></div>
        <div class="list" v-for="(item, index) in obj.list" :key="index">
          <div class="text" v-if="item.text">
            {{ item.text }}
          </div>
          <h5 v-else-if="item.tilte" style="
                text-align: center;
                margin-top: 0;
                font-size: 14px;
                font-weight: 400;
                color: #707070;
              ">
            {{ item.tilte }}
          </h5>
          <div v-else-if="item.smallTile" style="
                text-indent: 33px;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 20px;
                line-height: 30px;
              ">
            {{ item.smallTile }}
          </div>
          <div v-else>
            <img v-if="item.Imag ==
                            'https://static.drlianzhuren.com/brandWeb//JournalismInfo/1/%E5%9B%BE%E7%89%871.png'
                            " src="https://static.drlianzhuren.com/brandWeb//JournalismInfo/1/%E5%9B%BE%E7%89%871.png" alt="" style="width: 60%; margin: 20px 20%" />
            <img v-else :src="item.Imag" alt="" style="width: 100%; margin: 20px 0" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import infoList from "@/utils/data";
export default {
  data() {
    return {
      obj: {
        title: "播种希望 筑梦未来 碧莲盛开展六一爱心助学公益行",
        date: "发布时间:2022-06-01",
        list: [],
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.obj = infoList[this.$route.query.id];
    } else {
      this.obj = infoList[0];
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0;
  },
};
  </script>
  
<style lang="scss"></style>
  
