<template>
  <div class="service">
    <img :src="`${$img}/service/banner.jpg`" class="banner" />
    <div
      class="agreement item"
      :style="{
        'background-image': `url(${$img}/service/agreement.jpg) `,
      }"
    >
      <div class="box">
        <div class="allTitle wow fadeInUp">签约植发 八大协议</div>
        <div class="allTitle-En wow fadeInUp">CONTRACTUAL SERVICES</div>
        <div
          style="display: flex; flex-wrap: wrap"
          class="wow fadeInUp"
          data-wow-delay="0.38s"
        >
          <div class="agreementBox">
            <div class="agreementBox-left">
              <img
                :src="`${$img}/service/agreement/blue/1.png`"
                alt=""
                class="agreementBlue"
              />
              <img
                :src="`${$img}/service/agreement/white/1.png`"
                class="agreementWhite"
                alt=""
              />
            </div>
            <div>
              <div class="agreementBox-text">手术质量</div>
              <div class="agreementBox-text">承诺协议</div>
            </div>
          </div>

          <div class="agreementBox">
            <div class="agreementBox-left">
              <img
                :src="`${$img}/service/agreement/blue/2.png`"
                alt=""
                class="agreementBlue"
              />
              <img
                :src="`${$img}/service/agreement/white/2.png`"
                class="agreementWhite"
                alt=""
              />
            </div>
            <div>
              <div class="agreementBox-text">手术安全</div>
              <div class="agreementBox-text">承诺协议</div>
            </div>
          </div>

          <div class="agreementBox">
            <div class="agreementBox-left">
              <img
                :src="`${$img}/service/agreement/blue/3.png`"
                alt=""
                class="agreementBlue"
              />
              <img
                :src="`${$img}/service/agreement/white/3.png`"
                class="agreementWhite"
                alt=""
              />
            </div>
            <div>
              <div class="agreementBox-text">手术预约金</div>
              <div class="agreementBox-text">承诺协议</div>
            </div>
          </div>

          <div class="agreementBox">
            <div class="agreementBox-left">
              <img
                :src="`${$img}/service/agreement/blue/4.png`"
                alt=""
                class="agreementBlue"
              />
              <img
                :src="`${$img}/service/agreement/white/4.png`"
                class="agreementWhite"
                alt=""
              />
            </div>
            <div>
              <div class="agreementBox-text">术前术后收费</div>
              <div class="agreementBox-text">承诺协议</div>
            </div>
          </div>

          <div class="agreementBox">
            <div class="agreementBox-left">
              <img
                :src="`${$img}/service/agreement/blue/5.png`"
                alt=""
                class="agreementBlue"
              />
              <img
                :src="`${$img}/service/agreement/white/5.png`"
                class="agreementWhite"
                alt=""
              />
            </div>
            <div>
              <div class="agreementBox-text">医疗团队服务</div>
              <div class="agreementBox-text">承诺协议</div>
            </div>
          </div>

          <div class="agreementBox">
            <div class="agreementBox-left">
              <img
                :src="`${$img}/service/agreement/blue/6.png`"
                alt=""
                class="agreementBlue"
              />
              <img
                :src="`${$img}/service/agreement/white/6.png`"
                class="agreementWhite"
                alt=""
              />
            </div>
            <div>
              <div class="agreementBox-text">全国联保</div>
              <div class="agreementBox-text">承诺协议</div>
            </div>
          </div>

          <div class="agreementBox">
            <div class="agreementBox-left">
              <img
                :src="`${$img}/service/agreement/blue/7.png`"
                alt=""
                class="agreementBlue"
              />
              <img
                :src="`${$img}/service/agreement/white/7.png`"
                class="agreementWhite"
                alt=""
              />
            </div>
            <div>
              <div class="agreementBox-text">术后服务</div>
              <div class="agreementBox-text">承诺协议</div>
            </div>
          </div>

          <div class="agreementBox">
            <div class="agreementBox-left">
              <img
                :src="`${$img}/service/agreement/blue/8.png`"
                alt=""
                class="agreementBlue"
              />
              <img
                :src="`${$img}/service/agreement/white/8.png`"
                class="agreementWhite"
                alt=""
              />
            </div>
            <div>
              <div class="agreementBox-text">手术会员权益</div>
              <div class="agreementBox-text">承诺协议</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="allTitle wow fadeInUp">透明医疗 四大承诺</div>
      <div class="allTitle-En wow fadeInUp">TRANSPARENT MEDICINE</div>
      <div class="box wow fadeInUp" data-wow-delay="0.3s">
        <div class="docker">
          <div class="docker-item">
            <div class="docker-left">
              <div>
                <div class="docker-number">NO.1</div>
                <div class="docker-no" style="font-weight: 600">拒绝</div>
                <div class="docker-no">包装医生</div>
                <div class="famousDoctors">
                  <div class="famousDoctors-select">百位名医</div>
                  <div class="famousDoctors-item">持证上岗</div>
                </div>
              </div>
              <img
                :src="`${$img}/service/docker/3.png`"
                alt=""
                class="docker-bigimg"
              />
            </div>
            <div class="docker-right" @click="docker = 1">
              <div
                class="docker-bg"
                :style="{
                  'background-image': `url(${$img}/service/docker/7.png) `,
                }"
              >
                <div class="docker-number">NO.1</div>
                <div class="docker-no" style="font-weight: 600">拒绝</div>
                <div class="docker-no">包装医生</div>
                <div
                  class="docker-open"
                  :class="{ 'right-open': docker >= 1 }"
                ></div>
              </div>
            </div>
          </div>
          <div class="docker-item">
            <div class="docker-left">
              <div>
                <div class="docker-number">NO.2</div>
                <div class="docker-no" style="font-weight: 600">拒绝</div>
                <div class="docker-no">技术混杂</div>
                <div class="famousDoctors">
                  <div class="famousDoctors-select">单人种植</div>
                  <div class="famousDoctors-item">即插即种</div>
                </div>
              </div>
              <img
                :src="`${$img}/service/docker/1.png`"
                alt=""
                class="docker-bigimg"
              />
            </div>
            <div class="docker-right" @click="docker = 2">
              <div
                class="docker-bg"
                :style="{
                  'background-image': `url(${$img}/service/docker/5.png) `,
                }"
              >
                <div class="docker-number">NO.2</div>
                <div class="docker-no" style="font-weight: 600">拒绝</div>
                <div class="docker-no">技术混杂</div>
                <div
                  class="docker-open"
                  :class="{ 'right-open': docker >= 2 }"
                ></div>
              </div>
            </div>
          </div>
          <div class="docker-item">
            <div class="docker-left">
              <div>
                <div class="docker-number">NO.3</div>
                <div class="docker-no" style="font-weight: 600">拒绝</div>
                <div class="docker-no">浑水摸鱼</div>
                <div class="famousDoctors">
                  <div class="famousDoctors-select">长发提取</div>
                  <div class="famousDoctors-item">长发种植</div>
                </div>
              </div>
              <img
                :src="`${$img}/service/docker/2.png`"
                alt=""
                class="docker-bigimg"
              />
            </div>
            <div class="docker-right" @click="docker = 3">
              <div
                class="docker-bg"
                :style="{
                  'background-image': `url(${$img}/service/docker/6.png) `,
                }"
              >
                <div class="docker-number">NO.3</div>
                <div class="docker-no" style="font-weight: 600">拒绝</div>
                <div class="docker-no">浑水摸鱼</div>
                <div
                  class="docker-open"
                  :class="{ 'right-open': docker >= 3 }"
                ></div>
              </div>
            </div>
          </div>
          <div class="docker-item">
            <div class="docker-left">
              <div>
                <div class="docker-number">NO.4</div>
                <div class="docker-no" style="font-weight: 600">拒绝</div>
                <div class="docker-no">价格套路</div>
                <div class="famousDoctors">
                  <div class="famousDoctors-select">诚信为本</div>
                  <div class="famousDoctors-item">公开透明</div>
                </div>
              </div>
              <img
                :src="`${$img}/service/docker/4.png`"
                alt=""
                class="docker-bigimg"
              />
            </div>
            <div class="docker-right" @click="docker = 4">
              <div
                class="docker-bg"
                :style="{
                  'background-image': `url(${$img}/service/docker/8.png) `,
                }"
              >
                <div class="docker-number">NO.4</div>
                <div class="docker-no" style="font-weight: 600">拒绝</div>
                <div class="docker-no">价格套路</div>
                <div
                  class="docker-open"
                  :class="{ 'right-open': docker >= 4 }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item" style="background: #f7f8fc">
      <div class="box">
        <div class="allTitle wow fadeInUp">强强联合 保险保障</div>
        <div class="allTitle-En wow fadeInUp">INSURANCE GUARANTEE</div>
        <div class="Insurance wow fadeInUp" data-wow-delay="0.3s">
          <div class="Insurance-item">
            <img :src="`${$img}/service/1.jpg`" class="Insurance-img" alt="" />
            <div class="Insurance-title">百万医疗责任险</div>
            <div class="Insurance-text">
              与中国平安保险达成战略合作，为植发医护团队提供百万医疗责任险，通过保险的方式，进一步维护广大脱发患者在安全、效果等方面的合法权益，让他们“放心植发，安心变美”。
            </div>
            <div class="Insurance-ellipsis">...</div>
          </div>
          <div class="Insurance-item">
            <img :src="`${$img}/service/2.jpg`" class="Insurance-img" alt="" />
            <div class="Insurance-title">“碧生发”专项保障</div>
            <div class="Insurance-text">
              与由中国太平洋保险达成战略合作，共同推出了一款植发领域创新产品
              “碧生发”，是业内首款跨界合作针对脱发人群定制的保障产品，为植发存活率加一道真正的“安全锁”。
            </div>
            <div class="Insurance-ellipsis">...</div>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="allTitle wow fadeInUp">植发服务流程</div>
      <div class="allTitle-En wow fadeInUp">SERVICE PROCEDURE</div>
      <div class="process box wow fadeInUp" data-wow-delay="0.3s">
        <div
          class="process-item"
          v-for="(item, index) in processList"
          :key="index"
          :style="{
            'background-image': `url(${$img}/service/process/${index + 1}.png)`,
          }"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  mounted() {
    $(".docker-item").click(function () {
      var li_index = $(this).index();
      $(".imgList img").eq(li_index).fadeIn().siblings().fadeOut();
      $(this).animate({ width: 810 }, 200);
      $(this).find(".docker-left").show();
      $(this).find(".docker-right").hide();
      $(this).siblings().animate({ width: 195 }, 200);
      $(this).siblings().find(".docker-left").hide();
      $(this).siblings().find(".docker-right").show();
    });
    $(".docker-item")[0].click();
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
  },
  beforeDestroy() {
    this.wow.stop();
  },
  data() {
    return {
      wow: null,
      docker: 1,
      processList: [
        "毛囊检测",
        "美学设计",
        "手术麻醉",
        "毛囊提取",
        "毛囊分离",
        "精细种植",
        "术后养护",
        "追踪访问",
      ],
    };
  },
};
</script>

<style lang="scss">
</style>