<template>
  <div class="footer">
    <div class="box">
      <div style="display: flex">
        <div class="footer-left">
          <div class="footer-title">直营院部</div>
          <div class="footer-address">
            <span>华北</span>
            <div>
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 0, index: 0 } }"
                >
                  北京碧莲盛
                </router-link></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 0, index: 2 } }"
                  >太原碧莲盛</router-link
                ></span
              >

              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 0, index: 4 } }"
                  >天津碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 0, index: 1 } }"
                  >石家庄碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 0, index: 3 } }"
                  >呼和浩特碧莲盛</router-link
                ></span
              >
            </div>
          </div>
          <div class="footer-address">
            <span>东北</span>
            <div>
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 1, index: 0 } }"
                >
                  沈阳碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 1, index: 1 } }"
                  >大连碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 1, index: 2 } }"
                >
                  长春碧莲盛</router-link
                ></span
              >
            </div>
          </div>
          <div class="footer-address">
            <span>华东</span>
            <div>
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 0 } }"
                  >上海碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 1 } }"
                  >南京碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 2 } }"
                  >苏州碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 3 } }"
                  >杭州碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 4 } }"
                  >宁波碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 5 } }"
                  >合肥碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 6 } }"
                  >南昌碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 7 } }"
                  >济南碧莲盛</router-link
                ></span
              >
              <span style="margin-top: 10px">
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 8 } }"
                  >青岛碧莲盛
                </router-link></span
              >
              <span style="margin-top: 10px">
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 9 } }"
                  >厦门碧莲盛</router-link
                >
              </span>
              <span style="margin-top: 10px">
                <router-link
                  :to="{ path: 'direct', query: { type: 2, index: 10 } }"
                  >泉州碧莲盛</router-link
                ></span
              >
            </div>
          </div>
          <div class="footer-address">
            <span>华中</span>
            <div>
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 3, index: 0 } }"
                  >郑州碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 3, index: 1 } }"
                  >武汉碧莲盛</router-link
                ></span
              >
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 3, index: 2 } }"
                  >长沙碧莲盛</router-link
                ></span
              >
            </div>
          </div>
          <div class="footer-address">
            <span>华南</span>
            <div>
              <span>
                <router-link
                  :to="{ path: 'direct', query: { type: 4, index: 0 } }"
                  >广州碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 4, index: 1 } }"
                  >深圳碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 4, index: 2 } }"
                  >深圳莲空间</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 4, index: 3 } }"
                  >东莞碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 4, index: 4 } }"
                  >珠海碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 4, index: 5 } }"
                  >南宁碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 4, index: 6 } }"
                  >海口碧莲盛</router-link
                ></span
              >
              <span style="margin-top: 10px"
                ><router-link
                  :to="{ path: 'direct', query: { type: 4, index: 7 } }"
                  >佛山碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 4, index: 8 } }"
                  >中山碧莲盛</router-link
                ></span
              >
            </div>
          </div>
          <div class="footer-address">
            <span>西南</span>
            <div>
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 5, index: 0 } }"
                  >重庆碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 5, index: 1 } }"
                  >成都碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 5, index: 2 } }"
                  >绵阳碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 5, index: 3 } }"
                  >贵阳碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 5, index: 4 } }"
                  >昆明碧莲盛</router-link
                ></span
              >
            </div>
          </div>
          <div class="footer-address">
            <span>西北</span>
            <div>
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 6, index: 0 } }"
                  >西安碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 6, index: 1 } }"
                  >兰州碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 6, index: 2 } }"
                  >西宁碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 6, index: 3 } }"
                  >银川碧莲盛</router-link
                ></span
              >
              <span
                ><router-link
                  :to="{ path: 'direct', query: { type: 6, index: 4 } }"
                  >乌鲁木齐碧莲盛</router-link
                ></span
              >
            </div>
          </div>
          <div style="display: flex">
            <div style="margin-right: 16px">
              <img src="../assets/2.jpg" alt="" style="width: 50px" />
              <div
                style="
                  color: #ffffff;
                  opacity: 0.6;
                  font-size: 12px;
                  margin-top: 10px;
                  text-align: center;
                "
              >
                营业执照
              </div>
            </div>
            <div>
              <img
                src="../assets/1.jpg"
                alt=""
                style="width: 50px; height: 35px"
              />
              <div
                style="
                  color: #ffffff;
                  opacity: 0.6;
                  font-size: 12px;
                  margin-top: 10px;
                  text-align: center;
                "
              >
                许可证
              </div>
            </div>
          </div>
        </div>
        <div class="footer-right">
          <div class="footer-title">联系我们</div>
          <div class="footer-phone">总部地址</div>
          <div class="footer-We">北京市 大兴区 西红门鸿坤广场B座3号楼5层</div>
          <div class="footer-phone">服务热线</div>
          <div class="footer-WePhone">400-888-9882</div>
          <div
            class="display-box"
            style="display: flex; justify-content: space-between"
          >
            <div style="display: flex" class="footer-display">
              <div class="footer-code">
                <img
                  src="https://static.drlianzhuren.com/brandWeb/contactUs/wx.png"
                  alt=""
                  style="width: 100%; height: 100%"
                />
              </div>
              <div>
                <div class="footerCode-text">碧莲盛医疗</div>
                <div class="footerCode-text">微信公众号</div>
              </div>
            </div>
            <div style="display: flex" class="footer-display">
              <div class="footer-code">
                <img
                  src="https://static.drlianzhuren.com/brandWeb//xcxCode.jpg"
                  alt=""
                  style="width: 100%; height: 100%"
                />
              </div>
              <div>
                <div class="footerCode-text">碧莲盛医疗</div>
                <div class="footerCode-text">官方小程序</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-line"></div>
      <div class="footerRecord">
        <div class="footerRecord-left">
          Copyright © 北京碧莲盛医疗美容门诊部有限责任公司
          <a
            style="color: inherit"
            href="http://beian.miit.gov.cn"
            target="_blank"
            >京ICP备12001479号-54</a
          >
          <img
            :src="`${$url}/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png`"
            alt=""
            style="vertical-align: bottom; margin: -3px 5px; margin-right: 2px"
          />
          <a
            target="_blank"
            style="color: inherit"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502006011"
          >
            京公网安备 11011502006011号</a
          >
        </div>
        <img
          :src="`${$img}/home/rightLogo.png`"
          alt=""
          class="footerRecord-right"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
a {
  text-decoration: none;
}
</style>
