import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/home";
import index from "@/views/index";
import innovation from "@/views/innovation";
import team from "@/views/team";
import aboutUs from "@/views/aboutUs";
import service from "@/views/service";
import Personal from "@/views/Personal";
import report from "@/views/report";
import info from "@/views/info";
import info1 from "@/views/info1";
import project from "@/views/project";
import direct from "@/views/direct";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/home",
    component: index,
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
      },
      {
        path: "/innovation",
        name: "innovation",
        component: innovation,
      },
      {
        path: "/team",
        name: "team",
        component: team,
      },
      {
        path: "/service",
        name: "service",
        component: service,
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: aboutUs,
      },
      {
        path: "/Personal",
        name: "Personal",
        component: Personal,
      },
      {
        path: "/report",
        name: "report",
        component: report,
      },
      {
        path: "/info",
        name: "info",
        component: info,
      },
      {
        path: "/info1",
        name: "info1",
        component: info1,
      },
      {
        path: "/project",
        name: "project",
        component: project,
      },
    ],
  },
  {
    path: "/direct",
    name: "direct",
    component: direct,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
