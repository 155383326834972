<template>
  <div class="Personal">
    <div class="banner">
      <img
        :src="`${$img}/Personal/banner.jpg`"
        style="vertical-align: middle; width: 100vw"
        alt=""
      />
      <div class="banner-fixed">
        <div class="banner-title">百位名医 专家矩阵</div>
        <div class="banner-text">资质可查 | 履历真实 | 经验丰富 | 技艺精湛</div>
      </div>
    </div>
    <div class="box">
      <div class="Personal-introduction">
        <div class="introduction-top">
          <div class="introduction-bg">DOCTOR</div>
          <div class="introduction-bg">{{ obj.en }}</div>
          <div class="introduction-title">{{ obj.title }}</div>
          <div class="introduction-position">{{ obj.position }}</div>
          <div
            class="introduction-text"
            v-for="(item, index) in obj.text"
            :key="index"
          >
            {{ item }}
          </div>
          <img
            :src="`${$img}/team/Famous/introduce/${Number(index) + 1}.png`"
            class="introduction-img"
            alt=""
            v-if="path == 1"
          />
          <img
            class="introduction-img"
            :src="`${$img}${obj.src2}`"
            alt=""
            v-else-if="path == 2 && obj.src2"
          />
          <img
            class="introduction-img"
            :src="`${$img}/team/Technical/introduce/${
              Number(obj.numberIndex) + 1
            }.png`"
            alt=""
            v-else-if="path == 2 && obj.src2 == null"
          />
          <img
            :src="`${$img}/team/Transplanter/introduce/${
              Number(index) + 1
            }.png`"
            alt=""
            v-else-if="path == 3 && index <= 7"
            class="introduction-img"
          />
          <img
            :src="`${$img}/team/Transplanter/introduce/${
              Number(index) + 2
            }.png`"
            alt=""
            v-else
            class="introduction-img"
          />
        </div>
        <div class="introduction-bottom">
          <div class="Expert-en">INTRODUCTION OF EXPERTS</div>
          <div class="Expert-An">专家介绍</div>
          <div class="Expert-text">
            {{ obj.detail }}
          </div>
        </div>
        <div class="Qualification-title">QUALIFICATION</div>
        <div class="Qualification-text">CERTIFICATE</div>
        <div class="Qualification-line"></div>
        <div class="certificate-title">资质证书</div>

        <div
          class="certificate-img"
          v-if="
            obj.title == '杨志祥' ||
            obj.title == '周宗贵' ||
            obj.title == '杨昕' ||
            obj.title == '张卓' ||
            obj.title == '杨勇' ||
            obj.title == '文亚雄' ||
            obj.title == '郎振图' ||
            obj.title == '梁峰' ||
            obj.title == '鲁少康' ||
            obj.title == '朱世伟' ||
            obj.title == '李盼盼' ||
            obj.title == '张章' ||
            obj.title == '张雪敏' ||
            obj.title == '杨勇华' ||
            obj.title == '罗继森' ||
            obj.title == '米哲亮' ||
            obj.title == '张于锋'
          "
        >
          <div>
            <img
              :src="`${$img}/team/Famous/certificate/${obj.title}/2.png?v=2`"
              alt=""
            />
            <div class="certificate-text">
              执业医师资格证编号：{{ obj.number[1] }}
            </div>
          </div>
          <div>
            <img
              :src="`${$img}/team/Famous/certificate/${obj.title}/1.png?v=2`"
              alt=""
            />
            <div class="certificate-text">
              医师执业证书编号：{{ obj.number[0] }}
            </div>
          </div>
        </div>
        <div class="certificate-img" v-else>
          <div>
            <img
              :src="`${$img}/team/Famous/certificate/${obj.title}/1.png?v=2`"
              alt=""
            />
            <div class="certificate-text">
              执业医师资格证编号：{{ obj.number[1] }}
            </div>
          </div>
          <div>
            <img
              :src="`${$img}/team/Famous/certificate/${obj.title}/2.png?v=2`"
              alt=""
            />
            <div class="certificate-text">
              医师执业证书编号：{{ obj.number[0] }}
            </div>
          </div>
        </div>
        <div class="certificate-img" v-if="obj.healthImg">
          <div>
            <img :src="`${$img}${obj.healthImg}`" alt="" />
            <div class="certificate-text">
              卫生专业技术资格证编号：{{ obj.healthNum }}
            </div>
          </div>
        </div>

        <!--  v-if="obj.title !== '尹梓贻'" -->
        <!-- <div class="certificate-img" v-else>
          <div>
            <img :src="`${$img}/team/Famous/certificate/尹梓贻/1.png`" alt="" />
            <div class="certificate-text">
              执业医师资格证编号：{{ obj.number[1] }}
            </div>
          </div>
          <div>
            <img :src="`${$img}/team/Famous/certificate/尹梓贻/2.png`" alt="" />
            <div class="certificate-text">
              医师执业证书编号：{{ obj.number[0] }}
            </div>
          </div>
        </div> -->
        <div class="OnlineConsulting">在线咨询>></div>
        <div class="OnlineConsulting-bottom"></div>
      </div>
    </div>
    <div class="Personal-Pager box">
      <div class="Pager-left" @click="gotoInfo(leftObj)">
        上一篇：{{ leftObj.title }}
      </div>
      <div class="Pager-center">
        <input
          type="text"
          v-model="name"
          placeholder="请输入医生姓名"
          @keyup.enter="getName"
          style="font-size: 16px; color: #666666; padding-left: 10px"
        />
        <div class="Pager-search" @click="getName">
          <img :src="`${$img}/team/search.png`" alt="" />
        </div>
      </div>
      <div class="Pager-left" @click="gotoInfo(rightObj)">
        下一篇：{{ rightObj.title }}
      </div>
    </div>
  </div>
</template>

<script>
import FamousList from "@/utils/FamousDoctorHall";
import TechnicalList from "../utils/TechnicalDean";
import HaircalList from "../utils/HairTransplanter";
export default {
  data() {
    return {
      name: "",
      obj: {
        en: "",
        title: "",
        position: "",
        Text: [""],
        detail: "",
        number: ["", ""],
      },
      leftObj: {
        title: "",
        position: "",
        Text: [""],
        detail: "",
        number: ["", ""],
      },
      rightObj: {
        title: "",
        position: "",
        Text: [""],
        detail: "",
        number: ["", ""],
      },
      path: 1,
      index: 0,
    };
  },
  created() {
    let list = this.$route.query.index.split("-");
    this.index = list[0];
    if (list[1] == 1) {
      this.obj = FamousList.find((item) => item.numberIndex == this.index);
      this.path = 1;
    } else if (list[1] == 3) {
      this.obj = HaircalList.find((item) => item.numberIndex == this.index);
      this.path = 3;
    } else {
      this.obj = TechnicalList.find((item) => item.numberIndex == this.index);
      this.path = 2;
    }
    let totalList = [];
    let allList = [...FamousList, ...TechnicalList, ...HaircalList];
    allList.forEach((el) => {
      if (!totalList.find((e) => e.title == el.title)) {
        totalList.push(el);
      }
    });
    let index = totalList.findIndex((item) => {
      return item.title == this.obj.title;
    });

    if (index !== 0) {
      this.leftObj = totalList[index - 1];
    } else {
      this.leftObj.title = "没有了";
    }
    if (index !== totalList.length - 1) {
      this.rightObj = totalList[index + 1];
    } else {
      this.rightObj.title = "没有了";
    }
  },
  beforeRouteUpdate(to, from, next) {
    let list = to.query.index.split("-");
    this.index = list[0];
    if (list[1] == 1) {
      this.obj = FamousList[list[0]];
      this.path = 1;
    } else if (list[1] == 3) {
      this.obj = HaircalList[list[0]];
      this.path = 3;
    } else {
      this.obj = TechnicalList[list[0]];
      this.path = 2;
    }
    let totalList = [];
    let allList = [...FamousList, ...TechnicalList, ...HaircalList];
    allList.forEach((el) => {
      if (!totalList.find((e) => e.title == el.title)) {
        totalList.push(el);
      }
    });
    let index = totalList.findIndex((item) => {
      return item.title == this.obj.title;
    });

    if (index !== 0) {
      this.leftObj = totalList[index - 1];
    } else {
      this.leftObj.title = "没有了";
    }
    if (index !== totalList.length - 1) {
      this.rightObj = totalList[index + 1];
    } else {
      this.rightObj.title = "没有了";
    }
    console.log(123123);
    next();
  },
  methods: {
    getName() {
      this.$router.push({
        name: "team",
        params: {
          searchA: this.name,
        },
      });
    },

    gotoInfo(a) {
      if (a.title == "没有了") {
        return false;
      }
      let index = `${String(a.numberIndex)}-${String(a.type)}`;
      document.documentElement.scrollTop = 0;

      this.$router.push({
        path: "/Personal",
        query: {
          index: index,
        },
      });
    },
  },
};
</script>

<style lang="scss"></style>
