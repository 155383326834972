export default [{
    title: '杨志祥',
    position: '主任医师',

    en: 'YANG',
    path: 'Famous',
    type: '1',
    numberIndex: "0",
    text: [
      '教授、博士研究生导师',
      '享受国务院特殊津贴专家',
      '世界植发大会会员',
      '碧莲盛医疗技术带头人',
      '中国医师协会美容与整形医师分会顾问',
      '中国医师协会美容与整形医师分会荣誉会员',
    ],
    detail: '教授、主任医师、博士研究生导师，享受国务院特殊津贴专家，世界植发大会会员，碧莲盛医疗技术带头人，中国医师协会美容与整形医师分会顾问，中国医师协会美容与整形医师分会荣誉会员。参编《整形外科学》《烧伤整形再造外科学》《辐射事故临床医学处理》《整形与烧伤外科手术学(2版)》等11部外科论著。',
    number: ['110110108003591', '199883110110108195010263715'],
  },
  {
    numberIndex: "1",
    title: '袁国安',
    en: 'YUAN',
    path: 'Famous',
    type: '1',
    position: '副主任医师',
    text: [
      '碧莲盛北京院部技术院长',
      '世界植发大会会员',
      '碧莲盛医疗技术带头人',
      '碧莲盛植发技术研究中心负责人 ',
      '碧莲盛植发技术培训中心特聘带教官',
      '中国医师协会美容与整形医师分会委员',
      '微创抗衰老亚专业委员会委员',
      '原武钢集团医院整形烧伤科副主任',
    ],
    detail: '副主任医师，碧莲盛北京院部技术院长，世界植发大会会员，原武钢集团医院整形烧伤科副主任，碧莲盛医疗技术带头人，碧莲盛植发技术研究中心负责人，碧莲盛植发技术培训中心特聘带教官，中国医师协会美容与整形医师分会委员，微创抗衰老亚专业委员会委员。参编《无痕植发学》等行业技术参考书籍，在植发临床、科研、教学等领域做出了重要贡献。',
    number: ['110440304002014', '199842110420106651125443'],

  },
  {
    numberIndex: "2",
    title: '周宗贵',
    en: 'ZHOU',
    path: 'Famous',
    type: '1',
    position: '副主任医师',
    text: [
      '碧莲盛上海院部技术院长',
      '碧莲盛医疗技术带头人',
      '碧莲盛名医堂核心成员',
      '碧莲盛植发技术培训中心特聘带教官',
      '碧莲盛植发手术数字监控系统建设者',
      '参与不剃发植发技术的核心研发',
      '多次受邀出席世界植发大会、中国植发大会',
      '擅长大面积种植、不剃发植发、加密种植、艺术种植',
    ],
    detail: '副主任医师，碧莲盛上海院部技术院长，碧莲盛医疗技术带头人，碧莲盛名医堂核心成员，碧莲盛植发技术培训中心特聘带教官，植发手术数字监控系统建设者，参与不剃发植发技术的核心研发，多次受邀出席世界植发大会、中国植发大会，擅长大面积种植、不剃发植发、加密种植、艺术及疤痕种植，在治脱方案选择及术后指导方面有独到心得。',
    number: ['110310107001480', '201042110422727196907122133'],
  },
  {
    numberIndex: "3",
    title: '蒋学',
    en: 'JIANG',
    path: 'Famous',
    type: '1',
    position: '副主任医师',
    text: [
      '碧莲盛集团总院长',
      '碧莲盛医疗技术带头人',
      '完全不剃发植发技术研发人',
      '碧莲盛集团医疗技术负责人',
      '碧莲盛植发技术培训中心特聘带教官',
    ],
    detail: '副主任医师，碧莲盛集团总院长，碧莲盛集团技术总负责人，碧莲盛植发技术培训中心医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，不剃发植发技术的主要发起人和研发人。二十多年外科临床经验，植发手术经验丰富，参与多项植发技术的升级研发和国际交流，倡导植养固体系与植发手术紧密结合。多次受邀在国内外植发学术论坛上现场演示植发技术，在植发临床、科研、教学领域做出重要贡献。',
    number: ['110130200003215', '200213110130204761025481'],
  },
  {
    en: 'YI',
    path: 'Famous',
    type: '1',
    numberIndex: "4",
    title: '尹梓贻',
    position: '副主任医师',
    text: [
      '医疗美容主诊医师',
      '碧莲盛济南院部技术院长',
      '碧莲盛名医堂核心成员',
      '碧莲盛医疗技术带头人',
      '碧莲盛多项植发器械专利发明人',
      '中国整形美容协会毛发医学分会委员',
      '中国非公立医疗机构协会皮肤专业委员会委员',
    ],
    detail: '碧莲盛济南院部技术院长，碧莲盛医疗技术带头人，毕业于山东大学医学院临床医学专业。现为整形外科副主任医师，医疗美容主诊医师，中国整形美容协会毛发医学分会委员，中国非公立医疗机构协会皮肤专业委员会委员，山东省毛发移植培训指定讲师，荣获“山东首届十佳整形美容医师提名奖”。多次在权威学术期刊发表专业论文，参编专著《整形美容外科学》，主刀案例小蔡被CCTV1《生活圈》专题报道，并国内首次入驻世界植发大会案例中心。',
    number: ['110370102001931', '200737110370124198110210575'],
  },
  {
    numberIndex: "5",
    en: 'CHEN',
    path: 'Famous',
    type: '1',
    title: '陈修义',
    position: '主任医师',
    text: [
      '美容外科主诊医师',
      '碧莲盛泉州院部技术院长',
      '碧莲盛名医堂核心成员',
      '碧莲盛医疗技术带头人',
      '碧莲盛植发技术培训中心特聘带教官',
      '主导毛囊提取器械的升级研发',
      '参与不剃发植发技术的核心研发',
      '擅长艺术种植、多维加密及不剃发植发',
    ],
    detail: '主任医师，美容外科主诊医师，碧莲盛泉州院部技术院长，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官。主导毛囊提取器械的升级研发，参与不剃发植发技术的核心研发，拥有近30年临床经验，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。',
    number: ['110110000039218', '19992311231004670812097'],
  },
  {
    numberIndex: "6",
    en: 'SU',
    path: 'Famous',
    type: '1',
    title: '苏斌',
    position: '副主任医师',
    text: [
      '碧莲盛福州院部技术院长',
      '碧莲盛名医堂核心成员',
      '碧莲盛医疗技术带头人',
      '碧莲盛植发技术培训中心特聘带教官',
      '参与精密即插即种植发技术的升级研发',
      '擅长艺术种植、多维加密及不剃发植发',
    ],
    detail: '副主任医师，碧莲盛福州院部技术院长，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，参与精密即插即种植发技术的升级研发，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密及不剃发植发。',
    number: ['110650000902922', '200665110652322810127401'],
  },
  {
    en: 'ZHANG',
    path: 'Famous',
    type: '1',
    numberIndex: "7",
    title: '张桥',
    position: '主治医师',
    text: [
      '碧莲盛西安院部技术院长',
      '碧莲盛名医堂核心成员',
      '碧莲盛医疗技术带头人',
      '碧莲盛植发技术培训中心特聘带教官',
      '参与精密即插即种植发技术的与研发',
      '主导研发新式毛囊提取设备',
      '擅长大面积植发、艺术种植和不剃发植发',
    ],
    detail: '主治医师，碧莲盛西安院部技术院长，毕业于第四军医大学，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，参与精密即插即种植发技术的与研发，主导研发新式毛囊提取设备，擅长大面积植发、艺术种植和不剃发植发。',
    number: ['110610103001288', '201061110610402198403015595'],
  },
  {
    en: 'CAO',
    path: 'Famous',
    type: '1',
    title: '曹国荣',
    position: '主治医师',
    numberIndex: "8",
    text: [
      '碧莲盛长沙院部技术院长',
      '碧莲盛名医堂核心成员',
      '碧莲盛医疗技术带头人',
      '碧莲盛植发技术培训中心特聘带教官',
      '中国整形美容协会毛发医学分会委员',
      '擅长大面积植发、艺术种植和不剃发植发',
    ],
    detail: '主治医师，碧莲盛长沙院部技术院长，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，中国整形美容协会毛发医学分会委员，多次受邀参加国内毛发移植技术交流论，擅长大面积植发、艺术种植和不剃发植发。',
    number: ['110430105000924', '201143110432321197901316777'],
  },
  {
    en: 'LI',
    path: 'Famous',
    type: '1',
    title: '李兴国',
    position: '主治医师',
    numberIndex: "9",
    text: [
      '碧莲盛名医堂核心成员',
      '碧莲盛医疗技术带头人',
      '碧莲盛植发技术培训中心特聘带教官',
      '参与精密即插即种植发技术的研发',
      '多次受邀参加国内毛发移植技术交流论坛',
      '擅长大面积植发、艺术种植和不剃发植发',
    ],
    detail: '主治医师，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，曾参与精密即插即种植发技术的研发。拥有多年毛发移植临床经验，倡导大面积种植精细化操作，多次受邀参加国内毛发移植技术交流论坛，擅长大面积植发、艺术种植和不剃发植发。',
    number: ['110510109001332', '200751110510724197505112034'],
  },
  {
    en: 'HUANG',
    path: 'Famous',
    type: '1',
    title: '黄广程',
    numberIndex: "10",
    position: '主治医师',
    text: [
      '碧莲盛名医堂核心成员',
      '碧莲盛医疗技术带头人',
      '碧莲盛植发技术培训中心特聘带教官',
      '参与不剃发植发技术的研发',
      '参与精密即插即种植发技术研发',
      '擅长大面积植发、艺术种植和不剃发植发',
    ],
    detail: '主治医师，碧莲盛广州院部技术院长，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官。曾参与不剃发植发技术的研发，参与精密即插即种植发技术研发，拥有多年毛发移植临床经验，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。',
    number: ['110440304002476', '20144311043078119840525001X'],
  },
  {
    title: '张于锋',
    en: 'ZHANG',
    path: 'Famous',
    type: '1',
    numberIndex: "11",
    position: '主治医师',
    text: [
      '碧莲盛名医堂核心成员',
      '碧莲盛植发技术培训中心特聘带教官',
      '碧莲盛植发养护体系制定者之一',
      '参与不剃发植发技术的器械改进',
      '多次受邀参加国内毛发移植技术交流论坛',
      '擅长大面积植发、艺术种植和不剃发植发',
    ],
    detail: '主治医师，碧莲盛名医堂核心成员，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发养护体系制定者之一，参与不剃发植发技术的器械改进，多次受邀参加国内毛发移植技术交流论坛，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。',
    number: ['110440304002343', '200842110420822198006107076'],
  },
  {
    title: '杨昕',
    en: 'YANG',
    path: 'Famous',
    type: '1',
    position: '主治医师',
    numberIndex: "12",
    text: [
      '碧莲盛杭州院部技术院长',
      '碧莲盛名医堂核心成员',
      '碧莲盛医疗技术带头人',
      '碧莲盛植发技术培训中心特聘带教官',
      '主持精密即插即种植发器械的升级改进',
      '擅长大面积植发、艺术种植和不剃发植发',
    ],
    detail: '主治医师，碧莲盛杭州院部技术院长，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，主持精密即插即种植发器械的升级改进，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长大面积植发、艺术种植和不剃发植发。',
    number: ['1103301060002557', '201033110360302198508230534'],
  },
  {
    numberIndex: "13",
    title: '陈燕华',
    en: 'CHEN',
    path: 'Famous',
    type: '1',
    position: '主治医师',
    text: [
      '碧莲盛名医堂核心成员',
      '碧莲盛植发技术培训中心特聘带教官',
      '碧莲盛植发技术研究中心核心成员',
      '参与不剃发植发技术的核心研发',
      '参与不剃发植发技术的器械改进',
      '擅长大面积种植、艺术种植和不剃发植发',
    ],
    detail: '主治医师，碧莲盛名医堂核心成员，碧莲盛植发技术培训中心特聘带教官，碧莲盛植发技术研究中心核心成员，参与不剃发植发技术的核心研发，参与不剃发植发技术的器械改进，擅长大面积种植、艺术种植和不剃发植发，在治脱方案选择及术后指导方面有独到心得。',
    number: ['110140106000764', '201214110142603198504099317'],
  },
  {
    numberIndex: "14",
    en: 'ZHANG',
    path: 'Famous',
    type: '1',
    title: '张卓',
    position: '主治医师',
    text: [
      '碧莲盛名医堂核心成员',
      '碧莲盛植发技术培训中心特聘带教官',
      '碧莲盛养固体系制定参与者',
      '参与新式毛囊提取设备的研发',
      '多次受邀参加国内毛发移植技术交流论坛',
      '擅长艺术种植、发际线修复及不剃发植发',
    ],
    detail: '主治医师，碧莲盛名医堂核心成员，碧莲盛植发技术培训中心特聘带教官，碧莲盛养固体系制定参与者，参与新式毛囊提取设备的研发，多次受邀参加国内毛发移植技术交流论坛。倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、发际线修复及不剃发植发，深受发友信赖。',
    number: ['110110108008293', '201542110420881198910173354'],
  },
  {
    numberIndex: "15",
    en: 'YANG',
    path: 'Famous',
    type: '1',
    title: '杨勇',
    position: '主治医师',
    text: [
      '碧莲盛名医堂核心成员',
      '碧莲盛医疗技术带头人',
      '碧莲盛植发技术培训中心特聘带教官',
      '主持精密即插即种植发技术的器械改进',
      '多次受邀参加国内毛发移植技术交流论坛',
      '擅长艺术种植、多维加密和不剃发植发',
    ],
    detail: '主治医师，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官。曾多次受邀参加参加国内毛发移植技术交流论坛，主持了精密即插即种植发技术的器械改进，倡导根据毛囊特征打造差异化的植发器械。擅长艺术种植、多维加密和不剃发植发。',
    number: ['110440106004690', '201042110429001198007067670'],
  },
  {
    title: '夏正法',
    en: 'XIA',
    path: 'Famous',
    type: '1',
    numberIndex: "16",
    position: '主治医师',
    text: [
      '碧莲盛南昌院部技术院长',
      '碧莲盛名医堂核心成员',
      '碧莲盛植发技术培训中心特聘带教官',
      '参与不剃发植发技术的研发',
      '多次受邀参加国内毛发移植技术交流论坛',
      '擅长大面积植发、艺术种植和不剃发植发',
    ],
    detail: '主治医师，毕业于武汉科技大学临床医学专业，碧莲盛南昌院部技术院长，碧莲盛名医堂核心成员，碧莲盛植发技术培训中心特聘带教官，参与不剃发植发技术的研发，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发。',
    number: ['110421100000795', '200342110422124197709150830'],
  },
  {
    en: 'LU',
    path: 'Famous',
    type: '1',
    numberIndex: "17",
    title: '卢春涛',
    position: '主治医师',
    text: [
      '碧莲盛名医堂核心成员',
      '碧莲盛植发技术培训中心特聘带教官',
      '主导毛发提取器械改进研发',
      '参与不剃发植发技术的研发',
      '多次受邀参加国内毛发移植技术交流论坛',
      '擅长大面积植发、艺术种植和不剃发植发',
    ],
    detail: '主治医师，碧莲盛名医堂核心成员，碧莲盛植发技术培训中心特聘带教官，主导毛发提取器械改进研发，参与不剃发植发技术的研发，多次受邀参加国内毛发移植技术交流论坛，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长大面积植发、艺术种植和不剃发植发，深受发友信赖。',
    number: ['110321181001013', '201052110432924198303050071'],
  },
  {
    en: 'WEN',
    path: 'Famous',
    type: '1',
    numberIndex: "18",
    title: '文亚雄',
    position: '副主任医师',
    text: [
      '美容外科主诊医师',
      '碧莲盛名医堂核心成员',
      '碧莲盛医疗技术带头人',
      '碧莲盛植发技术培训中心特聘带教官',
      '拥有二十多年外科临床经验',
      '碧莲盛康养体系的发起人之一',
      '植发培固+焕活理念的理论建设者',
      '擅长大面积植发、艺术种植和不剃发植发',
    ],
    detail: '副主任医师，美容外科主诊医师，碧莲盛名医堂核心成员，碧莲盛医疗技术带头人，碧莲盛植发技术培训中心特聘带教官，拥有二十多年外科临床经验，多次获得优秀医生、精神文明建设先进工作者称号，是碧莲盛康养体系的发起人之一，倡导植养固体系与植发手术紧密结合，是新型植发技术中培固+焕活理念的理论建设者，并多次受邀出席国内毛发移植技术交流论坛讲解传授新式养固体系。',
    number: ['110430223000196', '199843110430223700313723'],
  },
  {
    title: '郎振图',
    numberIndex: "19",
    en: 'LANG',
    path: 'Famous',
    type: '1',
    position: '主治医师',
    text: [
      '碧莲盛沈阳院部技术院长',
      '碧莲盛名医堂核心成员',
      '碧莲盛植发技术研究中心核心成员',
      '参与研发精密即插即种植发技术',
      '参与碧莲盛毛发康养体系的建设',
      '擅长个性化定制方案、艺术种植和不剃发植发',
    ],
    detail: '主治医师，碧莲盛沈阳院部技术院长，碧莲盛名医堂核心成员，碧莲盛植发技术研究中心核心成员，参与研发精密即插即种植发技术，参与碧莲盛毛发康养体系的建设。拥有多年毛发移植临床经验，倡导大面积种植精细化操作，擅长根据脱发者的具体情况量身打造植发方案，擅长艺术种植、多维加密和不剃发植发。',
    number: ['110210100016353', '201321110211221198609112117'],
  },
]
