<template>
  <div class="m-select-wrap">
    <input
      :class="['u-select-input f16', color === 'blue' ? '' : 'white-color']"
      type="text"
      style="outline: none"
      readonly
      @click="openSelect"
      @blur="onBlur"
      v-model="selectName"
    />
    <div
      :class="['triangle-down', { rotate: rotate }]"
      @click="openSelect"
    ></div>

    <div :class="['m-options-panel f16', showOptions ? 'show' : 'hidden']">
      <p
        class="u-option"
        @mousedown="getValue(item.name, item.value, index)"
        v-for="(item, index) in selectData"
        :key="index"
      >
        {{ item.name }}
      </p>
    </div>
  </div>
</template>
  <script>
export default {
  name: "Select",
  props: {
    selectData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // eslint-disable-next-line vue/require-prop-types
    selValue: {
      // 将该prop值作为selV的初始值
      default: undefined,
    },
    color: {
      type: String,
      default: () => {
        return "blue";
      },
    },
    name: {
      type: String,
      default: () => {
        return "城市";
      },
    },
  },

  computed: {
    selectName() {
      let selName;
      this.selectData.forEach((item) => {
        if (item.value === this.selectValue) {
          selName = item.name;
        }
      });
      if (this.isAdressName == false && this.name == "城市") {
        selName = "请选择城市";
      }

      if (this.isValueName == false && this.name == "区域") {
        selName = "请选择区域";
      }
      if (selName == undefined) {
        selName = "请选择城市";
      }
      return selName;
    },

    selectValue: {
      get() {
        return this.selV;
      },
      set(newVal) {
        this.selV = newVal;
      },
    },
  },
  data() {
    return {
      selV: this.selValue,
      rotate: false,
      showOptions: false,
      isAdressName: false,
      isValueName: false,
    };
  },
  created() {
    this.selV = this.selValue;
  },
  methods: {
    openSelect(e) {
      this.showOptions = !this.showOptions;
      this.rotate = !this.rotate;
      e.stopPropagation();
      if (this.showOptions) {
        let that = this;
        document.querySelector("body").onclick = function () {
          that.showOptions = false;
        };
      }
    },
    getValue(name, value, index) {
      this.selectValue = value;
      if (this.name == "区域") {
        this.isValueName = true;
      } else {
        if (this.name == "城市") {
          this.isAdressName = true;
        }
      }
      this.$emit("getValue", name, value, index);
    },
    triggerValue(name, value, index) {
      this.selectValue = value;
      this.$emit("getValue", name, value, index);
    },
    onBlur() {
      this.showOptions = false;
      this.rotate = false;
    },
  },
};
</script>
  <style lang="scss" scoped>
.m-select-wrap {
  width: 225px;
  height: 45px;
  line-height: 45px;
  position: relative;
  border: solid 1px #c5c6c7;

  .u-select-input {
    width: 150px;
    height: 30px;
    background-color: #ffffff;

    color: #b1b1b1;
    font-size: 16px;
    height: 30px;
    padding: 0 15px;
    cursor: pointer;
    border: none;
  }
  .white-color {
    background: #ffffff;
    color: #666666;
  }
  .triangle-down {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 10px;
    height: 10px;
    background-color: transparent; /* 模块背景为透明 */
    border-color: #b1b1b1;
    border-style: solid;
    border-width: 1px 1px 0 0;
    transform: rotate(135deg); /*箭头方向可以自由切换角度*/
  }

  .rotate {
    transform: rotate(-45deg) translate(-5px, 5px);
  }
  .m-options-panel {
    position: absolute;
    background: #ffffff;
    height: 300px;
    overflow: scroll;
    width: 100%;
    border: 1px solid #e3e3e3;
    top: 46px;
    left: 0;
    color: #666666;
    font-size: 16px;
    .u-option {
      padding: 2px 15px;
      cursor: pointer;
    }
    .u-option:hover {
      color: #3a79ee;
      background: #eef1fa;
    }
  }
  .show {
    display: block;
  }
  .hidden {
    display: none;
  }
}
</style>